import React from 'react';
import { connect } from 'react-redux';
import PageHome from '../components/PageHome';

class PageHomeContainer extends React.Component {
  render() {
    const { homePage } = this.props;
    return (
      <PageHome { ...homePage } />
    )
  }
}

const mapStateToProps = store => {
  return {
    homePage: store.homePage,
  };
};

export default connect(
  mapStateToProps,
)(PageHomeContainer);
