import React from 'react';
import renderMessage from '../utils/renderMessage';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import PlayerContainer from '../containers/PlayerContainer';
import PlaylistContainer from '../containers/PlaylistContainer';
import theme, { breakpoint, bp } from './theme';
import Ad from "./Ad";
import setMeta from '../utils/setMeta';
import getImages from "../utils/getImages";
import BannerObserver from '../utils/BannerObserver';

// import adxTest from '../utils/adxTest';

const PageVideoLayout = styled.div`
  ${breakpoint.mobileLandscape} {
    display: flex;
  }

  ${breakpoint.desktopUp} {
    display: flex;
  }

  ${breakpoint.mobileLandscape} {
    padding: 5px 5px 5px 0;
  }

  ${breakpoint.desktopUp} {
    padding: 20px 20px 20px 0;
  }

  ${breakpoint.desktopBigUp} {
    padding: 20px 15px 20px 20px;
  }
`;

const VideoBlock = styled.div`
  ${breakpoint.mobileLandscape} {
    min-width: 0;
  }

  ${breakpoint.mobileSmallLandscape} {
    flex: 58.8% 0 0;
  }

  ${breakpoint.mobileBigLandscape} {
    flex: 62% 0 0;
  }

  ${breakpoint.desktopUp} {
    flex: 72.9% 0 0;
    min-width: 0;
  }

  ${breakpoint.desktopBigUp} {
    flex: 57% 0 0;
  }
`;

const Sticky = styled.div`
  position: sticky;
  top: 0;

  ${breakpoint.mobileLandscape} {
    top: 5px;
  }

  ${breakpoint.desktopUp} {
    top: 20px;
  }
`;

const VideoInfo = styled.div`
  background: white;
  box-shadow: ${theme.shadow.common};
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 19px;
`;

const Title = styled.h1`
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.01em;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const PlaylistContainerStyled = styled(PlaylistContainer)`
  ${breakpoint.mobileLandscape} {
    display: block;
  }

  ${breakpoint.mobileSmallLandscape} {
    flex: 41.2% 0 0;
  }

  ${breakpoint.mobileBigLandscape} {
    flex: 38% 0 0;
  }

  ${breakpoint.desktopOnly} {
    flex: 27.1% 0 0;
    display: block;
  }

  ${breakpoint.desktopBigUp} {
    flex: 43% 0 0;
  }
`;

const UnvideoBanner = styled(Ad)`
  margin: 12px 0 0;
  min-width: 250px;
  text-align: center;
  overflow: hidden;
  overflow-x: auto;

  & > ins {
    width: 100%;
  }

  @media (max-width: 365px) {
    min-height: 250px;
    
    .adsbygoogle {
      height: 250px;
    }
  }

  @media (min-width: 366px) and (max-width: 767px)  {
    min-height: 280px;

    .adsbygoogle {
      height: 280px;
    }
  }

  @media (min-width: 768px) {
    min-height: 190px;

    .adsbygoogle {
      height: 190px;
    }
  }
`;

export default class PageVideo extends React.Component {
  componentDidMount() {
    setMeta(this.props.pageTitle, this.props.pageDescription);
    this.bannerObserver = new BannerObserver();
    this.observer = this.bannerObserver.init();
    this.callManualInterstitial();
  }

  componentWillUnmount() {
    if (this.adRef) this.observer.unobserve(this.adRef.container);
  }

  componentDidUpdate (prevProps) {
    if(prevProps.dataPath !== this.props.dataPath) {
      setMeta(this.props.pageTitle, this.props.pageDescription);
      this.bannerObserver.reset();
      this.observer.disconnect();
      this.adxRefresh();
      this.callManualInterstitial();
      if (this.adRef) {
        this.adRef.container.dataset.sendedEvent = 0;
        this.observer.unobserve(this.adRef.container);
        this.observer.observe(this.adRef.container);
      }
    }
  }

  callManualInterstitial = () => {
    const { adNamed } = this.props;

    try {
      let currentPage = +localStorage.getItem('szManualAdFromThird');
      localStorage.setItem('szManualAdFromThird', ++currentPage);

      if (adNamed.some(el => el.place_id === "double") && currentPage >= 3) {
        document.dispatchEvent(new Event('showManualInterstitial'));
      }
    } catch (error) {
      console.error(error);
    }
  }

  adAfterInit = () => {
    window.dBanInitTime = Date.now();
    if (this.adRef) this.observer.observe(this.adRef.container);
  }

  adxRefresh = () => setTimeout(() => {
    if (window.googletag && window.googletag.pubads && !window.initSlotName) {
      googletag.pubads().refresh();
    }
  },1000)

  getObj(arr, val) {
    return arr.reduce((result, item) => {
      result[item[val]] = item;
      return result;
    }, {});
  }

  get images() {
    return getImages(this.props.data.posterUrl, {
      [bp.screen375]: '375x211',
      [bp.screen414]: '414x233',
      [bp.screen640]: '640x360',
      [bp.bigMobile]: '768x432',
      [bp.tablet]: '1024x576',
      [bp.desktop]: '1024x576',
    });
  }

  render() {
    renderMessage('PageVideo');
    const { adNamed, pathname, history, data } = this.props;
    const { title } = data;
    const ADNamed = this.getObj(adNamed, 'place_id');
    const slotHeader = (ADNamed.unvideo && ADNamed.unvideo.allow_processing) ? ADNamed.unvideo.slot_header : false;
    const adxId = ADNamed.unvideo?.slot_code?.match(/id='(.*?)'/)?.[1];

    return (
      <PageVideoLayout>
        <VideoBlock>
          <Sticky>
            <PlayerContainer history={history} />
            <VideoInfo>
              <Title>{title}</Title>
            </VideoInfo>
            {ADNamed.unvideo && <UnvideoBanner
              pathname={pathname}
              type="rebootable"
              name={ADNamed.unvideo.place_id}
              code={ADNamed.unvideo.slot_code}
              header={slotHeader}
              analyticsAction="UNVIDEO_LOADED"
              analyticsActionSpecial="UNVIDEO_LOADED_VIP"
              ref={el => (this.adRef = el)}
              afterInit={this.adAfterInit}
              className={`desktop-banner ${adxId ? adxId : ''}`}
            />}
          </Sticky>
        </VideoBlock>
        <PlaylistContainerStyled />
      </PageVideoLayout>
    );
  }
}

PageVideo.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    length: PropTypes.string.isRequired,
    posterUrl: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    videoUrl: PropTypes.string.isRequired,
    vastUrl: PropTypes.array,
  }).isRequired,
  pageDescription: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
};
