import axios from 'axios';
import {
  ARTICLE_REQUEST,
  ARTICLE_SUCCESS,
  ARTICLE_FAIL,
} from '../constants';

export function handleArticle(pathname) {
  return (dispatch) => {
    console.log(ARTICLE_REQUEST);
    dispatch({
      type: ARTICLE_REQUEST,
    });

    axios.get(`${pathname}.json`)
      .then(res => {
        console.log('ARTICLE_SUCCESS', res.data);
        dispatch({
          type: ARTICLE_SUCCESS,
          payload: res.data,
        });
      }, error => {
        dispatch({
          type: ARTICLE_FAIL,
          payload: error,
        });
      });
  };
}
