import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { MENU_SHOW, MENU_HIDE } from '../constants';

let MenuTarget;

export function menuShow(rendered) {
  return function(dispatch) {
    MenuTarget = document.querySelector('.menu');
    disableBodyScroll(MenuTarget);
    dispatch({
      type: MENU_SHOW,
    });
  };
}

export function menuHide() {
  enableBodyScroll(MenuTarget);
  return function(dispatch) {
    dispatch({
      type: MENU_HIDE,
    });
  };
}
