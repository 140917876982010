import Analytics from '../utils/Analytics';
import { googleAnalytics4, yandexMetrika } from "../params";

const analyticsMiddleware = new Analytics({
  gaId4: googleAnalytics4,
  yaId: yandexMetrika,
  category: "Video",
  events: {
    VIDEO_THUMB: {
      action: [ "related_click", "Related Position"],
      label: [ "url", "position" ],
      conversion: ["related_id"],
      conversion: ["all_in"],
    },
    VIDEO_THUMB_VIP: {
      action: [ "cl_related_click" ],
      label: [ "url" ],
      conversion: [ "cl_related_click" ]
    },
    MENU_SHOW: {
      action: [ "Sidebar Open" ],
    },
    SHOW_COOKIE_POLICY: {
      action: [ "Show Cookies Policy" ],
      label: [ "label" ],
      category: "Index",
    },
    ACCEPT_COOKIE_POLICY: {
      action: [ "Accept Cookies Policy" ],
      category: "Index",
    },
    OLD_BROWSER: {
      action: [ "Old WebKit" ],
      method: "trackOldBrowsers",
    },
    PLAYER_AD_ERROR: {
      action: [ "adError" ],
      label: [ "message" ],
      category: "JwAds",
    },
    PLAYER_AD_CLICK: {
      action: [ "adClick" ],
      label: [ "creativetype" ],
      category: "JwAds",
    },
    PLAYER_AD_REQUEST: {
      action: [ "vast_request", "invideo_loaded" ],
      label: [ false, false ],
      category: "JwAds",
      conversion: ["all_in"],
    },
    INVIDEO_LOADED_VIP: {
      action: [ "cl_invideo_loaded" ],
      label: [ false ],
      category: "JwAds",
      conversion: ["cl_invideo_direct"],
    },
    PLAYER_AD_COMPLETE: {
      action: [ "adComplete" ],
      label: [ "creativetype" ],
      category: "JwAds",
    },
    PLAYER_AD_IMPRESSION: {
      action: [ "adImpression" ],
      label: [ "creativetype" ],
      category: "JwAds",
    },
    PLAYER_AUTOPLAY_START: {
      action: [ "Autoplay start" ],
      label: [ false ],
      category: "Player",
    },
    PLAYER_AUTOPLAY_ERROR: {
      action: [ "Autoplay error" ],
      label: [ "message" ],
      category: "Player",
    },
    PLAYER_MEDIA_PLAY: {
      conversion: ["media_play"],
    },
    SHOW_AD_TAG: {
      action: ['Show Ad Tag'],
      category: "Index",
    },
    PRELAND_CLICKED: {
      action: ['first_click'],
      category: "Index",
      conversion: ["all_in"],
    },
    INTERSTITIAL_AD_SHOW: {
      action: [ "content_loaded" ],
      label: [ "label" ],
      category: "Index",
      conversion: ["content_direct"],
    },
    LANDER_AD_SHOW: {
      action: [ "lander_loaded" ],
      label: [ "label" ],
      category: "Index",
    },
    INTERSTITIAL_LEGACY_SHOW: {
      action: [ "content_loaded_legacy" ],
      label: [ "label" ],
      category: "Index",
    },
    RELATED_LOADED: {
      action: [ "related_loaded" ],
      label: [ false ],
      category: "Index",
      conversion: ["related_direct"],
    },
    UNVIDEO_LOADED: {
      action: [ "unvideo_loaded" ],
      label: [ false ],
      category: "Index",
    },
    UNVIDEO_LOADED_VIP: {
      action: [ "cl_unvideo_loaded" ],
      label: [ false ],
      category: "Index",
      conversion: ["cl_unvideo_direct"],
    },
  },
});

export default analyticsMiddleware;
