import React from 'react';
import renderMessage from '../utils/renderMessage';
import { connect } from 'react-redux';
import { handleRoute, handlePreLand } from '../actions/VideoPageActions';
import PagePreland from '../components/PagePreland';

class PageLandingContainer extends React.Component {
  componentDidMount() {
    this.props.handlePreLand(true);
  }

  render() {
    renderMessage('PageLandingContainer');
    const { location, videoPage } = this.props;
    const { data, playlist, pageTitle, pageDescription } = videoPage;

    return (
      <PagePreland
        pathname={location.pathname}
        data={data}
        playlist={playlist}
        pageTitle={pageTitle}
        pageDescription={pageDescription}
      />
    );
  }
}

const mapStateToProps = store => {
  return {
    videoPage: store.videoPage,
    siteFeatures: store.sessionParams.siteFeatures,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleRoute: (pathname, isUpdate) => dispatch(handleRoute(pathname, isUpdate)),
    handlePreLand: isPreLand => dispatch(handlePreLand(isPreLand)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageLandingContainer);
