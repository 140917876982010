import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import renderMessage from '../../utils/renderMessage';
import LogoImage from './LogoHeader.svg';

const StyledLogo = styled(Link)`
  height: 100%;
  align-items: center;
  display: inline-flex;
`;

const Image = styled.img`
  max-height: 100%;
`;

const LogoHeader = () => {
  renderMessage('LogoHeader');
  return (
    <StyledLogo to="/">
      <Image src={LogoImage} alt="" />
    </StyledLogo>
  );
};

export default LogoHeader;
