import React from 'react';
import PropTypes from 'prop-types';

const Svg = props => {
  const width = props.size;
  const height = props.height * props.size / props.width;

  return (
    <svg className={props.className}
         xmlns="http://www.w3.org/2000/svg"
         width={width}
         height={height}
         viewBox={`0 0 ${props.width} ${props.height}`}
         aria-labelledby="title">
      <title id="title">{props.title}</title>
      {props.children}
    </svg>
  )
};

export default Svg;

Svg.propTypes = {
  size: PropTypes.number,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
};
