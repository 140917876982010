import React from 'react';
import Svg from "./Svg";

const IconChevronDoubleRight = props => {
  return (
    <Svg
      title="ChevronDoubleRight Icon"
      size={props.size || 21}
      className={props.className}
      width={21}
      height={23.1}>
      <path d="M12.8,11.6c0,0.3-0.1,0.6-0.4,0.9L2.1,22.8c-0.2,0.2-0.5,0.4-0.9,0.4c0,0,0,0,0,0c-0.3,0-0.6-0.1-0.9-0.4
	C0.1,22.6,0,22.3,0,21.9c0-0.3,0.1-0.6,0.4-0.9l9.5-9.5L0.4,2C0.1,1.8,0,1.5,0,1.2c0-0.3,0.1-0.6,0.4-0.9c0.5-0.5,1.2-0.5,1.7,0
	l10.4,10.4C12.7,11,12.8,11.3,12.8,11.6z M20.6,10.7L10.3,0.3C9.8-0.1,9-0.1,8.6,0.3C8.1,0.8,8.1,1.6,8.6,2l9.5,9.5l-9.5,9.5
	c-0.5,0.5-0.5,1.2,0,1.7c0.2,0.2,0.5,0.4,0.9,0.4c0.3,0,0.6-0.1,0.9-0.3l10.4-10.4C21.1,12,21.1,11.2,20.6,10.7z"/>
    </Svg>
  )
};

export default IconChevronDoubleRight;
