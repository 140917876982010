import Api from "./Api";
import { options } from "./Player";
import sendAnalyticsEvent from '../../utils/sendAnalyticsEvent';

export default class Analytics {
  constructor(api, options) {
    this.api = api;
    this.eventCategory = options.eventCategory || "Player";
    this.playerName = options.playerName || "sz-player";

    this.percentsAlreadyTracked = [];
    this.percentsPlayedInterval = 10;
    this.seekStart = 0;
    this.seekEnd = 0;
    this.videoWatchedNumber = 0;
    this.secondsAlreadyTracked = false;

    this.debug = false;
  }

  sendBeacon = fields => {
    if (window.gtag) {
      let gtag =  this.debug ? (a, b, c) => {
        window.gtag(a, b, c);
        console.log('%c%s', 'color: yellow;', 'gtag event:', b, c);
      } : window.gtag;

      gtag("event", fields.action, {
        'event_label': fields.label || window.location.pathname,
        'value': fields.value,
        'event_category': this.eventCategory,
        'non_interaction': fields.nonInteraction || true,
      });
    }
  };

  onReady = () => {
    let loadedTime = 0;
    if (window.performance) {
      loadedTime = Math.floor(performance.now() - window.start);
    }

    this.sendBeacon({
      action: "init",
      label: this.playerName,
      value: loadedTime,
    });
  };

  onLoadedMetadata = () => {
    this.mediaPlay = false;
    let startupTime = window.start ? Math.floor(performance.now() - window.start) : 1;
    this.sendBeacon({
      action: "loadedmetadata",
      value: startupTime,
    });

    let dimensions = Api.getDimensions(this.api.videoEl, options.minHeight);
    this.sendBeacon({
      action: "player-size",
      label: `${dimensions.width}x${dimensions.height}`,
      value: 0,
    });
  };

  onFullscreen = () => {
    this.sendBeacon({
      action: "fullscreen",
      value: 1,
      nonInteraction: false,
    });
  };

  onPlay = () => {
    this.sendBeacon({
      action: "play",
      value: 1,
      nonInteraction: false,
    })
  };

  onEnd = () => {
    this.sendBeacon({
      action: "end",
      value: 1,
    });

    this.percentsAlreadyTracked = [];
    this.secondsAlreadyTracked = false;
  };

  onTimeUpdate = (duration, currentTime) => {
    let i, percent, percentPlayed, ref;

    if (currentTime >= 1 && window.location.href !== this.videoWatchedLocation) {
      this.videoWatchedLocation = window.location.href;
      this.videoWatchedNumber++;

      this.sendBeacon({
        action: "video watched",
        value: this.videoWatchedNumber,
      });
    }

    percentPlayed = Math.round((currentTime / duration) * 100);

    if (percentPlayed >= 1 && !this.mediaPlay) {
      this.mediaPlay = true;
      // sendAnalyticsEvent('PLAYER_MEDIA_PLAY');
    }

    let second = 20;
    if (currentTime >= second && !this.secondsAlreadyTracked) {
      this.sendBeacon({
        action: "sec play",
        value: second,
      });
      this.secondsAlreadyTracked = true;
    }

    for (percent = i = 0, ref = this.percentsPlayedInterval; i <= 99; percent = i += ref) {
      if (percentPlayed >= percent && this.percentsAlreadyTracked.indexOf(percent) < 0) {
        if (percent === 0 && percentPlayed > 0) {
          this.sendBeacon({
            action: "start",
            value: 1,
          });

        } else if (percentPlayed !== 0) {
          this.sendBeacon({
            action: "percent played",
            value: percent,
          });
        }

        if (percentPlayed > 0) {
          this.percentsAlreadyTracked.push(percent);
        }
      }
    }

    this.seekStart = this.seekEnd;
    this.seekEnd = currentTime;

    if (Math.abs(this.seekStart - this.seekEnd) > 1) {
      this.sendBeacon({
        action: "seek start",
        value: this.seekStart,
        nonInteraction: false,
      });
    }
  };
}
