const Events = {
  'onContentResumeRequested': 'CONTENT_RESUME_REQUESTED',
  'onContentPauseRequested': 'CONTENT_PAUSE_REQUESTED',
  'onClick': 'CLICK',
  'onDurationChange': 'DURATION_CHANGE',
  'onExpandedChanged': 'EXPANDED_CHANGED',
  'onStarted': 'STARTED',
  'onImpression': 'IMPRESSION',
  'onPaused': 'PAUSED',
  'onResumed': 'RESUMED',
  'onAdProgress': 'AD_PROGRESS',
  'onAdBuffering': 'AD_BUFFERING',
  'onFirstQuartile': 'FIRST_QUARTILE',
  'onMidpoint': 'MIDPOINT',
  'onThirdQuartile': 'THIRD_QUARTILE',
  'onComplete': 'COMPLETE',
  'onUserClose': 'USER_CLOSE',
  'onLinearChanged': 'LINEAR_CHANGED',
  'onLoaded': 'LOADED',
  'onAdCanPlay': 'AD_CAN_PLAY',
  'onAdMetadata': 'AD_METADATA',
  'onAdBreakReady': 'AD_BREAK_READY',
  'onInteraction': 'INTERACTION',
  'onAllAdsCompleted': 'ALL_ADS_COMPLETED',
  'onSkipped': 'SKIPPED',
  'onSkippableStateChanged': 'SKIPPABLE_STATE_CHANGED',
  'onLog': 'LOG',
  'onViewableImpression': 'VIEWABLE_IMPRESSION',
  'onVolumeChanged': 'VOLUME_CHANGED',
  'onVolumeMuted': 'VOLUME_MUTED',
};

export default Events;
