import React from 'react';
import renderMessage from '../utils/renderMessage';
import { connect } from 'react-redux';
import ReactPlayer from "../components/ReactPlayer";
import { handlePreLand } from "../actions/VideoPageActions";

const PlayerContainer = props => {
  const { data, vasts, nextVideoUrl, history, isPreLandShown, handlePreLand, siteFeatures } = props;

  function duration() {
    const arData = data.length.split(':');
    return +arData[0]*60 + +arData[1];
  }

  return (
    <ReactPlayer
      {...data}
      secDuration={duration()}
      vasts={vasts}
      nextVideoUrl={nextVideoUrl}
      history={history}
      isPreLandShown={Boolean(isPreLandShown)}
      handlePreLand={handlePreLand}
      siteFeatures={siteFeatures} />
  );
}

const mapStateToProps = store => {
  return {
    isPreLandShown: store.videoPage.isPreLandShown,
    data: store.videoPage.data,
    nextVideoUrl: store.videoPage.nextVideoUrl,
    vasts: store.ads.vasts,
    siteFeatures: store.sessionParams.siteFeatures,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handlePreLand: isPreLand => dispatch(handlePreLand(isPreLand)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerContainer);
