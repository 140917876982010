import sendAnalyticsEvent from './sendAnalyticsEvent';
import MHelper from "./MHelper";
import setCookie from './setCookie';
import delayedEvents from './delayedEvents';

const cookieLifetime = 0.013889; // время жизни куки в днях 

function afterShowedAds() {
  const eventName = window.isInterstitialLander ? 'LANDER_AD_SHOW' : 'INTERSTITIAL_AD_SHOW';
  sendAnalyticsEvent(eventName, { label: MHelper.orientation });
  setCookie("shownInterstitial", 1, cookieLifetime);
  delayedEvents().send();
}

export default function interstitialObserver() {
  window.addEventListener('hashchange',({ target }) =>{
    if (target.location.hash === '#google_vignette') afterShowedAds();
  });

  document.addEventListener('yad-ad-show', afterShowedAds);

  const treeObserver = new MutationObserver(
    mutationList => {
      mutationList.forEach(mutation => {
        mutation.addedNodes.forEach(node => {
          if(node.tagName === "INS") {
            styleObserver.observe(node, { attributes: true });
          }
        });
      });
    }
  );

  const styleObserver = new MutationObserver(
    entries => {
      const entry = entries[0];

      if (
        (entry.type === "attributes" && entry.attributeName === "style") &&
        (entry.target.attributes.style.value.indexOf('display: block') > -1 ||
        entry.target.attributes.style.value.indexOf('display:block') > -1)
        ) {
          sendAnalyticsEvent('INTERSTITIAL_LEGACY_SHOW', { label: MHelper.orientation });
      }
    }
  );

  let interstitialEl = document.querySelector('[data-vignette-loaded="true"]');

  if (interstitialEl)
    styleObserver.observe(interstitialEl, { attributes: true });
  else
    treeObserver.observe(document.documentElement, {subtree: false, childList: true});
}
