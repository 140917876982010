import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { StaticRouter } from "react-router-dom";
import { renderToString } from 'react-dom/server';
import { ServerStyleSheet } from 'styled-components';
import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/react';
import { sentryParams, sentryDns } from '../params';

import interstitialObserver from '../utils/interstitialObserver';
import configureStore from '../store/configureStore';
import App from '../components/App';

import ReactOnRails from 'react-on-rails';

function pathnameWithSlash(pathname) {
  return pathname.slice(-1) === '/' ? pathname : pathname + '/';
}

function main_static(pathname) {
  let history = { location: { pathname: pathnameWithSlash(pathname) } };
  const store = ReactOnRails.getStore('store');
  //let store = configureStore(store_d.getState(), history);
  // console.log(JSON.stringify(store.getState()));
  const Html =
    <Provider store={store}>
      <StaticRouter location={pathnameWithSlash(pathname)}>
        <App />
      </StaticRouter>
    </Provider>
    ;

  const sheet = new ServerStyleSheet();
  try {
    renderToString(sheet.collectStyles(Html));
    const Styles = sheet.getStyleElement();
    return (
      <React.Fragment>
        {Styles}
        {Html}
      </React.Fragment>
    );
  } catch (error) {
    console.error(error);
  } finally {
    sheet.seal();
  }
}


function main_browser(railsContext, pathName) {
  Sentry.init({...sentryParams, dsn: sentryDns});

  let store_data = ReactOnRails.getStore('store').getState();
  delete store_data['router'];

  let history = createBrowserHistory();

  if (history.location.pathname.slice(-1) !== '/')
    history.replace({ pathname: location.pathname + '/' });

  if (pathName.indexOf('/404') === 0 )
    history.location.pathname = pathName;

  let store = configureStore(store_data, history);

  interstitialObserver();

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>
  );
}

const Main = (props, railsContext) => {
  const { location } = props;
  if (typeof window !== 'undefined') {
    return main_browser(railsContext, location);
  } else {
    return main_static(location);
  }
};

// store.dispatch(push('/path/to/somewhere'));
export default Main;
