import React from 'react';
import renderMessage from '../utils/renderMessage';
import { connect } from 'react-redux';
import { handleRoute, handlePreLand } from '../actions/VideoPageActions';
import PagePreloader from '../components/PagePreloader';
import PageChannel from '../components/PageChannel';
import PagePreland from '../components/PagePreland';

class PageChannelContainer extends React.Component {
  state = {
    firstShow: true,
    prevPagePreLand: false,
  };

  get isPreLand() {
    return !!this.props.match.params.utm_source;
  }

  get correctPath() {
    const { videoPage, location } = this.props;
    const pathName = location.pathname.indexOf('/utm-') > -1 ?
      location.pathname.slice(0, location.pathname.indexOf('/utm-') + 1) : location.pathname;
    return videoPage.currentPath === pathName;
  }

  get showComponent() {
    const { isFetching } = this.props.videoPage;
    return !isFetching && (this.correctPath || !this.state.firstShow);
  }

  componentDidMount() {
    const { isFetching } = this.props.videoPage;

    if (!isFetching && !this.correctPath) {
      this.props.handleRoute(this.props.location.pathname);
    }

    this.setState({
      firstShow: false,
      prevPagePreLand: this.isPreLand,
    });

    if (this.isPreLand)
      this.props.handlePreLand(true);
  }

  componentDidUpdate(prevProps) {
    const utmPos = prevProps.location.pathname.indexOf('/utm-');
    const prevPathName = utmPos > -1 ?
      prevProps.location.pathname.slice(0, utmPos + 1) : prevProps.location.pathname;

    if (prevProps.location.pathname !== this.props.location.pathname) {

      this.setState({
        firstShow: false,
        prevPagePreLand: this.isPreLand,
      });

      if (!this.state.prevPagePreLand || prevPathName !== this.props.location.pathname)
        this.props.handleRoute(this.props.location.pathname, 'update');
    }
  }

  render() {
    renderMessage('PageChannelContainer');
    const { location, videoPage } = this.props
    const { pageTitle, pageDescription, isFetching, isUpdate } = videoPage;

    return (
      <React.Fragment>
        {this.showComponent && !this.isPreLand && <PageChannel
          pageTitle={pageTitle}
          pageDescription={pageDescription}
        />}
        {this.showComponent && this.isPreLand && <PagePreland
          pathname={location.pathname}
          pageTitle={pageTitle}
          pageDescription={pageDescription}
        />}
        <PagePreloader active={isUpdate || isFetching}/>
      </React.Fragment>
    );
  }
}

const mapStateToProps = store => {
  return {
    videoPage: store.videoPage,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleRoute: (pathname, isUpdate) => dispatch(handleRoute(pathname, isUpdate)),
    handlePreLand: isPreLand => dispatch(handlePreLand(isPreLand)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageChannelContainer);
