import React from 'react';
import PropTypes from 'prop-types';
import renderMessage from '../utils/renderMessage';

import setMeta from "../utils/setMeta";
import HomeSection from './HomeSection';
import CarouselContainer from "../containers/CarouselContainer";

class PageHome extends React.Component {
  componentDidMount() {
    setMeta(this.props.pageTitle, this.props.pageDescription);
  }

  componentDidUpdate () {
    setMeta(this.props.pageTitle, this.props.pageDescription);
  }

  renderCarousels = () => {
    const { carousels } = this.props;
    if (!carousels) return;

    return carousels.map((carouselItem, key) => {
      return (
        <React.Fragment key={key}>
          {(carouselItem.items.length) ? <HomeSection
            name={carouselItem.sectionName}
            link={carouselItem.link}>
            <CarouselContainer
              id={key}
              items={carouselItem.items}
              dataPath={carouselItem.dataPath}
              isEnd={carouselItem.isEnd}
              isFetching={carouselItem.isFetching}
            />
          </HomeSection> : false}
        </React.Fragment>
      )
    });
  };

  render() {
    renderMessage('PageHome');
    const { carouselBig } = this.props;

    return (
      <React.Fragment>
        {(carouselBig && carouselBig.items.length) ? <CarouselContainer
          items={carouselBig.items}
          dataPath={carouselBig.dataPath}
          isEnd={carouselBig.isEnd}
          isFetching={carouselBig.isFetching}
          isBig={true}
        /> : false}
        {this.renderCarousels()}
      </React.Fragment>
    );
  }
}

export default PageHome;

PageHome.propTypes = {
  pageTitle: PropTypes.string,
  pageDescription: PropTypes.string,
  carouselBig: PropTypes.object,
  carousels: PropTypes.arrayOf(
    PropTypes.shape({
      sectionName: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      dataPath: PropTypes.string.isRequired,
      items: PropTypes.array.isRequired,
    }).isRequired
  ).isRequired,
};
