const canAutoplay = (muted = false) => {
  const video = document.createElement('video');
  video.autoplay = true;
  video.muted = muted;
  video.setAttribute('webkit-playsinline', 'webkit-playsinline');
  video.setAttribute('playsinline', 'playsinline');
  video.src = 'data:video/mp4;base64,AAAAHGZ0eXBpc29tAAACAGlzb21pc28ybXA0MQAAAAhmcmVlAAAAMm1kYXTeBABMYXZjN' +
    'TcuMTA3LjEwMABCIAjBGDghEARgjBwhEARgjBwhEARgjBwAAAMbbW9vdgAAAGxtdmhkAAAAAAAAAAAAAAAAAAAD6AAAAEsAAQAAAQ' +
    'AAAAAAAAAAAAAAAAEAAAAAAAAAAAAAAAAAAAABAAAAAAAAAAAAAAAAAABAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAgA' +
    'AAkV0cmFrAAAAXHRraGQAAAADAAAAAAAAAAAAAAABAAAAAAAAAEsAAAAAAAAAAAAAAAEBAAAAAAEAAAAAAAAAAAAAAAAAAAABAAAA' +
    'AAAAAAAAAAAAAABAAAAAAAAAAAAAAAAAAAAkZWR0cwAAABxlbHN0AAAAAAAAAAEAAAA1AAAEAAABAAAAAAG9bWRpYQAAACBtZGhkA' +
    'AAAAAAAAAAAAAAAAAC7gAAADgZVxAAAAAAALWhkbHIAAAAAAAAAAHNvdW4AAAAAAAAAAAAAAABTb3VuZEhhbmRsZXIAAAABaG1pbm' +
    'YAAAAQc21oZAAAAAAAAAAAAAAAJGRpbmYAAAAcZHJlZgAAAAAAAAABAAAADHVybCAAAAABAAABLHN0YmwAAABqc3RzZAAAAAAAAAA' +
    'BAAAAWm1wNGEAAAAAAAAAAQAAAAAAAAAAAAIAEAAAAAC7gAAAAAAANmVzZHMAAAAAA4CAgCUAAQAEgICAF0AVAAAAAAH0AAAAEYwF' +
    'gICABRGQVuUABoCAgAECAAAAMHN0dHMAAAAAAAAABAAAAAEAAAQAAAAAAQAABBgAAAABAAAEAAAAAAEAAAHuAAAAHHN0c2MAAAAAA' +
    'AAAAQAAAAEAAAAEAAAAAQAAACRzdHN6AAAAAAAAAAAAAAAEAAAAGAAAAAYAAAAGAAAABgAAABRzdGNvAAAAAAAAAAEAAAAsAAAAGn' +
    'NncGQBAAAAcm9sbAAAAAIAAAAB//8AAAAcc2JncAAAAAByb2xsAAAAAQAAAAQAAAABAAAAYnVkdGEAAABabWV0YQAAAAAAAAAhaGR' +
    'scgAAAAAAAAAAbWRpcmFwcGwAAAAAAAAAAAAAAAAtaWxzdAAAACWpdG9vAAAAHWRhdGEAAAABAAAAAExhdmY1Ny44My4xMDA=';
  video.load();
  video.style.display = 'none';
  video.playing = false;
  return video.play();
};

export default canAutoplay;
