import ajaxPost from "./ajaxPost";

export default class PerformanceMonitor {
  constructor({measures}) {
    for(let key in measures) {
      this.addMeasure({[key]: measures[key]});
    }
  }

  addMeasure(obj) {
    let measureName = Object.keys(obj);
    let measureStart = obj[measureName][1] ? obj[measureName][0] : 'navigationStart';
    let measureEnd = obj[measureName][1] ? obj[measureName][1] : obj[measureName][0];

    if((PerformanceMonitor.testMark(measureStart) && PerformanceMonitor.testMark(measureEnd))
      ||
      ((measureStart in window.performance.timing) && (measureEnd in window.performance.timing))) {
      window.performance.measure(measureName, measureStart, measureEnd);
    } else {
      if(!PerformanceMonitor.testMark(measureStart))
        console.info(`The mark ${measureStart} does not exist.`);
      if(!PerformanceMonitor.testMark(measureEnd))
        console.info(`The mark ${measureEnd} does not exist.`);
    }
  }

  sendReport(url) {
    ajaxPost(url, JSON.stringify(this.report), "application/json; charset=utf-8").catch(error => {
      throw new Error(error);
    });
  }

  static testMark(markName) {
    return window.performance.getEntriesByName(markName).length;
  }

  get report() {
    let report = {};
    window.performance.getEntriesByType('measure').forEach(measure => {
      report[measure.name] = parseInt(measure.duration);
    });
    report.location = window.location.href;

    return report;
  }

  static showLog() {
    console.log('measure', window.performance.getEntriesByType('measure'));
    console.log('mark', window.performance.getEntriesByType('mark'));
  }
}
