import PerformanceMonitor from './utils/PerformanceMonitor';
import { performanceMonitor } from "./params";

if (typeof window !== 'undefined' && window.performance.getEntriesByName) {
  window.addEventListener('load', () => {
    let monitor = new PerformanceMonitor({
      measures: {
        measureRequestStart: ['requestStart'],
        measureResponseEnd: ['responseEnd'],
        measureDomLoading: ['domLoading'],
        measureDomInteractive: ['domInteractive'],
        measureDomContentLoadedEnd: ['domContentLoadedEventEnd'],
        measureDomComplete: ['domComplete'],
      }
    });

    monitor.sendReport(performanceMonitor);
  });
}
