import React from "react";
import styled, {keyframes} from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`;

const StyledIcon = styled.svg`
  animation: ${rotate} 2s linear 0s infinite normal;
`;

export default function Spinner({className}){
  return (
    <StyledIcon className={className} viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <g transform="translate(4 4)" strokeWidth="2" stroke="#fff">
          <circle strokeOpacity="0.35" cx="18" cy="18" r="18"/>
          <path d="M36 18c0-9.94-8.06-18-18-18"/>
        </g>
      </g>
    </StyledIcon>
  )
};
