import React from 'react';
// import PlayArrow from '../icons/PlayArrow';
import Redo from "../icons/Redo";
import Spinner from '../icons/Spinner';
import Report from '../icons/Report';
import styled, {css} from "styled-components";

const Component = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  color: #fff;
  text-align: center;
  cursor: pointer;
  background-color: transparent;
  -webkit-cursor-visibility: auto-hide;
  z-index: 1;
  
  opacity: ${props => props.firstPlay ? '0' : '1'};
  
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, transparent, rgba(0,0,0,0.4) 77%, rgba(0,0,0,0.4) 100%) 100% 100% / 100% 240px no-repeat transparent;
    opacity: 0;
  }

  .controls-show &::before {
    transition: opacity 250ms cubic-bezier(0, .25, .25, 1),background-size 250ms cubic-bezier(0, .25, .25, 1);
    opacity: 1;
  }
`;

const Inner = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  right: 0;
  left: 50%;
  width: 60px;
  height: 60px;
  transform: translateY(-50%);
  margin-left: -30px;
  background-color: rgba(0,0,0,0.65);
  border-radius: 7px;
  outline: none;
`;

const SpinInner = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  right: 0;
  left: 50%;
  width: 100px;
  height: 100px;
  transform: translateY(-50%);
  margin-left: -50px;
  outline: none;
  border-radius: 50%;
  background: rgba(0,0,0,0.03);
  box-shadow: 0 0 40px 40px rgba(0,0,0,0.03);
  filter: drop-shadow(0 0 5px rgba(0,0,0,0.8));
`;

const IconStyles = css`
  height: 40px;
  width: 40px;
  fill: #ffffff;
`;

const SpinStyled = styled(Spinner)`
  height: 100px;
  width: 100px;
  fill: #ffffff;
`;

const ReportStyled = styled(Report)`
  ${IconStyles};
`;

const RedoStyled = styled(Redo)`
  ${IconStyles};
`;

// const PlayArrowStyled = styled(PlayArrow)`
//  ${IconStyles};
// `;

export default class Overlay extends React.Component {
  renderContent() {
    const {
      error,
      loading,
      adLoading,
      ended
    } = this.props;

    if (error) {
      return (
        <Inner>
          <ReportStyled/>
        </Inner>
      );
    } else if (loading || adLoading) {
      return (
        <SpinInner>
          <SpinStyled/>
        </SpinInner>
      );
    } else if (ended) {
      return (
        <Inner>
          <RedoStyled/>
        </Inner>
      )
    }
  }

  render() {
    const {className, onClick, firstPlay} = this.props;
    return (
      <Component
        className={[className].join(' ')}
        onClick={onClick}
        firstPlay={firstPlay}>
        {this.renderContent()}
      </Component>
    );
  }
}
