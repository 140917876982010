import React from 'react';
import Svg from "./Svg";

const IconChevronRoundLeft = props => {
  return (
    <Svg
      title="ChevronRoundLeft Icon"
      size={props.size || 32}
      className={props.className}
      width={32}
      height={32}>
      <path d="M16,32c8.8,0,16-7.2,16-16S24.8,0,16,0S0,7.2,0,16S7.2,32,16,32z M15.8,9H21l-6.2,7l6.2,7h-5.2l-6.2-7L15.8,9z"/>
    </Svg>
  )
};

export default IconChevronRoundLeft;
