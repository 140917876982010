import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme, { breakpoint } from './theme';
import Button from './primitives/Button';
import { menuHide, menuShow } from '../actions/MenuActions';
import { connect } from 'react-redux';
import IconMenu from './Icons/IconMenu';
import IconClose from './Icons/IconClose';

const MenuButtonStyled = styled(Button)`
  margin-right: 8px;
  padding: 7px 4px;
  color: ${theme.color.bright};
  width: 31px;
  justify-content: center;
  z-index: 2;

  &:hover {
    color: ${theme.color.bright_h};
  }

  ${breakpoint.tabletPortrait}, ${breakpoint.desktopOnly.slice(6)} {
    margin-right: 14px;
  }

  ${breakpoint.desktopBigUp} {
    margin-right: 31px;
    width: 42px;
  }
`;

const IconMenuStyled = styled(IconMenu)`
  fill: ${theme.color.bright};
`;

const IconCloseStyled = styled(IconClose)`
  fill: white;
  padding: 2px;
`;

class MenuButton extends React.Component {
  onClick = () => {
    const { active, rendered, menuShow, menuHide } = this.props;

    if (!active) {
      menuShow(rendered);
    } else {
      menuHide();
    }
  };

  render() {
    const { active } = this.props;

    return (
      <MenuButtonStyled onClick={this.onClick} className="menu-button">
        {!active && <IconMenuStyled size={34.5}/>}
        {active && <IconCloseStyled size={30}/>}
      </MenuButtonStyled>
    )
  }
}

MenuButton.propTypes = {
  active: PropTypes.bool.isRequired,
  rendered: PropTypes.bool.isRequired,
  menuShow: PropTypes.func.isRequired,
  menuHide: PropTypes.func.isRequired,
};

const mapStateToProps = store => {
  return {
    active: store.menu.active,
    rendered: store.menu.rendered,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    menuShow: (rendered) => dispatch(menuShow(rendered)),
    menuHide: () => dispatch(menuHide()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MenuButton);
