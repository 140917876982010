import React from 'react';
import renderMessage from '../utils/renderMessage';
import { connect } from 'react-redux';
import Carousel from '../components/Carousel';
import addSlides from '../actions/HomePageActions';

class CarouselContainer extends React.Component {
  render() {
    renderMessage('CarouselContainer');

    const { addSlides } = this.props;
    return (
      <Carousel {...this.props} addSlides={addSlides}/>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addSlides: (id, url, isBig) => dispatch(addSlides(id, url, isBig)),
  };
};

export default connect(
  false,
  mapDispatchToProps,
)(CarouselContainer);
