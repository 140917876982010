import React from 'react';
import styled from 'styled-components';
import theme, { breakpoint } from './theme';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import IconChevronDoubleRight from "./Icons/IconChevronDoubleRight";

const Section = styled.section`
  background: white;
  border-radius: 4px;
  padding: 10px 5px 7px;
  margin: 10px;
  box-shadow: ${theme.shadow.common};

  ${breakpoint.tabletOnly} {
    padding-top: 13px;
    padding-bottom: 10px;
  }
  
  ${breakpoint.desktopUp} {
    background: transparent;
    box-shadow: none;
    margin: 40px 50px;
    padding: 0;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 0 8px 5px;
  
  ${breakpoint.desktopUp} {
    padding-bottom: 11px;
  }
`;

const Title = styled.h2`
  font-size: 16px;
  font-weight: bold;

  ${breakpoint.tabletUp} {
    font-size: 24px;
  }
`;

const LinkStyled = styled(Link)`
  color: ${theme.color.bright};
  text-decoration: none;
  font-weight: 500;
  font-size: 12px;
  margin-left: 10px;
  white-space: nowrap;

  ${breakpoint.tabletUp} {
    font-size: 18px;
    margin-left: 20px;
  }
`;

const ChevronDoubleRight = styled(IconChevronDoubleRight)`
  width: 6px;
  height: 100%;
  fill: ${theme.color.bright};
  
  ${breakpoint.tabletUp} {
    width: 10px;
  }
`;

const HomeSection = props => {
  return (
    <Section>
      <Header>
        <Title>{props.name}</Title>
        <LinkStyled to={props.link}>see more <ChevronDoubleRight/></LinkStyled>
      </Header>
      {props.children}
    </Section>
  )
};

export default HomeSection;

HomeSection.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};
