// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'intersection-observer';
import configureStore from '../store/configureStore';

import ReactOnRails from 'react-on-rails';

ReactOnRails.registerStore({
  store: configureStore,
});

import Main from '../root_components/Main';

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
  Main,
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// Support component names relative to this directory:
//const componentRequireContext = require.context("root_components", true);
//const ReactRailsUJS = require("react_ujs");
//ReactRailsUJS.useContext(componentRequireContext);
