import { createGlobalStyle } from 'styled-components';
import theme from './theme';
import RobotoRegularCyrillic from '../libs/Roboto/RobotoRegularCyrillic.woff2';
import RobotoRegularLatin from '../libs/Roboto/RobotoRegularLatin.woff2';
import RobotoMediumCyrillic from '../libs/Roboto/RobotoMediumCyrillic.woff2';
import RobotoMediumLatin from '../libs/Roboto/RobotoMediumLatin.woff2';
import RobotoBoldCyrillic from '../libs/Roboto/RobotoBoldCyrillic.woff2';
import RobotoBoldLatin from '../libs/Roboto/RobotoBoldLatin.woff2';


const GlobalStyle = createGlobalStyle`
  // RESET
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
    font-family: sans-serif;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  } 
  // END RESET

  // FONTS
  /* cyrillic */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Roboto'), local('Roboto-Regular'), url(${RobotoRegularCyrillic}) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }

  /* latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Roboto'), local('Roboto-Regular'), url(${RobotoRegularLatin}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  /* cyrillic */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local('Roboto Medium'), local('Roboto-Medium'), url(${RobotoMediumCyrillic}) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }

  /* latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local('Roboto Medium'), local('Roboto-Medium'), url(${RobotoMediumLatin}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  /* cyrillic */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Roboto Bold'), local('Roboto-Bold'), url(${RobotoBoldCyrillic}) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }

  /* latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Roboto Bold'), local('Roboto-Bold'), url(${RobotoBoldLatin}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  // END FONTS

  // BOX SIZING
  html {
    box-sizing: border-box;
    -webkit-text-size-adjust: none;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  
  // INITIAL STYLES
  body {
    margin: 0;
    padding: 0;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    line-height: 1.25;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  
  a {
    color: ${theme.color.common};
  }
  
  .root {
    background-color: ${theme.color.bg};
  }
  // END INITIAL STYLES

  //* { background-color: rgba(255,0,0,.2); }
  //* * { background-color: rgba(0,255,0,.2); }
  //* * * { background-color: rgba(0,0,255,.2); }
  //* * * * { background-color: rgba(255,0,255,.2); }
  //* * * * * { background-color: rgba(0,255,255,.2); }
  //* * * * * * { background-color: rgba(255,255,0,.2); }
  //* * * * * * * { background-color: rgba(255,0,0,.2); }
  //* * * * * * * * { background-color: rgba(0,255,0,.2); }
  //* * * * * * * * * { background-color: rgba(0,0,255,.2); }
`;

export default GlobalStyle;
