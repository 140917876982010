import React from 'react';
import renderMessage from '../utils/renderMessage';
import { connect } from 'react-redux';
import handleLogin from '../actions/HeaderActions';
import Header from '../components/Header';

class HeaderContainer extends React.Component {
  render() {
    renderMessage('HeaderContainer');

    const { header, handleLogin } = this.props;
    return (
      <Header
        username={header.username}
        cookiePolicy={header.cookiePolicy}
        error={header.error}
        isFetching={header.isFetching}
        handleLogin={handleLogin}
      />
    );
  }
}

const mapStateToProps = store => {
  return {
    header: store.header,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleLogin: () => dispatch(handleLogin()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HeaderContainer);
