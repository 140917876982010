import React from 'react';
import styled from "styled-components";

const Component = styled.div`
  padding: 0 10px;
  color: #fff;
`;

const Current = styled.span`
  margin-right: 5px;
`;

const Duration = styled.span`
  margin-left: 5px;
  color: #f2f2f2;
`;

const formatTime = (seconds) => {
  const date = new Date(Date.UTC(1970, 1, 1, 0, 0, 0, 0));
  seconds = isNaN(seconds) || seconds > 86400
    ? 0
    : Math.floor(seconds);
  date.setSeconds(seconds);
  const duration = date.toISOString().substr(11, 8).replace(/^0{1,2}:?0{0,1}/, '');
  return duration;
};

export default ({currentTime, duration, className}) => {
  return (
    <Component className={[
      className
    ].join(' ')}>
      <Current>
        {formatTime(currentTime)}
      </Current>
      /
      <Duration>
        {formatTime(duration)}
      </Duration>
    </Component>
  );
};
