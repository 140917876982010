import React from 'react';
import Svg from "./Svg";

const IconChevronRoundRight = props => {
  return (
    <Svg
      title="ChevronRoundRight Icon"
      size={props.size || 32}
      className={props.className}
      width={32}
      height={32}>
      <path d="M16,0C7.2,0,0,7.2,0,16s7.2,16,16,16s16-7.2,16-16S24.8,0,16,0z M16.2,23h-5l6-7l-6-7h5.2l6,7L16.2,23z"/>
    </Svg>
  )
};

export default IconChevronRoundRight;
