import React from 'react';
import Svg from "./Svg";

const IconChevronLeft = props => {
  return (
    <Svg
      title="ChevronLeft Icon"
      size={props.size || 32}
      className={props.className}
      width={32}
      height={32}>
      <polygon points="17.1,32 29,32 14.9,16 29,0 17.1,0 3,16 "/>
    </Svg>
  )
};

export default IconChevronLeft;
