import React from 'react';
import Svg from "./Svg";

const IconLogoFooter = props => {
  return (
    <Svg
      title="Close Icon"
      size={props.size || 110}
      className={props.className}
      width={110}
      height={140}>
      <g filter="url(#filter0_di_27_1179)">
      <path fillRule="evenodd" clipRule="evenodd" d="M102.502 136V87.8671C102.505 78.3811 99.6946 69.1072 94.4258 61.2185C89.1569 53.3298 81.6665 47.1804 72.902 43.5483C64.1375 39.9161 54.4925 38.9642 45.1869 40.8131C35.8813 42.6619 27.3331 47.2284 20.6233 53.935C13.9136 60.6415 9.34375 69.187 7.49174 78.4905C5.63974 87.794 6.58875 97.4377 10.2188 106.202C13.8489 114.966 19.9969 122.457 27.8853 127.728C35.7737 132.998 45.0481 135.811 54.5357 135.811C65.4035 135.834 75.9446 132.098 84.3719 125.237V135.811H102.517L102.502 136ZM84.7496 87.8671C84.7467 93.8032 82.9836 99.6053 79.6833 104.54C76.383 109.474 71.6936 113.32 66.2078 115.59C60.7221 117.86 54.6862 118.454 48.8633 117.295C43.0404 116.136 37.6918 113.277 33.4937 109.08C29.2955 104.882 26.4362 99.5345 25.2772 93.7125C24.1182 87.8906 24.7115 81.8558 26.9822 76.3709C29.2529 70.886 33.099 66.1974 38.0343 62.8976C42.9696 59.5978 48.7726 57.8351 54.7098 57.8321C62.6593 57.8806 70.2696 61.0586 75.8923 66.6776C81.5149 72.2965 84.6972 79.9041 84.7496 87.8523V87.8671Z" fill="#E43451"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M103.64 18.7213C90.1703 6.6087 72.6311 -0.0680652 54.4623 0.000523241C36.2935 0.0691117 18.8059 6.8781 5.4293 19.0921C4.3613 19.9664 3.50057 21.064 2.90836 22.3066C2.31615 23.5491 2.00704 24.9062 2.00309 26.2809C1.97125 27.4814 2.18583 28.6758 2.63372 29.7912C3.08161 30.9067 3.75345 31.9199 4.60828 32.769C5.46312 33.6182 6.48305 34.2856 7.60594 34.7305C8.72884 35.1754 9.93117 35.3885 11.1397 35.3569C15.5176 34.9862 16.279 32.8989 19.3245 30.2517C29.36 22.1585 41.9956 17.9293 54.9153 18.3395C68.0155 17.9279 80.8132 22.3005 90.8868 30.6299C93.742 33.277 94.1227 35.1678 98.8813 35.3569C100.084 35.3704 101.277 35.1447 102.391 34.6931C103.504 34.2415 104.516 33.5732 105.365 32.7275C106.215 31.8818 106.886 30.8757 107.338 29.7686C107.79 28.6615 108.015 27.4757 107.999 26.2809C107.558 23.3049 106.001 20.6045 103.64 18.7213Z" fill="#E43451"/>
      </g>
      <defs>
      <filter id="filter0_di_27_1179" x="0" y="0" width="110" height="140" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="2"/>
      <feGaussianBlur stdDeviation="1"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.279167 0 0 0 0 0.399306 0 0 0 1 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_27_1179"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_27_1179" result="shape"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="3"/>
      <feGaussianBlur stdDeviation="2"/>
      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
      <feBlend mode="normal" in2="shape" result="effect2_innerShadow_27_1179"/>
      </filter>
      </defs>
    </Svg>
  )
};

export default IconLogoFooter;
