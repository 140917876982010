import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { breakpoint } from "./theme";
import MHelper from '../utils/MHelper';

const AdStyled = styled.div`
  ${breakpoint.mobileOnly} {
    min-height: ${props => props.Type === 'related'? '121px' : false};
  }

  ${breakpoint.mobileSmallLandscape} {
    min-height: ${props => props.Type === 'related' && props.isSmallRelated ? '85px' : false};
  }

  ${breakpoint.tabletOnly} {
    min-height: ${props => props.Type === 'related' && !props.isSmallRelated ? '102px;' : false};
  }

  ${breakpoint.tabletPortrait} {
    min-height: ${props => props.Type === 'related' && props.isSmallRelated ? '102px;' : false};
  }

  ${breakpoint.tabletLandscape} {
    min-height: ${props => props.Type === 'related' && props.isSmallRelated ? '100px' : false};
  }

  ${breakpoint.desktopUp} {
    min-height: ${props => props.Type === 'related'? '157px' : false};
  }

  overflow: ${props => props.ifAdsense ? 'visible!important' : false};
  overflow-x: ${props => props.ifAdsense ? 'visible!important' : false};
`;

class Ad extends React.Component {

  componentDidMount() {
    const { code, isPreLand, isFetching, lazy } = this.props;
    if(MHelper.ifSSR || !code || isPreLand || isFetching) return false;

    if (!lazy) this.show();
    else this.observer.observe(this.container);

    window.addEventListener('orientationchange', this.show);
  }

  componentWillUnmount() {
    window.removeEventListener('orientationchange', this.show);
    this.observer.disconnect();
  }

  componentDidUpdate(prevProps) {
    const { type, pathname, lazy } = this.props;

    if (type === 'rebootable' && pathname !== prevProps.pathname) {
      if (!lazy) this.show();
      else this.observer.observe(this.container);
    }
  }

  get observer() {
    if(MHelper.ifSSR) return false;
    const { lazy } = this.props;

    this._observer = new IntersectionObserver(
      entries => {
        const { isIntersecting } = entries[0];

        if (isIntersecting) {
          // console.log('%c%s', 'color: green;', 'intersection', this.props.analyticsAction);
          if (lazy) this.init()

          if (this._observer) {
            this._observer.disconnect();
            this._observer = null;
          }
        }
      },
      { threshold: [0.66] }
    );

    return this._observer;
  }

  show = () => setTimeout(() => {
    if (this.container) this.init();
  }, 1000);

  setInitHeaderName(name) {
    if (!window.initHeaderName) window.initHeaderName = [];
    window.initHeaderName.push(name);
  }

  getInitHeaderName(name) {
    return window.initHeaderName ? window.initHeaderName.includes(name): false;
  } 

  setInitSlotName(name) {
    if (!window.initSlotName) window.initSlotName = [];
    window.initSlotName.push(name);
  }

  getInitSlotName(name) {
    return window.initSlotName ? window.initSlotName.includes(name): false;
  } 

  appendHeader = () => {
    const { name, header } = this.props;
    const regex = /\[(\[|\')(.*?)(\]|\')\]/g;
    const str = header.match(regex)[0]
    if (str) {
      this.setInitHeaderName(name);
      const width = this.container.offsetWidth;
      const arr = JSON.parse(str.replace(/'/g, '"'));
      const filteredArr = arr.filter(subarr => 
        typeof subarr === 'string' || (typeof subarr === 'object' && subarr[0] <= width)
      );

      const fixedHeaderScript = header.replace(regex, JSON.stringify(filteredArr));
      const fixedHeader = this.regexp.exec(fixedHeaderScript)[1];
      const headerWithVar = fixedHeader.replace('googletag.defineSlot', `window.slot_${name} = googletag.defineSlot`);
      this.executeScript(headerWithVar);
    }
  };

  init() {
    const { header, name, afterInit }  = this.props;
    if (header && !this.getInitHeaderName(name)) this.appendHeader();
    if (afterInit) afterInit();
    this.container.innerHTML = this.html;

    if (!this.checkAdx) {
      this.executeScript(this.script);
    } else if (window.googletag && window.googletag.pubads) {
      const slotName = 'slot_' + name;

      if (this.getInitSlotName(slotName)) {
        googletag.pubads().refresh([window[slotName]]);
      } else if (header) {
        this.setInitSlotName(slotName);
        this.executeScript(this.script);
      } else {
        this.executeScript(this.script);
      }
    }
  }

  executeScript = (code) => {
    const func = new Function(code);
    func();
  }

  get regexp() {
    return /<script\b[^>]*>([\s\S]*?)<\/script>/;
  }

  get isMatchReplaceable() {
    return window.matchMedia(this.props.replaceable).matches;
  }

  get html() {
    const { code } = this.props;
    return code.replace(this.regexp, '');
  }

  get script() {
    const scriptCode = this.regexp.exec(this.props.code);
    if (scriptCode.length === 2)
      return scriptCode[1];
  }

  get checkAdx() {
    return this.script && this.script.indexOf('googletag.cmd.push') > -1;
  }

  render() {
    const { className, type, replaceable, isSmallRelated, isPreLand, code, analyticsAction, analyticsActionSpecial } = this.props;

    return (
      <AdStyled
        isSmallRelated={isSmallRelated}
        Type={type}
        ifAdsense={!this.checkAdx}
        replaceable={replaceable}
        className={className}
        data-code={isPreLand ? code : null}
        data-analytics-action={analyticsAction}
        data-analytics-action-special={analyticsActionSpecial}
        ref={el => (this.container = el)}
        dangerouslySetInnerHTML={isPreLand ? {__html: ''} : null} />
    )
  }
}

export default Ad;

Ad.propTypes = {
  code: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  replaceable: PropTypes.string,
};
