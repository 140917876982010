import React from 'react';
import VolumeOff from '../icons/VolumeOff';
import VolumeUp from '../icons/VolumeUp';
import styled, { css } from "styled-components";
import MHelper from "../../../utils/MHelper";

const Slider = styled.div`
  display: none;
  position: absolute;
  right: 0;
  bottom: 100%;
  left: 0;
  height: 120px;
  border-radius: 2px 2px 0 0;
`;

const Component = styled.div`
  position: relative;
  opacity: 0.8;
  z-index: 2;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  
  :hover {
    opacity: 1;
    
    ${Slider} {
      display: block;
    }
  }
`;

const Button = styled.button`
  width: 40px;
  height: 40px;
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  cursor: pointer;
  outline: 0;
`;

const IconStyles = css`
  padding: 8px;
  width: 100%;
  height: 100%;
  fill: #ffffff;
`;

const VolumeOffStyled = styled(VolumeOff)`
  ${IconStyles};
`;

const VolumeUpStyled = styled(VolumeUp)`
   ${IconStyles};
`;

const Track = styled.div`
  position: absolute;
  top: 11px;
  bottom: 30px;
  left: 50%;
  width: 5px;
  transform: translateX(-50%);
  background-color: rgba(255,255,255,0.45);
  border-radius: 2px;
`;

const CommonStyles = css`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

const Fill = styled.div`
  ${CommonStyles};
  background-color: #fff;
  border-radius: 2px;
  
  &::before {
    content: '';
    position: absolute;
    right: -2px;
    top: -4px;
    height: 9px;
    width: 9px;
    border-radius: 50%;
    background: white;
    box-shadow: 0 0 7px rgba(0,0,0,0.5);
  }
`;

const Input = styled.input`
  ${CommonStyles};
  padding: 0;
  margin: 0;
  opacity: 0;
  -webkit-appearance: slider-vertical;
  cursor: pointer;
  left: -100%;
  width: 300%;
`;

export default ({onChange, onClick, volume, muted, className, ariaLabelMute, ariaLabelUnmute, ariaLabelVolume}) => {
  const volumeValue = muted || !volume ? 0 : +volume;
  const isSilent = muted || volume <= 0;
  return (
    <Component className={[
      className
    ].join(' ')}>
      <Button
        aria-label={isSilent
          ? ariaLabelUnmute
          : ariaLabelMute}
        onClick={onClick}
        type="button">
        {isSilent ?
          <VolumeOffStyled
            height={20}
            width={20}/>
          :
          <VolumeUpStyled
            height={20}
            width={20}/>}
      </Button>
      {!MHelper.hasTouchScreen && <Slider>
        <Track>
          <Fill
            style={{
              height: `${volumeValue * 100}%`
            }}/>
          <Input
            min="0"
            step={0.05}
            max="1"
            type="range"
            orient="vertical"
            onChange={onChange}
            aria-label={ariaLabelVolume}
            value={volumeValue}/>
        </Track>
      </Slider>}
    </Component>
  );
};
