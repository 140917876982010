import React from 'react';
import styled, { keyframes } from 'styled-components';

const animate  = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoaderStyled = styled.div`
  border-radius: 50%;
  color: ${props => props.color ? props.color : '#ccc'} ;
  text-indent: -99999em;
  width: 5em;
  height: 5em;
  box-shadow: inset 0 0 0 0.5em;
  transform: translateZ(0);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;

  &::before, &::after {
    position: absolute;
    content: '';
  }

  &::before {
    width: 2.6em;
    height: 5.2em;
    background: ${props => props.bg ? props.bg : '#e9e9e9'} ;
    border-radius: 2.55em 0 0 2.55em;
    top: -0.1em;
    left: -0.1em;
    transform-origin: 2.6em 2.55em;
  }

  &.enable::before {
    animation: ${animate} ${props => props.speed ? props.speed*2 : 2}s infinite ease ${props => props.speed ? props.speed*1.5 : 1.5}s;
    animation-play-state: ${props => props.paused ? "paused" : "running"};
  }

  &::after {
    width: 2.6em;
    height: 5.2em;
    background: ${props => props.bg ? props.bg : '#e9e9e9'} ;
    border-radius: 0 5.1em 5.1em 0;
    top: -0.1em;
    left: 2.55em;
    transform-origin: 0 2.55em;
  }

  &.enable::after {
    animation: ${animate} ${props => props.speed ? props.speed*2 : 2}s infinite ease;
    animation-play-state: ${props => props.paused ? "paused" : "running"};
  }
`;

class Loader extends React.Component {
  constructor(props) {
    super(props);
    this.setLoaderRef = this.setLoaderRef.bind(this);
  }

  setLoaderRef(node) {
    this.loaderRef = node;
  }

  handleIntersectionObserver = el => {
    if (window.IntersectionObserver) {
      const observer = new IntersectionObserver(intersectionObserverCallback);
      observer.observe(el);

      function intersectionObserverCallback(entries){
        if (entries[0].intersectionRatio === undefined) {
          return false;
        }

        if (entries[0].intersectionRatio > 0) {
          el.classList.add('enable');
        }
      }
    }
  };

  componentDidMount() {
    this.handleIntersectionObserver(this.loaderRef)
  }

  render() {
    const {className, color, bg, speed, paused } = this.props;
    return <LoaderStyled
      className={className}
      color={color}
      bg={bg}
      speed={speed}
      ref={this.setLoaderRef}
      paused={paused}
    />;
  }
}

export default Loader;
