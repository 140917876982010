/**
 *
 * @param {String} string - String with ${variables}
 * @param {Object} data - Object with data where key is variable name for String
 * @return {String} Formatted string
 * @example
 * let str = "this is my ${test}, i'm so glad to use ${test2}";
 * let data = {test:"best String", test2: "this amazing strings"};
 * templateStrings(str,data); //this is my best String, i'm so glad to use this amazing strings
 */
export default function templateStrings(string, data) {
  let formatted = string;

  if (string.indexOf("${" > -1)) {
    for (let arg in data) {
      formatted = formatted.replace(
        new RegExp("\\${" + arg + "}", "g"),
        data[arg]
      );
    }
  }

  return formatted;
}
