import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAIL } from "../constants";

export default function handleLogin() {
  return function(dispatch) {

    // dispatch click button:
    dispatch({
      type: LOGIN_REQUEST,
    });

    // emulate asynchronous request:
    setTimeout(() => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: 'Username',
      });
    }, 1000);
  };
}
