import { MENU_SHOW, MENU_HIDE } from '../constants';

export function menuReducer(state = {}, action) {
  switch (action.type) {


    case MENU_SHOW:
      return { ...state, rendered: true, active: true };

    case MENU_HIDE:
      return { ...state, rendered: true, active: false };

    default:
      return state;
  }
}
