import React from 'react';
import Svg from "./Svg";

const IconChevron = props => {
  return (
    <Svg
      title="Chevron Icon"
      size={props.size || 13}
      className={props.className}
      width={13}
      height={8}>
      <path d="M6.5 8a1.09 1.09 0 0 1-.798-.347L.327 2.017a1.233 1.233 0 0 1 0-1.674 1.099 1.099 0 0 1 1.596 0l4.577 4.8 4.577-4.8a1.099 1.099 0 0 1 1.596 0 1.233 1.233 0 0 1 0 1.674L7.298 7.653A1.09 1.09 0 0 1 6.5 8Z" fill="#fff"/>
    </Svg>
  )
};

export default IconChevron;
