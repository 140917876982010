import React from 'react';
import renderMessage from '../utils/renderMessage';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import PlaylistContainer from '../containers/PlaylistContainer';
import { breakpoint } from './theme';
import setMeta from '../utils/setMeta';

const PageChannelLayout = styled.div`
  ${breakpoint.mobileLandscape} {
    padding: 5px 5px 5px 0;
  }
  
  ${breakpoint.desktopUp} {
    padding: 20px 20px 20px 0;
  }
`;

export default class PageChannel extends React.Component {
  componentDidMount() {
    setMeta(this.props.pageTitle, this.props.pageDescription);
  }

  componentDidUpdate () {
    setMeta(this.props.pageTitle, this.props.pageDescription);
  }

  render() {
    renderMessage('PageChannel');

    return (
      <PageChannelLayout>
        <PlaylistContainer channel={true}/>
      </PageChannelLayout>
    );
  }
}

PageChannel.propTypes = {
  pageDescription: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
};
