import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import theme, { breakpoint } from "./theme";
import uuidv4 from "uuid/v4";
import ajaxPost from "../utils/ajaxPost";
import setCookie from "../utils/setCookie";
import getCookie from "../utils/getCookie";
import MHelper from "../utils/MHelper";
import getGoogleIdentifiers from '../utils/getGoogleIdentifiers';
import sendAnalyticsEvent from '../utils/sendAnalyticsEvent';

const CookieBannerStyled = styled.div`
  position: absolute;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(255,255,255,0.9);

  font-size: 10px;
  padding: 5px;

  box-shadow: 0 3px 6px rgba(0,0,0,0.1);

  transform: ${props => props.isHidden ? 'translate(0, -100%)' : 'translate(0, 0)'};
  opacity: ${props => props.isHidden ? 0 : 1};
  transition: transform 0.2s linear, opacity 0.4s linear;

  ${breakpoint.mobileBigUp} {
    font-size: 13px;
    padding: 5px 10px;
  }

  ${breakpoint.desktopUp} {
    font-size: 15px;
    padding: 20px;
  }
`;

const Text = styled.div`
  line-height: 1.2em;

  ${breakpoint.mobileBigUp} {
    line-height: 1.36em;
  }

  ${breakpoint.desktopUp} {
    line-height: 1.6em;
  }
`;

const ButtonOut = styled.div`
  padding-left: 5px;

  ${breakpoint.mobileBigUp} {
    padding-left: 10px;
  }
`;

const Button = styled.button`
  vertical-align: top;
  padding: 5px 5px 6px;
  font-weight: 500;
  font-family: inherit;
  font-size: inherit;
  border: 0;
  border-radius: 3px;
  background: #15b13e;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;
  margin: 0;

  ${breakpoint.mobileBigOnly} {
    padding: 5px 15px 6px;
  }

  ${breakpoint.tabletUp} {
    padding: 5px 20px 6px;
  }
`;

const A = styled(Link)`
  color: ${theme.color.bright};
`;

export default class CookieBanner extends React.Component {
  constructor(props) {
    super(props);
    this.waitingSend = +getCookie("waitingSend");
    this.userIdentifier = getCookie("userIdentifier");
    this.acceptedVersion = getCookie("cookiePolicy");
    this.currentVersion = this.props.version;
    this.newVersion = !this.acceptedVersion || (this.acceptedVersion !== this.currentVersion);
    this.termsId = "MamaTV Cookie Policy";
    this.googleIdsString = "";
    this.showCondition = (!this.userIdentifier || this.newVersion) && !this.waitingSend && !MHelper.ifSSR;

    if (this.showCondition) {
      sendAnalyticsEvent('SHOW_COOKIE_POLICY', { label: "Show" });
    }

    if (this.waitingSend && !MHelper.ifSSR) {
      this.waitingGa(this.userIdentifier || uuidv4());
    }

    this.state = { isHidden: false };
  }

  handleClick = () => {
    this.setState({ isHidden: true });
    const uuid = this.userIdentifier || uuidv4();

    if (window.gtagLoaded) {
      const { ga, gid } = getGoogleIdentifiers();
      this.googleIdsString = `&ga=${ga}&gid=${gid}`;
      this.request(uuid);
    } else {
      setCookie("waitingSend", 1);
      this.waitingGa(uuid);
    }

    sendAnalyticsEvent('ACCEPT_COOKIE_POLICY');
  };

  waitingGa = uuid => {
    let i = 0;
    const timer = setInterval(() => {
      i++;

      if (window.gtagLoaded) {
        const { ga, gid } = getGoogleIdentifiers();
        this.googleIdsString = `&ga=${ga}&gid=${gid}`;
      }

      if ((window.ga && ga.loaded) || i >= 10) {
        clearInterval(timer);
        setCookie("waitingSend", 0);
        this.request(uuid);
      }
    }, 500);
  };

  request = uuid => {
    let requestString = `user_id=${uuid}&terms_id=${this.termsId}&version=${this.currentVersion}${this.googleIdsString}`;
    this.send(requestString, uuid);
  };

  send = (requestString, uuid) => {
    ajaxPost("https://terms-base.com/api/v1/confirms", requestString)
      .then(() => {
        setCookie("userIdentifier", uuid, 180);
        setCookie("cookiePolicy", this.currentVersion, 180);
      })
      .catch(error => {
        throw new Error(error);
      });
  };

  render() {
    const { isHidden } = this.state;
    return (
      <React.Fragment>
        {this.showCondition && <CookieBannerStyled isHidden={isHidden}>
          <Text>
            We use Cookies for analysis and non-personalized ads. By clicking accept, you agree to our <A
            to="/en/about/privacy">Privacy Policy</A>,
            affirm you're at least 16 y.o. or have parental/guardian consent. <A to="/en/about/cookies">Find out how to Reject
            </A>
          </Text>
          <ButtonOut><Button onClick={this.handleClick}>Accept</Button></ButtonOut>
        </CookieBannerStyled>}
      </React.Fragment>
    )
  }
}

CookieBanner.propTypes = {
  version: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
};
