import React from 'react';
import styled, { css } from "styled-components";
import throttle from "../../utils/throttle";
import canAutoplay from "../../utils/canAutoplay";
import MHelper from "../../utils/MHelper";

// player components
import Overlay from "./components/Overlay";
import Controls from "./components/Controls";
import Seek from "./components/Seek";
import PlayPause from "./components/PlayPause";
import Volume from "./components/Volume";
import Time from "./components/Time";
import Fullscreen from "./components/Fullscreen";
import PlayButton from './components/PlayButton';
import Ad from "./components/Ad";
import Video from "./components/Video";
import labels from "./labels";
import Analytics from "./Analytics";

// player api
import Api from './Api';
import Adv from "./Adv";
import fsApi from "./Api/fsApi";
import StorageApi from "./Api/StorageApi";
import { EVENTS, PROPERTIES } from "./constants";

export const options = {
  controlsTimeout: 3000,
  minHeight: 350,
  controlsShowClass: 'controls-show',
};

const ReactPlayerStyled = styled.div`
  font-size: 12px;
  background: #202020;
  padding-top: ${9 / 16 * 100 + '%'};
  min-height: ${props => {
    if (props.greatHeight && !props.isFullscreen) {
      return options.minHeight + "px";
    } else if (props.isFullscreen) {
      return "100%!important";
    } else {
      return "0";
    }
  }};
  position: ${props => props.isFullscreen ? 'fixed' : 'relative'};
  width: ${props => props.isFullscreen ? '100%!important' : '100%'};
  overflow: ${props => props.isFullscreen ? 'visible' : 'hidden'};
  top: ${props => props.isFullscreen ? '0' : 'auto'};
  bottom: ${props => props.isFullscreen ? '0' : 'auto'};
  left: ${props => props.isFullscreen ? '0' : 'auto'};
  right: ${props => props.isFullscreen ? '0' : 'auto'};

  &.${options.controlsShowClass} {
    .ad-non-linear > div {
      margin-bottom: 60px;
    }
  }
`;

const ControlRowStyles = css`
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 12px;
`;

const ControlRow = styled.div`
  ${ControlRowStyles};
`;

const ControlSpacer = styled.div`
  flex: 1 1 auto;
  align-self: stretch;
`;

class Player extends React.Component {
  constructor(props) {
    super(props);

    const { videoUrl, widthVideoUrl, title, secDuration } = this.props;
    this.state = {
      error: false,
      firstPlay: true,
      loading: true,
      videoLoaded: false,
      ErrorDisableTimeout: true,
      isFullscreen: false,
      fsPlayerSupport: false,
      hoverClassName: '',
      controlsHover: false,
      lastOver: null,
      lastTouchEnd: null,
      adLoading: true,
      seekTouched: false,
      greatHeight: false,
      videoUrl: videoUrl,
      widthVideoUrl: widthVideoUrl,
      title: title,
      secDuration: secDuration,
    };
  }

  getState = () => {
    return this.state;
  };

  setPlayerRef = node => {
    this.containerEl = node;
  };

  setVideoRef = node => {
    this.videoEl = node;
  };

  setSourceRef = node => {
    this.sourceEl = node;
  };

  setAdRef = node => {
    this.adEl = node;
  };

  componentDidMount() {
    this.handleSize();
    window.addEventListener('resize', this.handleSize);
    window.addEventListener('orientationchange', this.handleSize);

    this.api = new Api({
      containerEl: this.containerEl,
      videoEl: this.videoEl,
      sourceEl: this.sourceEl,
      adEl: this.adEl,
      setState: this.setState.bind(this),
      getState: this.getState.bind(this),
      firstPlayHandler: this.firstPlayHandler.bind(this),
      onAutoPlayStart: this.onAutoPlayStart.bind(this),
      onAutoPlayError: this.onAutoPlayError.bind(this),
    });

    this.adv = new Adv(this.api, this.props.vasts, {
      onAdRequest: this.onAdRequest.bind(this),
      onAdImpression: this.onAdImpression.bind(this),
      onAdClick: this.onAdClick.bind(this),
      onAdComplete: this.onAdComplete.bind(this),
      onAdError: this.onAdError.bind(this),
      heightSwitcher: this.heightSwitcher.bind(this),
    });

    this.analytics = new Analytics(this.api, {});

    if (StorageApi.volume) this.videoEl.volume = StorageApi.volume; // set volume from localStorage
    this.setState({ muted: StorageApi.mute });

    if (fsApi.requestFullscreen in this.containerEl) this.setState({ fsPlayerSupport: true });

    this.bindEventsToUpdateState();

    document.dispatchEvent(new Event('playerReady', { bubbles: false, cancelable: false }));
    this.onReady();
  }

  onReady = () => {
    this.props.onReady();
    this.analytics.onReady();

    if (this.props.autoPlay) {
      const caPromise = canAutoplay(StorageApi.mute);
      if (caPromise) {
        caPromise
          .then(() => {
            this.adv.startAd();
          })
          .catch(() => {
            this.api.loadAndPlay(this.adv.startAd);
          });
      }
    }
  };

  onPlayPauseClick = () => {
    this.api.togglePause();
  };

  onOverlayClick = () => {
    const { firstPlay, ended, paused } = this.state;

    if (firstPlay) {
      window.startClick = performance.now();
      this.api.load(); // for ios, otherwise it doesn't play after advertising
      this.adv.startAd();

    } else if (!MHelper.hasTouchScreen && !ended) {
      this.api.togglePause();
    } else if (MHelper.hasTouchScreen && !ended && paused) {
      this.api.play();
    }

    if (ended) {
      this.adv.startAd();
    }
  };

  componentWillUnmount() {
    this.adv.onPlayerUnmount();

    this.unbindEvents();
    window.removeEventListener('resize', this.handleSize);
    window.removeEventListener('orientationchange', this.handleSize);
    if (this.leaveTimer)
      clearInterval(this.leaveTimer);
    if (this.errorDisableTimer)
      clearTimeout(this.errorDisableTimer);
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (nextProps.videoUrl !== this.props.videoUrl) {
      this.adv.adsManagerRefresh();

      this.setState({
        ErrorDisableTimeout: true,
        videoLoaded: false,
        videoUrl: nextProps.videoUrl,
        widthVideoUrl: nextProps.widthVideoUrl,
        title: nextProps.title,
        secDuration: nextProps.secDuration,
      }, () => {
        if (this.props.autoPlay) {
          this.adv.startAd()
        } else {
          this.api.load();
          this.setState({ firstPlay: true });
        }
      });

      return true;
    } else
      return nextState !== this.state;
  }

  mergeProps = (
    stateProps = {},
    videoElProps = {},
    ownProps = {}
  ) => Object.assign({}, stateProps, videoElProps, ownProps);

  updateState = () => {
    const { networkState, readyState, buffered, currentTime, duration, ErrorDisableTimeout, adLoading } = this.state;

    this.setState(this.mergeProps(
      PROPERTIES.reduce((p, c) => {
        p[c] = this.videoEl && this.videoEl[c];
        return p;
      }, {}), {
        error: networkState === 3 && !ErrorDisableTimeout && !adLoading,
        loading: readyState <= 1,
        percentagePlayed: Api.getPercentagePlayed(currentTime, duration),
        percentageBuffered: Api.getPercentageBuffered(buffered, duration),
      })
    );

    // console.log(this.state);
  };

  bindEventsToUpdateState() {
    for (let eventsKey in EVENTS) {
      this.videoEl.addEventListener(EVENTS[eventsKey], this.updateState);
      // this.videoEl.addEventListener(EVENTS[eventsKey], () => {console.log('%c%s', 'color: purple;', eventsKey)});
      if (this[eventsKey])
        this.videoEl.addEventListener(EVENTS[eventsKey], this[eventsKey]);
    }

    this.sourceEl.addEventListener('error', this.updateState);
    this.containerEl.addEventListener(fsApi.fullscreenchange, this.onFullscreenChange)
  }

  unbindEvents() {
    for (let eventsKey in EVENTS) {
      this.videoEl.removeEventListener(EVENTS[eventsKey], this.updateState);
      if (this[eventsKey])
        this.videoEl.removeEventListener(EVENTS[eventsKey], this[eventsKey]);
    }

    this.sourceEl.removeEventListener('error', this.updateState);
    this.containerEl.removeEventListener(fsApi.fullscreenchange, this.onFullscreenChange)
  }

  hover = () => {
    this.setState({
      hoverClassName: options.controlsShowClass,
      lastOver: Date.now(),
    });

    if (!this.leaveTimer) {
      this.leaveTimer = setInterval(() => {
        if ((Date.now() - this.state.lastOver) > options.controlsTimeout) {
          if (!this.state.controlsHover) this.leave();
          clearInterval(this.leaveTimer);
          this.leaveTimer = null;
        }
      }, 300);
    }
  };

  leave = () => {
    this.setState({
      hoverClassName: ''
    });
  };

  mouseOver = () => {
    this.hover();
  };

  mouseMove = throttle(200, this.mouseOver);

  controlsOverHandler = e => {
    if (Date.now() - this.state.lastTouchEnd > 800) {
      this.setState({
        controlsHover: true,
      });
    }
  };

  controlsOutHandler = () => {
    this.setState({
      controlsHover: false,
    });
  };

  touchEndHandler = () => {
    this.setState({ lastTouchEnd: Date.now() });
  };

  firstPlayHandler = () => {
    if (this.state.firstPlay) {
      this.setState({
        firstPlay: false,
        ended: false,
      });
      this.props.firstPlayHandler();
    }
  };

  heightSwitcher = value => {
    this.setState({
      greatHeight: value,
    })
  };

  handleSize = () => {
    this.setState({
      dimensions: Api.getDimensions(this.containerEl, options.minHeight),
    });
  };

  onLoadedMetadata = () => {
    this.analytics.onLoadedMetadata();
  };

  onPlaying = () => {
    this.props.onPlay();
    this.analytics.onPlay();
  };

  onPause = () => {
    this.props.onPause();
  };

  onEnd = () => {
    if (!this.state.fsPlayerSupport) this.api.videoEl[fsApi.exitFullscreen]();
    this.setState({ ended: true });
    this.adv.adsManagerRefresh();
    this.adv.handleEnd();
    this.props.onEnd();
    this.analytics.onEnd();
  };

  onTimeUpdate = () => {
    const { currentTime, duration } = this.state;
    this.analytics.onTimeUpdate(Math.round(duration), Math.round(currentTime));
  };

  onSeekChange = e => {
    Api.setCurrentTime(this.videoEl, e.target.value * this.state.duration / 100)
  }

  setCurrentTime = (offsetX, width) => {
    Api.setCurrentTime(this.videoEl, (offsetX / width) * this.state.duration);
  };

  onSeekTouchMove = e => {
    if (!MHelper.hasTouchScreen) return false;
    const value = ((e.changedTouches[0].pageX - e.target.getBoundingClientRect().left) / e.target.offsetWidth) * 100;

    this.setState({
      seekTouched: value < 100 ? value : 100,
      lastOver: Date.now(),
    });
  };

  onSeekTouchStop = e => {
    if (!MHelper.hasTouchScreen) return false;
    this.setState({ seekTouched: false });
    const offsetX = e.changedTouches[0].pageX - e.target.getBoundingClientRect().left;
    this.setCurrentTime(offsetX, e.target.offsetWidth);
  };

  onVolumeClickHandler = () => {
    Api.toggleMute(this.videoEl, this.state)
  };

  onVolumeChangeHandler = e => {
    Api.setVolume(this.videoEl, e.target.value)
  };


  onFullscreenClick = () => {
    if (!document[fsApi.fullscreenElement]) {
      if (this.state.fsPlayerSupport) {
        this.containerEl[fsApi.requestFullscreen]();
      } else {
        this.videoEl[fsApi.requestFullscreen]();
      }
    } else {
      document[fsApi.exitFullscreen]();
    }
  };

  onFullscreenChange = () => {
    if (document[fsApi.fullscreenElement]) {
      this.setState({ isFullscreen: true });
      this.analytics.onFullscreen();
    } else {
      this.setState({ isFullscreen: false });
    }

    this.adv.resizeAdsManager();
  };


  // adEvents

  onAdRequest = () => {
    this.props.onAdRequest();
  };

  onAdImpression = creativetype => {
    this.props.onAdImpression(creativetype);
  };

  onAdClick = creativetype => {
    this.props.onAdClick(creativetype);
  };

  onAdComplete = creativetype => {
    this.props.onAdComplete(creativetype);
  };

  onAdError = message => {
    this.props.onAdError(message);
  };

  onAutoPlayStart = () => {
    this.props.onAutoPlayStart();
  }

  onAutoPlayError = message => {
    this.props.onAutoPlayError(message);
  }

  // end adEvents

  render() {
    const {
      hoverClassName,
      firstPlay,
      greatHeight,
      isFullscreen,
      muted,
      error,
      loading,
      adLoading,
      percentagePlayed,
      percentageBuffered,
      paused,
      volume,
      currentTime,
      duration,
      adLinear,
      ended,
      seekTouched,
    } = this.state;

    const { children: poster } = this.props;

    return (
      <ReactPlayerStyled
        className={hoverClassName}
        ref={this.setPlayerRef}
        onMouseOver={this.mouseOver}
        onMouseMove={this.mouseMove}
        onTouchEnd={this.touchEndHandler}
        greatHeight={greatHeight}
        isFullscreen={isFullscreen}>

        {!isFullscreen && poster}

        <Video
          setVideoRef={this.setVideoRef}
          setSourceRef={this.setSourceRef}
          muted={muted} />

        {firstPlay && <PlayButton />}

        <Overlay
          firstPlay={firstPlay}
          onClick={this.onOverlayClick}
          error={error}
          loading={loading}
          adLoading={adLoading}
          ended={ended} />

        {!this.state.error && !this.state.firstPlay && <Controls
          onMouseOver={this.controlsOverHandler}
          onMouseOut={this.controlsOutHandler}>

          <Seek
            ariaLabel={labels.seek}
            onChange={this.onSeekChange}
            onSeekTouchMove={this.onSeekTouchMove}
            onSeekTouchStop={this.onSeekTouchStop}
            seekTouched={seekTouched}
            percentagePlayed={percentagePlayed}
            percentageBuffered={percentageBuffered} />

          <ControlRow>
            <PlayPause
              ariaLabelPlay={labels.play}
              ariaLabelPause={labels.pause}
              onClick={this.onPlayPauseClick}
              paused={paused} />
            <Volume
              onClick={this.onVolumeClickHandler}
              onChange={this.onVolumeChangeHandler}
              ariaLabelMute={labels.mute}
              ariaLabelUnmute={labels.unmute}
              ariaLabelVolume={labels.volume}
              volume={volume}
              muted={muted} />
            <Time
              currentTime={currentTime}
              duration={duration} />
            <ControlSpacer />
            <Fullscreen
              ariaLabel={labels.fullscreen}
              onClick={this.onFullscreenClick} />
          </ControlRow>
        </Controls>}

        <Ad
          setRef={this.setAdRef}
          adLinear={adLinear}
          isFullscreen={isFullscreen} />
      </ReactPlayerStyled>
    )
  }
}

export default Player
