import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import theme, { breakpoint } from "../theme";
import ImageSource from './Image.svg';

const In = styled.div`
  text-align: center;
  padding: 32px;
  
  ${breakpoint.tabletUp} {
    padding: 48px 32px;
  }
`;

const ImageWrapper = styled.div`
  margin: 0 auto;
  width: 334px;
  
  ${breakpoint.tabletOnly} {
    width: 510px;
  }
  
  ${breakpoint.desktopUp} {
    width: 480px;
  }
`;

const ImageIn = styled.div`
  padding-top: 81.40625%;
  position: relative;
`;

const Image = styled.img`
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: 700;
  margin: 46px 0 15px;
  
  ${breakpoint.tabletUp} {
    font-size: 42px;
    margin: 65px 0 15px;
  }
`;

const Text = styled.div`
  color: ${theme.color.textGray};
  font-size: 18px;
  line-height: 1.34em;
  margin: 15px auto 34px;
  max-width: 450px;
  text-align: center;
  
  ${breakpoint.tabletUp} {
    max-width: 542px;
    font-size: 20px;
    margin: 15px auto 50px;
  }
`;

const Button = styled(Link)`
  background: ${theme.color.bright};
  color: white;
  text-decoration: none;
  font-size: 22px; 
  height: 2.25em;
  font-weight: 700;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 1.5em 0.1em;
  opacity: 0.9;
  transition: opacity 0.1s linear;
  border-radius: 4px;

  &:hover {
    opacity: 1;
  }

  ${breakpoint.tabletOnly} {
    font-size: 26px;
  }

  ${breakpoint.desktopUp} {
    font-size: 24px;
  }
`;

const Page404 = () => {
  return (
    <In>
      <ImageWrapper>
        <ImageIn><Image src={ImageSource} alt=""/></ImageIn>
      </ImageWrapper>
      <Title>Sorry! Page not found.</Title>
      <Text>The page you're looking for has either been moved or removed from the site.</Text>
      <Button to="/">Go home</Button>
    </In>
  )
};

export default Page404;
