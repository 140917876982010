import React, { Component } from 'react';
import * as Sentry from '@sentry/react';
import renderMessage from '../utils/renderMessage';
import HeaderContainer from '../containers/HeaderContainer';
import GlobalStyle from './GlobalStyle';
import MenuContainer from '../containers/MenuContainer';
import styled from 'styled-components';
import theme from './theme';

import { renderRoutes } from "react-router-config";
import routes from '../routes';
import {connect} from "react-redux";

/** fix ssr */
require('../performance');

const Main = styled.main`
  max-width: ${theme.size.pageMaxWidth};
  margin: 0 auto;
  width: 100%;
  position: relative;
`;

class App extends Component {
  constructor(props) {
    super(props);

    if (typeof window !== 'undefined') {
      const observer = new MutationObserver(function(mutations) {
        mutations[0].target.removeAttribute('style');
      });

      const target = document.querySelector('.root');
      observer.observe(target, { attributes : true, attributeFilter : ['style'] });
    }
  }

  render() {
    renderMessage('App');

    return (
      <React.Fragment>
        <GlobalStyle/>
        <HeaderContainer/>
        <Main>
          {renderRoutes(routes)}
        </Main>
        <MenuContainer/>
      </React.Fragment>
    );
  }
}

const mapStateToProps = store => {
  return {
    pathName: store.router.location.pathname,
    siteFeatures: store.sessionParams.siteFeatures,
  };
};

export default connect(
  mapStateToProps,
)(Sentry.withProfiler(App));
