export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const SLIDES_REQUEST = 'SLIDES_REQUEST';
export const SLIDES_SUCCESS = 'SLIDES_SUCCESS';
export const SLIDES_FAIL = 'SLIDES_FAIL';

export const MENU_SHOW = 'MENU_SHOW';
export const MENU_HIDE = 'MENU_HIDE';

export const VIDEO_PAGE_REQUEST = 'VIDEO_PAGE_REQUEST';
export const VIDEO_PAGE_UPDATE = 'VIDEO_PAGE_UPDATE';
export const VIDEO_PAGE_SUCCESS = 'VIDEO_PAGE_SUCCESS';
export const VIDEO_PAGE_FAIL = 'VIDEO_PAGE_FAIL';

export const PLAYLIST_UPDATE = 'PLAYLIST_UPDATE';
export const PLAYLIST_SUCCESS = 'PLAYLIST_SUCCESS';
export const PLAYLIST_FAIL = 'PLAYLIST_FAIL';

export const ADS_REQUEST = 'LOGIN_REQUEST';
export const ADS_SUCCESS = 'LOGIN_SUCCESS';
export const ADS_FAIL = 'LOGIN_FAIL';

export const ARTICLE_REQUEST = 'ARTICLE_REQUEST';
export const ARTICLE_SUCCESS = 'ARTICLE_SUCCESS';
export const ARTICLE_FAIL = 'ARTICLE_FAIL';

export const PRELAND_SHOW = 'PRELAND_SHOW';
export const PRELAND_END = 'PRELAND_END';
