import React  from 'react';
import styled from "styled-components";
import IconCheckbox from "../Icons/IconCheckbox";
import IconCheckboxActive from "../Icons/IconCheckboxActive";
import theme from '../theme';

const Label = styled.label`
  position: relative;
  display: inline-flex;
  align-content: center;
  user-select: none;
`;

const Input = styled.input`
  position: absolute;
  left: 14px;
  top: 14px;
  opacity: 0;
  width: 1px;
  height: 1px;
`;

const IconContainer = styled.span`
  padding-right: 0.5em;
  fill: ${theme.color.common};
`;

const IconStyled = styled(IconCheckbox)`
  display: inline-block;
  ${Input}:checked + ${IconContainer} & {
    display: none;
  }
`;

const IconActiveStyled = styled(IconCheckboxActive)`
  display: none;
  ${Input}:checked + ${IconContainer} & {
    display: inline-block;
  }
`;

const Checkbox = ({className, children, name, isRequired, onChange, checked}) => {
  return (
    <Label className={className}>
      <Input onChange={onChange} checked={checked} type="checkbox" name={name} required={isRequired}/>
      <IconContainer>
        <IconStyled/>
        <IconActiveStyled/>
      </IconContainer>
      <span>{children}</span>
    </Label>
  )
}

export default Checkbox;
