export default class Scroller {
  /**
   * Initialize scroller;
   * @param el - Slider wrapper element
   * @param selectors
   */
  constructor(el, selectors) {
    this.selectors = selectors || {
      container: '.m-slider__cnt',
      scrolled: '.m-slider__container',
      item: '.m-slider__item',
      prevButton: '.m-slider__prev-btn',
      nextButton: '.m-slider__next-btn',
    };

    this.el = el;
    this.itemSelector = this.selectors.item;
    this.container = el.querySelector(this.selectors.container); // Контейнер
    this.scrolled = el.querySelector(this.selectors.scrolled); // Двигаемый контейнер
    this.sliderItem = el.querySelector(this.selectors.item); // Ячейка (для определения размера)
    this.prevButton = el.querySelector(this.selectors.prevButton);
    this.nextButton  = el.querySelector(this.selectors.nextButton);
    this.shiftValue = 0.25;

    this.sliderPosition = "0";  // Начальная позиция
    this.isSlide = false;
    this.touchX = 0;
    this.countLoLoad = 0;
    this.isAddition = false;

    this.resizeCarousel();

    /** Responsive */
    window.addEventListener("resize", this.resizeCarousel.bind(this)); // todo: debounce this

    /** Button Events */
    this.prevButton.addEventListener('click', () => this.slide('prev'));
    this.nextButton.addEventListener('click', () => this.slide('next'));

    /** Touch Control */
    this.scrolled.addEventListener("touchstart", this.slideStart.bind(this));
    document.addEventListener("touchmove", this.slideMove.bind(this));
    document.addEventListener("touchend", this.slideEnd.bind(this));

    /**  Mouse Control */
    this.scrolled.addEventListener("mousedown", this.slideStart.bind(this));
    document.addEventListener("mousemove", this.slideMove.bind(this));
    document.addEventListener("mouseup", this.slideEnd.bind(this));
  }

  get containerWidth() {
    let { container } = this;
    let containerStyles = getComputedStyle(container);
    return container.clientWidth - parseInt(containerStyles.paddingLeft) - parseInt(containerStyles.paddingRight);
  }

  resizeCarousel() {
    // todo: сделать восстанавление текущей позиции
    let { el, containerWidth, scrolled } = this;
    scrolled.style.width = "";

    if (scrolled.clientWidth < containerWidth) {
      scrolled.style.width = "100%";
      scrolled.style.transform = `translate(0, 0)`;
      scrolled.dataset.pos = 0;
      el.classList.add('full');
    } else {
      el.classList.remove('full');
    }
  }

  get faultWidth() {
    let { sliderItem } = this;
    let width = 0;
    ['marginLeft', 'marginRight'].map((selfMargin) => {
      width += parseInt(window.getComputedStyle(sliderItem)[selfMargin]);
      return width;
    });
    return width;
  }

  get itemWidth() {
    let { sliderItem, faultWidth } = this;
    return sliderItem.clientWidth + faultWidth;
  }

  set sliderPosition(pos) {
    this.scrolled.style.transform = `translate(${pos}px, 0)`;
    this.scrolled.dataset.pos = pos;
  }

  get sliderPosition() {
    return parseInt(this.scrolled.dataset.pos);
  }

  get activeItem() {
    return Math.floor(-this.sliderPosition / this.itemWidth);
  }

  get count() {
    return this.el.querySelectorAll(this.itemSelector).length;
  }

  get visibleItems() {
    return Math.ceil(this.containerWidth/this.itemWidth);
  }

  slide(direction) {
    this.isSlide = true;
    let { itemWidth } = this;
    let x = this.sliderPosition;
    switch (direction) {
      case "prev":
        x += itemWidth;
        break;
      case "next":
        x -= itemWidth;
        break;
      default: return;
    }
    this.sliderPosition = x;
    this.slideEnd();
  }

  static eventPageX(e) {
    // Проверяем тач это или клик
    if(e.changedTouches) {
      return e.changedTouches[0].pageX
    } else {
      e.preventDefault();
      return e.pageX;
    }
  }

  slideStart(e) {
    let { scrolled } = this;
    scrolled.style.transition = "0s";
    this.prevSliderPosition = this.sliderPosition;

    this.touchX = Scroller.eventPageX(e) - this.sliderPosition;
    this.isSlide = true;
  }

  slideMove(e) {
    if (this.isSlide) {
      this.sliderPosition = Scroller.eventPageX(e) - this.touchX;
    }
  }

  onSlideEnd(offset, callback) {
    this.countLoLoad = offset;
    this.callbackOnSlideEnd = callback;
  }

  onLoad(callback) {
    callback(this.count, this.visibleItems);
  }

  slideEnd() {
    if (this.isSlide) {
      this.touchX = 0;
      this.isSlide = false;
      this.scrolled.style.transition = "";
      this.correction();

      this.isAddition = (this.count - this.countLoLoad) <= (this.activeItem + this.visibleItems);
      if(this.isAddition && typeof this.callbackOnSlideEnd === 'function') {
        this.callbackOnSlideEnd(this.count);
      }
    }
  }

  correction() {
    let { scrolled, containerWidth, itemWidth, prevSliderPosition, shiftValue } = this;

    /** Корректировка положения карусели */
    let x = this.sliderPosition;
    let shift = Math.abs((prevSliderPosition - x) / itemWidth);
    let position;

    if (shift > shiftValue && shift < 1) {
      let direction = x > prevSliderPosition ? 'ceil' : 'floor';
      position = Math[direction](x / itemWidth) * itemWidth;
    } else {
      position = Math.round(x / itemWidth) * itemWidth;
    }

    /** Процесс корректировки карусели, высчитываем три этапа прокрутки (начало-середина-конец) */
    if (x <= -scrolled.clientWidth + containerWidth) { // конец
      position = -scrolled.clientWidth + containerWidth;
    }

    if (x > 0) {
      position = 0; // начало
    }

    this.sliderPosition = position;
  }
}
