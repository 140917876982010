import MHelper from '../../../utils/MHelper';
export default class StorageApi{
  static prefix = `mamatvPlayer`;

  static get mute() {
    if (MHelper.storageNotSupport) return false;
    return localStorage.getItem(`${StorageApi.prefix}.muted`);
  }

  static set mute(value) {
    if (MHelper.storageNotSupport) return false;
    localStorage.setItem(`${StorageApi.prefix}.muted`, value ? 'muted' : '');
  }

  static get volume() {
    if (MHelper.storageNotSupport) return false;
    return localStorage.getItem(`${StorageApi.prefix}.volume`);
  }

  static set volume(value) {
    if (MHelper.storageNotSupport) return false;
    localStorage.setItem(`${StorageApi.prefix}.volume`, value);
  }
}
