import React from 'react';
import styled from 'styled-components';

const Component = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border: none;
  border-radius: 0;
  background-size: auto;
  box-shadow: none;
  max-height: 72px;
  z-index: 1;
  
  transition: 250ms cubic-bezier(0, .25, .25, 1);
  transition-property: opacity, visibility;
  
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  transition-delay: 0s, 250ms;
  
  .controls-show & {
    visibility: visible;
    pointer-events: auto;
    transition-delay: 0s;
    opacity: 1;
  }
`;

const Controls = props => {
  return (
    <Component
      onMouseOver={props.onMouseOver}
      onMouseOut={props.onMouseOut}
    >
      {props.children}
    </Component>
  )
};

export default Controls;
