import React from 'react';
import styled from 'styled-components';

const VideoStyled = styled.video`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  
  ::-webkit-media-controls {
    justify-content:flex-start
  }

  ::-webkit-media-controls,
  ::-webkit-media-controls-panel,
  ::-webkit-media-controls-panel-container,
  ::-webkit-media-controls-start-playback-button,
  ::-webkit-media-controls-play-button,
  ::-webkit-media-controls-overlay-play-button {
    display: none!important;
    -webkit-appearance: none;
  }
`;

const Video = props => {
  const { setVideoRef, setSourceRef, muted } = props;

  return (
    <VideoStyled
      poster="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
      ref={setVideoRef}
      playsInline
      muted={muted}>
      <source
        ref={setSourceRef}
        type="video/mp4"/>
    </VideoStyled>
  )
};

export default Video;
