import vastUrlMacrosReplace from "./vastUrlMacrosReplace";

export default function vastPreProcessing(vastUrls, options) {
  let request;

  vastUrls.forEach(vast => {
    request = vastUrlMacrosReplace(vast.vast_code, options);
    request = request.replace("__page-url__", encodeURIComponent(window.location.href));
  });

  return request;
}
