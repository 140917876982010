const labels = {
  play: 'Play video',
  pause: 'Pause video',
  mute: 'Mute video',
  unmute: 'Unmute video',
  volume: 'Change volume',
  fullscreen: 'View video fullscreen',
  seek: 'Seek video',
  captions: 'Toggle captions'
};

export default labels;
