import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { homePageReducer } from './homePage';
import { videoPageReducer } from './videoPage';
import { articlePageReducer } from './articlePage';
import { menuReducer } from './menu';
import { headerReducer } from './header';
import { adsReducer } from "./ads";

export default (history) => combineReducers({
  router: connectRouter(history),
  homePage: homePageReducer,
  videoPage: videoPageReducer,
  articlePage: articlePageReducer,
  menu: menuReducer,
  header: headerReducer,
  ads: adsReducer,
  sessionParams: (store = {}) => store,
  contactPage: (store = {}) => store,
});
