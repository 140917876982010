import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import VideoThumb from './VideoThumb';
import theme, { breakpoint, bp } from './theme';
import debounce from '../utils/debounce';
import Loader from './primitives/Loader';
import dataPathWithParams from '../utils/dataPathWithParams';
import Ad from './Ad';
import BannerObserver from '../utils/BannerObserver';

import ThumbsAnalytics from "../utils/ThumbsAnalytics";
export const thumbsAnalytics = new ThumbsAnalytics();

const PlaylistStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 7px 5px;
  min-width: 0;

  ${breakpoint.mobileLandscape} {
    padding-left: 5px;
    margin: 0;
  }

  ${breakpoint.desktopOnly} {
    padding-left: 22px;
    margin: 0;
  }

  ${breakpoint.desktopBigUp} {
    padding-left: 17px;
    margin: 0;
  }
`;

const PlaylistItem = styled.div`
  flex: 50% 0 0;
  padding: 5px;
  min-width: 0;
  display: ${props => props.pageLoaded ? 'block' : 'none'};

  ${breakpoint.tabletDown} {
    order: 3;

    &:nth-child(1), &:nth-child(3) {
      order: 1;
    }

    &:nth-child(2) {
      order: 3;
    }
  }

  ${breakpoint.mobileOnly} {
    &:nth-child(4), &:nth-child(5) {
      order: 3;
    }

    &:nth-child(1), &:nth-child(3) {
      display: block;
    }
  }

  ${breakpoint.tabletOnly} {
    flex-basis: 33.33%;
    padding: 5px;

    &:nth-child(4) {
      order: 1;
    }

    &:nth-child(1), &:nth-child(3), &:nth-child(4) {
      display: block;
    }
  }

  ${breakpoint.desktopUp}, ${breakpoint.mobileLandscape.slice(6)}{
    flex-basis: 100%;
    padding: 5px 0;

    &:first-child {
      padding-top: 0;
    }

    display: block;
  }

  ${breakpoint.desktopBigUp} {
    flex-basis: 50%;
    padding: 5px;
    order: 3;

    &:nth-child(1), &:nth-child(3) {
      padding-top: 0;
      order: 1;
    }
  }
`;

const PlaylistAd = styled(Ad)`
  flex: 100% 0 0;
  padding: 5px;
  text-align: center;
  min-width: 0;
  min-height: 120px;

  overflow: hidden;
  overflow-x: auto;

  .adsbygoogle {
    height: 120px;
  }

  ${breakpoint.tabletDown} {
    order: 3;

    &:nth-child(2) {
      order: 2;
    }

    &:nth-child(3) {
      order: 3;
    }
  }

  ${breakpoint.tabletOnly} {
    padding: 5px;
  }

  ${breakpoint.desktopUp}, ${breakpoint.mobileLandscape.slice(6)} {
    padding: 5px 0;
    min-height: 250px;

    .adsbygoogle {
      height: 250px;
    }
  }

  ${breakpoint.desktopBigUp} {
    order: 3;

    &:nth-child(2) {
      order: 2;
    }
  }
`;

const WrapLoader = styled.div`
  flex-basis: 100%;
  order: 3;
  opacity: ${props => props.visible ? '1' : '0' };
  transition: opacity 0.05s ease-out;
`;

const StyledLoader = styled(Loader)`
  position: relative;
  margin-top: 10px;
  margin-bottom: 7px;
  font-size: 7px;

  ${breakpoint.tabletUp} {
    font-size: 10px;
  }

  ${breakpoint.desktopUp} {
    margin-bottom: 0;
  }
`;

class PlaylistMScroll extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageLoaded: false,
      quantity: 3,
      fakeFetching: false,
    };
  }

  componentDidMount() {
    this.setParams();
    window.addEventListener('orientationchange', this.setParams);
    const { handlePlaylist, dataPath } = this.props;
    const { items, isEnd } = this.props.playlist;

    const length = items.length;
    const limit = length < this.limit ? this.limit - length : 0;
    const ids = items.map(obj => obj.id);

    if(!isEnd && limit) {
      handlePlaylist(dataPathWithParams(dataPath, length, limit, this.props), ids);
    }

    this.setQuantity();
    window.dBanInitTime = Date.now();

    window.addEventListener('scroll', debounce(this.handleScroll, 50).bind(this));
    this.bannerObserver = new BannerObserver();
    this.observer = this.bannerObserver.init();
    this.desktopBannerEl = document.querySelector('.desktop-banner');
  }

  componentWillUnmount() {
    window.removeEventListener('orientationchange', this.setParams);
    window.removeEventListener('scroll', debounce(this.handleScroll, 50).bind(this));
    if (this.adRef) this.observer.unobserve(this.adRef.container);
  }

  get checkTablet() {
    return this.matchMedia(bp.tabletPortrait);
  }

  get checkDesktop() {
    return this.matchMedia(bp.desktop) || this.matchMedia(bp.mobileLandscape);
  }

  get checkDesktopBig() {
    return this.matchMedia(bp.desktopBig) || this.matchMedia(bp.mobileLandscape);
  }

  get checkMobile() {
    return (!this.checkTablet && !this.checkDesktop);
  }

  setParams = () => {
    // this.cols = 2;
    this.limit = 8;

    if(this.checkDesktop) {
      // this.cols = 1;
      this.limit = 6;
    }

    if(this.checkTablet) {
      // this.cols = 3;
      this.limit = 9;
    }
  };

  get getNumInRow() {
    let res = 2;
    if(this.checkDesktop)
      res = 1;
    else if(this.checkTablet)
      res = 3;
    return res;
  }

  componentDidUpdate(prevProps) {
    if(prevProps.dataPath !== this.props.dataPath) {
      this.bannerObserver.reset();
      this.observer.disconnect();
      this.handleScroll();

      if (this.adRef) {
        this.adRef.container.dataset.sendedEvent = 0;
        this.observer.unobserve(this.adRef.container);
        this.observer.observe(this.adRef.container);
      }

      this.setQuantity();
    }
  }

  setQuantity = () => {
    if (this.checkMobile) this.setState({pageLoaded: true, quantity: 4});
    if (this.checkTablet) this.setState({pageLoaded: true, quantity: 6});
    if (this.checkDesktop) this.setState({pageLoaded: true, quantity: 4});
    if (this.checkDesktopBig) this.setState({pageLoaded: true, quantity: 8});
  }

  adAfterInit = () => {
    if (this.adRef) this.observer.observe(this.adRef.container);
  }

  matchMedia(x) {
    if(typeof window !== 'undefined') {
      return window.matchMedia(x).matches;
    }
  }

  getCamel(str) {
    return str.replace(
      /([-_][a-z])/g, (group) => group.toUpperCase().replace('_', '')
    );
  }

  getObj(arr, val) {
    let result = {};
    arr.map(item => {
      result[this.getCamel(item[val])] = item;
    });
    return result;
  }

  handleScroll = () => {
    if (this.scroller) {
      const { handlePlaylist, dataPath, playlist } = this.props;
      const { isFetching, isEnd, items} = playlist;
      const { quantity } = this.state;

      const ids = items.map(obj => obj.id);
      let position = window.innerHeight + window.scrollY;
      let playlistHeight =
        this.scroller.clientHeight +
        this.scroller.offsetTop +
        this.scroller.offsetParent.offsetTop;

      if (playlistHeight < position && !isFetching && !isEnd) {
        
        if (items.length <= quantity) {
          handlePlaylist(dataPathWithParams(dataPath, items.length, this.limit, this.props), ids);
        } else {
          const passed = Date.now() - window.dBanInitTime;
          const timeout = 5000;
          const delay = passed < timeout ? timeout - passed : 0;
          const nextQuantity = quantity + this.getNumInRow;

          if (this.desktopBannerEl.offsetTop < (window.scrollY + this.itemRef.clientHeight - 100)) {
            this.setState({fakeFetching: true});

            setTimeout(() => {
              this.setState({fakeFetching: false});

              if (items.length === quantity) {
                handlePlaylist(dataPathWithParams(dataPath, items.length, this.limit, this.props), ids);
              }
              this.setState({pageLoaded: true, quantity: 99999});
            }, delay);
            
          } else {
            this.setState({pageLoaded: true, quantity: nextQuantity});
          }
        }
      }
    }
  };

  get plItems() {
    const { items } = this.props.playlist;
    const { quantity } = this.state;

    const visibleItems = items.slice(0, quantity)
    return visibleItems;
  }

  renderSlides() {
    const { ads, playlist } = this.props;
    const { items, isEnd, isFetching } = playlist;
    const { pageLoaded } = this.state;

    const adsObj = this.getObj(ads, 'place_id');

    return this.plItems.map((item, key) => {
      let k = `video-${item.id}`;

      if ((key + 1) % this.limit === 0 || key === 1)
        k += `-with-ad`;

      const relatedBannerShow = key === 1;
      const scrollCondition = key === (this.limit - 1) || ( items.length < this.limit && key === items.length - 1 && isEnd );
      const lastCondition = (key + 1) % this.limit === 0 && key !== (this.limit - 1);
      const scrollBannerShow = (scrollCondition || lastCondition);
      const position = key;

      return (
        <React.Fragment key={k}>
          {relatedBannerShow && adsObj.related && <PlaylistAd
            name={adsObj.related.place_id}
            className="related-banner"
            analyticsAction="RELATED_LOADED"
            ref={el => (this.adRef = el)}
            code={adsObj.related.slot_code}
            type="related"
            replaceable={bp.tabletPortrait}
            isFetching={isFetching}
            afterInit={this.adAfterInit}
          />}

          <PlaylistItem pageLoaded={pageLoaded} ref={el => (this.itemRef = el)} >
            <VideoThumb {...item} position={position} />
          </PlaylistItem>

          {scrollBannerShow && adsObj.scroll && <PlaylistAd lazy={true} name={adsObj.scroll.place_id} code={adsObj.scroll.slot_code} />}
        </React.Fragment>
      );
    });
  }

  render() {
    const { className } = this.props;
    const { isFetching } = this.props.playlist;
    const { fakeFetching } = this.state;
    return (
      <>
        <PlaylistStyled className={className} ref={scroller => this.scroller = scroller} >
          {this.renderSlides()}
        </PlaylistStyled>
        <WrapLoader visible={isFetching || fakeFetching}>
          <StyledLoader bg={theme.color.bg} color={theme.color.bright} speed={0.5} />
        </WrapLoader>
      </>
    );
  }
}

export default PlaylistMScroll;

PlaylistMScroll.propTypes = {
  handlePlaylist: PropTypes.func.isRequired,
  dataPath: PropTypes.string.isRequired,
  playlist: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired
  }).isRequired,
};
