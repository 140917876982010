import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAIL } from '../constants';

const initialState = {};//(typeof window !== 'undefined') ? window.initialStates.header : {};//global.initialStates.header;
export function headerReducer(state = initialState, action) {
  switch (action.type) {

    case LOGIN_REQUEST:
      return { ...state, isFetching: true, error: '' };
    case LOGIN_SUCCESS:
      return { ...state, isFetching: false, username: action.payload };
    case LOGIN_FAIL:
      return { ...state, isFetching: false, error: action.payload.message };

    default:
      return state;
  }
}
