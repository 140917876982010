import React from 'react';
import styled, { keyframes } from 'styled-components';
import theme from '../../theme';
import IconPlay from '../../Icons/IconPlay';

const buttonBg = keyframes`
  from {
    transform: translate(-50%, -50%) scale(1) rotate(0deg);
    background-color: ${theme.color.bright};
    fill: white;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  }

  70% {
    transform: translate(-50%, -50%) scale(1) rotate(0deg);
    background-color: ${theme.color.bright};
    fill: white;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  }

  75% {
    transform: translate(-50%, -50%) scale(1.3) rotate(360deg);
    background-color: white;
    fill: ${theme.color.bright};
    box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.4);
  }

  95% {
    transform: translate(-50%, -50%) scale(1.3) rotate(360deg);
    background-color: white;
    fill: ${theme.color.bright};
    box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.4);
  }

  to {
    transform: translate(-50%, -50%) scale(1) rotate(0deg);
    background-color: ${theme.color.bright};
    fill: white;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  }
`;

const Component = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

const PlayButtonStyled = styled.div`
  width: 25%;
  padding-top: 25%;
  position: absolute;
  border-radius: 50%;
  background: ${theme.color.bright};
  left: 50%;
  top: 50%;
  height: 0;
  fill: white;
  transform: translate(-50%, -50%);
  animation: ${props => props.disable ? false : '5s linear 1s infinite normal' };
  animation-name: ${props => props.disable ? false : buttonBg };
  animation-play-state: ${props => props.paused ? 'paused' : 'running'};
  
  body.player-preloader-active & {
    animation: none;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 59%;
  height: 59%;
  margin: auto;
`;

const IconPlayStyled = styled(IconPlay)`
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  transform: translate(12.5%, 0);
`;

export default class PlayButton extends React.Component {
  state = {
    paused: false,
  };

  componentDidMount() {
    this.observer = new IntersectionObserver(entries => {
      const {isIntersecting} = entries[0];

      if (isIntersecting === undefined) return;

      if (isIntersecting) {
        this.setState({paused: false});
      } else {
        this.setState({paused: true});
      }

    }, {
      threshold: [0.5],
      delay: 100,
    });
    this.observer.observe(this.element);
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  render() {
    const { paused } = this.state;
    const { disable } = this.props;
    return (
      <Component>
        <PlayButtonStyled
          paused={paused}
          disable={disable}
          ref={el => this.element = el}
        >
          <IconWrapper paused={paused}>
            <IconPlayStyled size={200}/>
          </IconWrapper>
        </PlayButtonStyled>
      </Component>
    )
  }
}
