import axios from "axios";
import { SLIDES_REQUEST, SLIDES_SUCCESS, SLIDES_FAIL } from '../constants';

export default function addSlides(id, dataPath, isBig) {
  return function(dispatch) {
    dispatch({
      type: SLIDES_REQUEST,
      payload: {
        id: id,
        isBig: isBig,
      }
    });

    axios.get(dataPath)
      .then(res => {
        console.log('SLIDES_SUCCESS', res.data);
        dispatch({
          type: SLIDES_SUCCESS,
          payload: {
            id: id,
            items: res.data.items,
            eol: res.data.eol,
            isBig: isBig,
          },
        });

      }, error => {
        dispatch({
          type: SLIDES_FAIL,
          payload: error,
        });
      });
  };
}
