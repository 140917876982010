import {ADS_FAIL, ADS_REQUEST, ADS_SUCCESS} from "../constants";

export function adsReducer(state = {}, action) {
  switch (action.type) {
    case ADS_REQUEST:
      return { ...state, isFetching: true, error: '' };
    case ADS_SUCCESS:
      return { ...state, isFetching: false, username: action.payload };
    case ADS_FAIL:
      return { ...state, isFetching: false, error: action.payload.message };

    default:
      return state;
  }
}
