import React from 'react';
import PlayArrow from '../icons/PlayArrow';
import Pause from '../icons/Pause';
import styled, { css } from 'styled-components';

const Button = styled.button`
  width: 40px;
  height: 40px;
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  cursor: pointer;
  outline: 0;
  opacity: 0.8;
  
  :hover {
    opacity: 1;
  }
`;

const IconStyles = css`
  padding: 7px;
  width: 100%;
  height: 100%;
  fill: #ffffff;
`;

const PlayArrowStyled = styled(PlayArrow)`
  ${IconStyles};
`;

const PauseStyled = styled(Pause)`
  ${IconStyles};
`;

export default ({className, onClick, paused, ariaLabelPlay, ariaLabelPause}) => {
  return (
    <Button
      className={className}
      onClick={onClick}
      aria-label={paused
        ? ariaLabelPlay
        : ariaLabelPause}
      type="button">
      {paused
        ? <PlayArrowStyled />
        : <PauseStyled />}
    </Button>
  );
};
