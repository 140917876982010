import React from 'react';
import { renderRoutes } from 'react-router-config';
import PageHomeContainer from './containers/PageHomeContainer';
import PageLandingContainer from './containers/PageLandingContainer';
import PageVideoContainer from './containers/PageVideoContainer';
import PageChannelContainer from "./containers/PageChannelContainer";
import PageArticleContainer from './containers/PageArticleContainer';
import PageContact from "./components/PageContact";
import Page404 from './components/Page404';

// import loadable from "@loadable/component";
// const PageVideoContainer = loadable(() => import('./containers/PageVideoContainer'));
// const PageHomeContainer = loadable(() => import('./containers/PageHomeContainer'));

const Root = ({ route }) => {
  return renderRoutes(route.routes);
};

const routes = [
  {
    component: Root,
    routes: [
      {
        path: '/',
        exact: true,
        component: PageHomeContainer,
      },
      {
        path: '/404/',
        exact: true,
        component: Page404,
      },
      {
        path: [
          '/url/:lander_id/:utm_source/',
        ], component: PageLandingContainer,
      },
      {
        path: [
          '/utm-:utm_source/',
          '/:lang/utm-:utm_source/',
        ],
        component: PageHomeContainer,
      },
      {
        path: [
          '/contact/',
        ],
        component: PageContact,
      },
      {
        path: [
          '/:lang/about/:page_path/',
        ],
        component: PageArticleContainer,
      },
      {
        path: [
          '/:lang/:channel_slug/:videoId/utm-:utm_source',
        ], component: PageLandingContainer,
      },
      {
        path: [
          '/:lang/:channel_slug/utm-:utm_source/',
        ], component: PageLandingContainer,
      },
      {
        path: [
          '/:lang/:channel_slug/:videoId/'
        ], component: PageVideoContainer,
      },
      {
        path: [
          '/:lang/:channel_slug/',
        ], component: PageChannelContainer,
      },
      {
        path: '/:lang/',
        component: PageHomeContainer,
      },
    ],
  },
];

export default routes;
