import React from 'react';
import Svg from "./Svg";

const IconCheckboxActive = props => {
  return (
    <Svg
      title="Checkbox Active Icon"
      size={props.size || 16}
      className={props.className}
      width={512}
      height={512}>
      <path d="M150.6,247.7 L235.9,333 C241.2,338.3 248.5,341.3 256,341.3 C263.5,341.3 270.8,338.3 276.1,333 L503.7,105.4 C514.8,94.3 514.8,76.3 503.7,65.2 C492.6,54.1 474.6,54.1 463.5,65.2 L256,272.7 L190.8,207.5 C179.7,196.4 161.7,196.4 150.6,207.5 C139.4,218.6 139.4,236.6 150.6,247.7 Z" id="Shape"/>
      <path d="M455.1,256 L455.1,426.7 C455.1,442.4 442.3,455.1 426.7,455.1 L85.3,455.1 C69.6,455.1 56.9,442.3 56.9,426.7 L56.9,85.3 C56.9,69.6 69.7,56.9 85.3,56.9 L341.3,56.9 C357,56.9 369.7,44.2 369.7,28.5 C369.8,12.7 357,0 341.3,0 L85.3,0 C38.2,0 0,38.2 0,85.3 L0,426.6 C0,473.8 38.2,512 85.3,512 L426.6,512 C473.7,512 511.9,473.8 511.9,426.7 L511.9,256 C511.9,240.3 499.2,227.6 483.5,227.6 C467.8,227.6 455.1,240.3 455.1,256 Z" id="Shape"/>
    </Svg>
  )
};

export default IconCheckboxActive;
