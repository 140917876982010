import React from 'react';
import Svg from "./Svg";

const IconCheckbox = props => {
  return (
    <Svg
      title="Checkbox Icon"
      size={props.size || 16}
      className={props.className}
      width={512}
      height={512}>
      <path d="M511.9,256.000015 L511.9,426.7 C511.9,473.8 473.7,512 426.6,512 L85.3,512 C38.2,512 0,473.8 0,426.6 L0,85.3 C0,38.2 38.2,0 85.3,0 L341.3,0 L426.6,0 C473.7,0 511.9,38.2 511.9,85.3 L511.9,256 Z M341.3,56.9 L85.3,56.9 C77.4905203,56.9 70.3827432,60.0827221 65.2312316,65.2312401 C60.0827203,70.3827473 56.9,77.4905226 56.9,85.3 L56.9,426.7 C56.9,434.540462 60.0922284,441.632745 65.2312361,446.768764 C70.3672586,451.907773 77.4595402,455.1 85.3,455.1 L426.7,455.1 C442.3,455.1 455.1,442.4 455.1,426.7 L455.1,256.00001 L455.1,85.3 C455.1,69.6 442.3,56.9 426.7,56.9 L341.3,56.9 Z"/>
    </Svg>
  )
};

export default IconCheckbox;
