import ajaxPost from "./ajaxPost";
import { clickHouse } from '../params';
import MHelper from '../utils/MHelper';

export default class ThumbsAnalytics {
  constructor(interval = 8000) {
    if (MHelper.ifSSR) return;
    this.touch();
    this.data = {};
    this.sendInterval = setInterval(() => {
      if (this.isTimeToSend()) {
        this.send();
      }
    }, interval);
  }

  start(id, action, slug) {
    let sl = slug || id;
    let item = this.data[sl];
    if (!item) {
      this.data[sl] = {};
    } else if (item.isTracked) return false;

    this.data[sl].startTime = Date.now();
    this.data[sl].action = action;
    this.data[sl].id = id;
    this.data[sl].isTracked = true;

    this.touch();
  }

  stop(id, slug) {
    let sl = slug || id;
    let item = this.data[sl];
    if (!item) return false;

    let time = Date.now();
    item.isTracked = false;
    if (!item.resultTime) {
      item.resultTime = time - item.startTime;
    } else {
      item.resultTime = item.resultTime + time - item.startTime;
    }
    this.touch();
  }

  isTimeToSend() {
    return (this.haveFreshData || (Date.now() - this.lastSendTime) > 30000);
  }

  touch() {
    this.haveFreshData = true;
    this.lastSendTime = Date.now();
  }

  untouch() {
    this.haveFreshData = false;
    this.lastSendTime = Date.now();
  }

  send(clickId, clickAction) {
    if (!Object.keys(this.data).length) return false;

    let data = [];
    for (let slug in this.data) {
      let item = this.data[slug];
      let time = item.resultTime ? item.resultTime : Date.now() - item.startTime;

      data.push({
        id: item.id,
        time: time,
        action: item.action,
      });

      if(item.isTracked) {
        item.startTime = Date.now();
        item.resultTime = undefined;
      } else {
        delete this.data[slug];
      }
    }

    if(clickId && clickAction) {
      data.push({
        id: clickId.toString(),
        action: clickAction,
      });

      this.data = {};
    }

    this.untouch();

    ajaxPost(clickHouse, JSON.stringify({locationPath: window.location.pathname, events: data}), "application/json; charset=utf-8").catch(error => {
      throw new Error(error);
    });
  }
}
