import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Thumb from './primitives/Thumb';
import theme, { breakpoint, bp } from './theme';
import styled from 'styled-components';
import sendAnalyticsEvent from '../utils/sendAnalyticsEvent';
import getImages from "../utils/getImages";
import { thumbsAnalytics } from "./Playlist";

const VideoThumbStyled = styled.div`
  position: relative;
  background: white;
  display: block;
  text-decoration: none;
  font-size: 18px;
  overflow: hidden;
  box-shadow: ${theme.shadow.common};
  
  ${breakpoint.tabletUp} {
    font-size: 16px;
  }
`;

const Title = styled.div`
  font-weight: bold;
  padding: 11px 10px 10px;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  min-width: 0;
  text-overflow: ellipsis;
  
  ${breakpoint.desktopUp} {
    padding: 13px 19px 12px;
  }
`;

const ThumbLink = styled(Link)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
`

export default class VideoThumbFPlayer extends React.Component {
  componentDidMount() {
    const { id } = this.props;

    this.observer = new IntersectionObserver(entries => {
      const { isIntersecting } = entries[0];

      if (isIntersecting) {
        thumbsAnalytics.start(id, 'show video-thumb');
      } else {
        thumbsAnalytics.stop(id);
      }
    }, {threshold: 0.5});

    this.observer.observe(this.element);
  }

  componentWillUnmount() {
    this.observer.unobserve(this.element);
  }

  get images() {
    return getImages(this.props.image, {
      [bp.smallMobile]: '305x172',
      [bp.bigMobile]: '369x208',
      [bp.tablet]: '328x185',
      [bp.desktop]: '425x239',
    });
  }

  handleClick = (ev) => {
    const { url, position, id } = this.props;

    thumbsAnalytics.send(id, 'click video-thumb');

    sendAnalyticsEvent('VIDEO_THUMB', {
      url: url,
      position: position.toString()
    });
    sendAnalyticsEvent('PRELAND_CLICKED');

    if (!ev.defaultPrevented && window.isInterstitialLander) {
      ev.preventDefault();
      window.location.href = url;
    }
  };

  render() {
    const { url, title } = this.props;

    return (
      <VideoThumbStyled>
        <Thumb images={this.images} alt="" proportion="16:9"/>
        <Title title={title}>{ title }</Title>
        <ThumbLink to={url} onClick={this.handleClick} innerRef={el => this.element = el}/>
      </VideoThumbStyled>
    );
  }
}

VideoThumbFPlayer.propTypes = {
  id: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  length: PropTypes.string
};
