import React from 'react';
import Svg from "./Svg";

const IconMenu = props => {
  return (
    <Svg
      title="Menu Icon"
      size={props.size || 23}
      className={props.className}
      width={23}
      height={18}>
      <path d="M1.5,0 L21.5,0 C22.3,0 23,0.7 23,1.5 C23,2.3 22.3,3 21.5,3 L1.5,3 C0.7,3 0,2.3 0,1.5 C0,0.7 0.7,0 1.5,0 Z M1.5,7.5 L21.5,7.5 C22.3,7.5 23,8.2 23,9 C23,9.8 22.3,10.5 21.5,10.5 L1.5,10.5 C0.7,10.5 0,9.8 0,9 C0,8.2 0.7,7.5 1.5,7.5 Z M1.5,15 L21.5,15 C22.3,15 23,15.7 23,16.5 C23,17.3 22.3,18 21.5,18 L1.5,18 C0.7,18 0,17.3 0,16.5 C0,15.7 0.7,15 1.5,15 Z"/>
    </Svg>
  )
};

export default IconMenu;
