export const googleAnalytics4 = "G-BTHXEFLXJN";
export const yandexMetrika = "54382222";
export const performanceMonitor = "/stats/perf";
export const clickHouse = "/stats/events";
export const sentryDns = "https://4472720bead04c78ac4a47415ad6b3da@traces.mynewcdn.com/2";
export const sentryParams = {
  blacklistUrls: [
    /amp4ads/,
    /ampproject/,
    /amp-intersection-observer/,
    /googlevideo.com/,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
  ignoreErrors: [
    /Error: Network error/,
    /^No error$/,
    /No error message/,
    /Blocked a frame with origin/,
    // ad from googlevideo:
    /InvalidStateError: The object is in an invalid state/,
    /Failed to execute 'appendBuffer' on 'SourceBuffer'/,
    /Failed to read the 'buffered' property from 'SourceBuffer'/,
    /Failed to execute 'addSourceBuffer' on 'MediaSource'/,
    /ResizeObserver loop limit exceeded/,
    /InvalidStateError: An attempt was made to use an object that is not, or is no longer, usable/,
    // browser extensions
    /vid_mate_check is not defined/,
    /Cannot read property 'getReadModeExtract'/,
    /Cannot read property 'getReadModeConfig'/,
    /Cannot read property 'getReadModeRender'/,
    /content blocker/,
    /Access is denied for this document/,
    /URI malformed/,
    // Random plugins/extensions
    "top.GLOBALS",
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    "originalCreateNotification",
    "canvas.contentDocument",
    "MyApp_RemoveAllHighlights",
    "http://tt.epicplay.com",
    "Can't find variable: ZiteReader",
    "jigsaw is not defined",
    "ComboSearch is not defined",
    "http://loading.retry.widdit.com/",
    "atomicFindClose",
    // Facebook borked
    "fb_xd_fragment",
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    "bmi_SafeAddOnload",
    "EBCallBackMessageReceived",
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    "conduitPage",
    "<anonymous>",
  ],
  // debug: true,
  // beforeSend: event => {console.log(event); return event}
};
