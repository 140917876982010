import React from "react";
import styled from 'styled-components';

const AdStyled = styled.div`
  visibility: hidden;
  opacity: 0;

  & > div {
    left: 0;
    bottom: 0;
    z-index: 1;
  }
  
  &.ad-linear > div {
    background: #202020;
    z-index: 3;
    right: ${props => props.isFullscreen ? '0' : 'auto'};
    top: ${props => props.isFullscreen ? '0' : 'auto'};
  }
`;

const Ad = props => {
  const { setRef, adLinear, isFullscreen } = props;
  return (
    <AdStyled
      ref={setRef}
      className={!adLinear ? 'ad-non-linear' : 'ad-linear'}
      isFullscreen={isFullscreen} />
  )
};

export default Ad;
