let fsApi = {prefixed: true};
const apiMap = [
  [
    'requestFullscreen',
    'exitFullscreen',
    'fullscreenElement',
    'fullscreenEnabled',
    'fullscreenchange',
    'fullscreenerror',
    'fullscreen'
  ], [
    'webkitRequestFullscreen',
    'webkitExitFullscreen',
    'webkitFullscreenElement',
    'webkitFullscreenEnabled',
    'webkitfullscreenchange',
    'webkitfullscreenerror',
    '-webkit-full-screen'
  ],[
    'webkitEnterFullscreen',
    'webkitExitFullscreen',
    'webkitFullscreenElement',
    'webkitFullscreenEnabled',
    'webkitfullscreenchange',
    'webkitfullscreenerror',
    '-webkit-full-screen'
  ], [
    'mozRequestFullScreen',
    'mozCancelFullScreen',
    'mozFullScreenElement',
    'mozFullScreenEnabled',
    'mozfullscreenchange',
    'mozfullscreenerror',
    '-moz-full-screen'
  ], [
    'msRequestFullscreen',
    'msExitFullscreen',
    'msFullscreenElement',
    'msFullscreenEnabled',
    'MSFullscreenChange',
    'MSFullscreenError',
    '-ms-fullscreen'
  ]
];

const specApi = apiMap[0];
let browserApi;

if(typeof window !== 'undefined') {
  // determine the supported set of functions
  const tempVideoEl = document.createElement('video');

  for (let i = 0; i < apiMap.length; i++) {
    // check for requestFullscreen function
    const requestCommand = apiMap[i][0];

    if ((requestCommand in document) || (requestCommand in tempVideoEl)) {
      browserApi = apiMap[i];
      break;
    }
  }

  // map the browser API names to the spec API names
  if (browserApi) {
    for (let i = 0; i < browserApi.length; i++) {
      fsApi[specApi[i]] = browserApi[i];
    }

    fsApi.prefixed = browserApi[0] !== specApi[0];
  }
}

export default fsApi;
