import sendAnalyticsEvent from './sendAnalyticsEvent';
import getCookie from './getCookie';
import delayedEvents from './delayedEvents';

class BannerObserver {
  constructor() {
    this.reset();
  }

  init = () => {
    return new IntersectionObserver(
      entries => {
        const { isIntersecting, intersectionRatio, target } = entries[0];
        this.analyticsAction = target.dataset.analyticsAction;
        this.analyticsActionSpecial = target.dataset.analyticsActionSpecial;
  
        if ( !isIntersecting && intersectionRatio < 0.5 ) {
          clearTimeout(this.timers[this.analyticsAction]);
          this.timers[this.analyticsAction] = null
        } else if(!this.timers[this.analyticsAction] && !this.showedBlock ) {
          this.timers[this.analyticsAction] = setTimeout(() => {
            this.showedBlock = true;
            const adEl = target.firstElementChild;

            // adsense
            if(adEl && adEl.tagName === "INS") {
              const status = adEl.dataset.adStatus;
              if(status === 'filled') this.sendAction();
              else if (status !== 'unfilled') this.attrObserver.observe(adEl, { attributes: true });
            }

            // adx
            if(adEl && adEl.tagName === "DIV" && adEl.id && adEl.id.includes('div-gpt-ad')) {
              this.treeTag = 'IFRAME';
              if (adEl.querySelector('iframe')) this.sendAction();
              else this.treeObserver.observe(adEl, {subtree: true, childList: true});
            }

            // yad
            if(adEl && adEl.tagName === "DIV" && adEl.id && adEl.id.includes('yandex')) {
              this.treeTag = 'DIV';
              if (adEl.querySelector('div')) this.sendAction();
              else this.treeObserver.observe(adEl, {subtree: true, childList: true});
            }
          }, 1000);
        }
      },
      { threshold: [0, 0.5] }
    )
  }

  attrObserver = new MutationObserver(
    entries => {
      const entry = entries[0];

      if (
        entry.type === "attributes" &&
        entry.attributeName === "data-ad-status" &&
        entry.target.dataset.adStatus === "filled"
      ) {
        this.sendAction();
        this.attrObserver.disconnect();
      }
    }
  );

  treeObserver = new MutationObserver(
    mutationList => {
      mutationList.forEach(mutation => {
        mutation.addedNodes.forEach(node => {
          if(node.tagName === this.treeTag) {
            this.sendAction();
            this.treeObserver.disconnect();
          };
        });
      });
    }
  );

  sendAction = () => {
    sendAnalyticsEvent(this.analyticsAction);

    if (this.analyticsActionSpecial) {
      if (getCookie('shownInterstitial'))
        sendAnalyticsEvent(this.analyticsActionSpecial);
      else
        delayedEvents().add({name: this.analyticsActionSpecial});
    }
  }

  reset = () => {
    this.timers = {}
    this.showedBlock = false;
  }
}

export default BannerObserver;
