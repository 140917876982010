import templateStrings from "./templateStrings";

let vastUrlMacrosReplace = function(vastUrlTemplate, options) {
  let advReferer = encodeURIComponent(window.location.href);
  let advVideoID = window.location.pathname;
  let pageLang = document.documentElement.lang.trim();
  let userAgent = encodeURI(navigator.userAgent);

  let videoTitle = encodeURI(options.title.trim());

  let playerWidth = options.dimensions.width;
  let playerHeight = options.dimensions.height;

  function maxAdvSize(sizes) {
    let resultSize = `${sizes[0].width}x${sizes[0].height}`;

    sizes.forEach(size => {
      if (size.width <= playerWidth && size.height <= playerHeight) {
        resultSize = `${size.width}x${size.height}`;
      }
    });

    return resultSize;
  }

  let advSizes = [{ width: 360, height: 203 }, { width: 640, height: 360 }, { width: 816, height: 459 }];

  let vastParams = {
    playerWidth: playerWidth, // Ширина плейера
    playerHeight: playerHeight, // Высота плейера
    refererUrl: advReferer, // Урл страницы где размещается реклама
    pageLang: pageLang, // Язык контента
    contentLength: options.duration, // Длительность видео в секундах
    contentId: advVideoID, // ID видоса
    contentTitle: videoTitle, // Title видоса
    userAgent: userAgent,
    randKey: Date.now(), // Уникальная строка для каждого запроса
    timestamp: Date.now(),
    playerStandardSize: maxAdvSize(advSizes)
  };

  return templateStrings(vastUrlTemplate, vastParams);
};

export default vastUrlMacrosReplace;
