import Events from './Events';
import { options } from "../Player";
import vastPreProcessing from "../../../utils/vastPreProcessing";
import Api from "../Api";
import StorageApi from '../Api/StorageApi';
import MHelper from "../../../utils/MHelper";

export default class Adv {
  constructor(api, vasts, callbacks) {
    this.api = api;
    this.vasts = vasts;

    this.onAdRequest = callbacks.onAdRequest;
    this.onAdImpression = callbacks.onAdImpression;
    this.onAdClick = callbacks.onAdClick;
    this.onAdComplete = callbacks.onAdComplete;
    this.onAdErrorCall = callbacks.onAdError;
    this.heightSwitcher = callbacks.heightSwitcher;

    if (window.google && google.ima && google.ima.AdDisplayContainer) {
      // console.log('%c%s', 'color: aqua;', 'ima sdk available ' + (performance.now() - window.start));

      this.init();
    } else {
      // console.log('%c%s', 'color: aqua;', 'ima sdk waiting ' + (performance.now() - window.start));

      const loadSdk = new Promise((resolve) => {
        document.querySelector('script[src*="sdkloader/ima3"]')
          .addEventListener('load', () => {
            // console.log('%c%s', 'color: aqua;', 'ima sdk onLoad ' + (performance.now() - window.start));
            resolve();
          });
      });

      loadSdk.then(() => {
        // console.log('%c%s', 'color: aqua;', 'ima sdk resolve ' + (performance.now() - window.start));
        this.init();
      });
    }
  }

  videoPlay = () => {
    if (this.api.getState().videoLoaded) {
      this.api.play();
    } else {
      this.api.loadAndPlay();
    }
  };

  init = () => {
    // enable vpaid insecure
    // google.ima.settings.setVpaidMode(google.ima.ImaSdkSettings.VpaidMode.INSECURE);
    this.adDisplayContainer = new google.ima.AdDisplayContainer(this.api.adEl);

    // Re-use this AdsLoader instance for the entire lifecycle of your page.
    this.adsLoader = new google.ima.AdsLoader(this.adDisplayContainer);

    // Add event listeners
    this.adsLoader.addEventListener(
      google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
      this.onAdsManagerLoaded,
      false);

    this.adsLoader.addEventListener(
      google.ima.AdErrorEvent.Type.AD_ERROR,
      this.onAdError,
      false);

    // An event listener to tell the SDK that our content video
    // is completed so the SDK can play any post-roll ads.
    this.api.videoEl.onended = this.contentEndedListener;
    this.requestParams();

    window.addEventListener('resize', this.resizeAdsManager);
    window.addEventListener('orientationchange', this.orientationChange);
  };

  resizeAdsManager = () => {
    if(this.adsManager && this.isProgress) {
      this.adsManager.resize(
        this.dimensions.width,
        this.isLinear ? ( this.api.getState().isFullscreen ? MHelper.browserHeight : this.dimensions.height) : this.adHeight + 20,
        this.viewMode
      );
    }

    this.requestParamsSize();
  };

  orientationChange = () => {
    this.orientationChanged().then(() => {
      this.resizeAdsManager();
    });
  };

  // Wait until innerheight changes, for max 120 frames
  orientationChanged = () => {
    const timeout = 120;
    return new window.Promise(function(resolve) {
      const go = (i, height0) => {
        window.innerHeight !== height0 || i >= timeout ?
          resolve() :
          window.requestAnimationFrame(() => go(i + 1, height0));
      };
      go(0, window.innerHeight);
    });
  };

  get dimensions() {
    return Api.getDimensions(this.api.videoEl, options.minHeight)
  }

  startAd = () => {
    if (this.api.getState().firstPlay)
      this.api.firstPlayHandler();

    if(this.adsLoader) {
      this.onAdRequest();
      this.requestAds();
    } else {
      this.videoPlay();
      this.api.setState({adLoading: false});
    }
  };

  requestParams = () => {
    this.adsRequest = new google.ima.AdsRequest();
    this.adsRequest.vastLoadTimeout = 10000;
    this.adsRequest.forceNonLinearFullSlot = false;
    this.adsRequest.setAdWillAutoPlay(false);
    this.adsRequest.setContinuousPlayback(true);
    this.requestParamsSize();
  };

  requestParamsSize = () => {
    // Specify the linear and nonlinear slot sizes. This helps the SDK to
    // select the correct creative if multiple are returned.
    if (this.adsRequest) {
      this.adsRequest.linearAdSlotWidth = this.dimensions.width;
      this.adsRequest.linearAdSlotHeight = this.dimensions.height;
      this.adsRequest.nonLinearAdSlotWidth = this.dimensions.width;
      this.adsRequest.nonLinearAdSlotHeight = this.dimensions.height;
    }
  };

  requestAds = () => {
    this.api.setState({adLoading: true});

    const state = this.api.getState();
    this.adsRequest.contentTitle = state.title;
    this.adsRequest.contentDuration = state.secDuration;
    this.adsRequest.setAdWillPlayMuted(Boolean(StorageApi.mute));
    this.adsRequest.adTagUrl = !MHelper.ifSSR ? vastPreProcessing(
      this.vasts, {
        title: state.title,
        duration: state.secDuration,
        dimensions: this.dimensions,
      }) : this.vasts[0].vast_code;

    // Must be done as the result of a user action on mobile
    this.adDisplayContainer.initialize();

    this.adsLoader.requestAds(this.adsRequest/*, {
      requestType: "TYPE_SCHEDULE",
      vpaidMode: "insecure",
      playerVersion: "1.0.1",
      adTagUrl: this.vasts,
    }*/);
  };

  contentEndedListener = () => {
    this.adsLoader.contentComplete();
  };

  onAdsManagerLoaded = (adsManagerLoadedEvent) => {
    const adsRenderingSettings = new google.ima.AdsRenderingSettings();
    adsRenderingSettings.enablePreloading = true;
    adsRenderingSettings.restoreCustomPlaybackStateOnAdBreakComplete = true;
    adsRenderingSettings.loadVideoTimeout = 10000;
    // adsRenderingSettings.bitrate = 500;
    this.adsManager = adsManagerLoadedEvent.getAdsManager(this.api.videoEl, adsRenderingSettings); // See API reference for contentPlayback
    this.registerEvents();
    this.adsManagerInit();
  };

  adsManagerInit = () => {
    try {
      this.adsManager.init(this.dimensions.width, this.dimensions.height, this.viewMode);
      this.adsManager.start();
    } catch (adError) {
      console.log('adsManagerInit', adError);
    }
  };

  adsManagerRefresh = () => {
    if (!this.adsManager) return false;
    this.handleEnd();
    this.adsManager.destroy();
    this.adsManagerInit();
  };

  registerEvents = () => {
    const typeAdEvent = google.ima.AdEvent.Type;
    const typeAdErrorEvent = google.ima.AdErrorEvent.Type;

    // Add listener to the error event
    this.adsManager.addEventListener(
      typeAdErrorEvent.AD_ERROR,
      this.onAdError
    );

    // Add listeners to the required events
    for (let eventsKey in Events) {
      this.adsManager.addEventListener(typeAdEvent[Events[eventsKey]], e => {
        // console.log('%c%s', 'color:pink;', e.type);
        if (!this[eventsKey]) return;
        this[eventsKey](e);
      });
    }
  };

  onContentPauseRequested = (e) => {
    // This function is where you should setup UI for showing ads (e.g.
    // display ad timer countdown, disable seeking, etc.)
    this.api.videoEl.removeEventListener('ended', this.contentEndedListener);
    this.api.pause();
  };

  onContentResumeRequested = () => {
    // This function is where you should ensure that your UI is ready
    // to play content.
    this.api.videoEl.addEventListener('ended', this.contentEndedListener);
    this.videoPlay();
  };

  onLoaded = e => {
    const ad = e.getAd();
    this.isLinear = ad.isLinear();

    this.handleStart();

    this.api.setState({ adLinear: this.isLinear });
    this.adHeight = ad.getHeight();

    this.resizeAdsManager();

    if(!this.isLinear) {
      this.videoPlay();
    } else if (!this.api.getState().videoLoaded) {
      this.api.load();
    }
  };

  onStarted = e => {
    this.api.setState({adLoading: false});
  };

  getContentType = type => {
    return type.getAd().contentType;
  };

  onImpression = type => {
    this.onAdImpression(this.getContentType(type));
  };

  onClick = type => {
    this.onAdClick(this.getContentType(type));
  };

  onComplete = type => {
    this.onAdComplete(this.getContentType(type));
    this.handleEnd();
  };

  onAllAdsCompleted = () => {
    this.handleEnd();
  };

  onAdError = (adErrorEvent) => {
    this.onAdErrorCall(adErrorEvent.getError().getMessage());
    console.log(adErrorEvent.getError());

    this.videoPlay();

    if (this.adsManager) {
      this.adsManager.destroy();
    }

    this.handleEnd();
    this.api.setState({adLoading: false});
  };

  onUserClose = e => {
    if (e.getAd().isLinear())
      this.handleEnd();
  };

  onPlayerUnmount = () => {
    window.removeEventListener('resize', this.resizeAdsManager);
    window.removeEventListener('orientationchange', this.orientationChange);

    if (this.api.videoEl)
      this.api.videoEl.removeEventListener('ended', this.contentEndedListener);

    if (this.adsManager)
      this.adsManager.destroy();

    if (this.adsLoader) {
      this.adsLoader.removeEventListener(
        google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
        this.onAdsManagerLoaded,
        false);
  
      this.adsLoader.removeEventListener(
        google.ima.AdErrorEvent.Type.AD_ERROR,
        this.onAdError,
        false);

        this.adsLoader.destroy();
    }

    if (this.adDisplayContainer)
      this.adDisplayContainer.destroy();
  }

  handleEnd = () => {
    if (this.isLinear) this.heightSwitcher(false);
    this.api.adEl.style.visibility = 'hidden';
    this.api.adEl.style.opacity = '0';
    this.api.adEl.style.pointerEvents = 'none';
    this.api.adEl.style.display = 'none';
    this.isProgress = false;
  };

  handleStart = () => {
    if (this.isLinear) this.heightSwitcher(true);
    this.api.adEl.style.visibility = 'visible';
    this.api.adEl.style.opacity = '1';
    this.api.adEl.style.pointerEvents = 'all';
    this.api.adEl.style.display = 'block';
    this.isProgress = true;
  };

  get viewMode() {
    const viewMode = google.ima.ViewMode;
    if (this.api.videoEl && this.api.videoEl.nodeName === 'VIDEO' && this.api.getState().isFullscreen) {
      return viewMode.FULLSCREEN;
    }
    return viewMode.NORMAL;
  }
}
