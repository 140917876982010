import React from 'react';
import PropTypes from 'prop-types';
import styled  from 'styled-components';
import { breakpoint } from './theme';
import Loader from './primitives/Loader';


const PagePreloaderStyled = styled.div`
  background: rgba(246,246,246,0.6);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.15s linear 0s, visibility 0s linear 0.15s;
  
  &.active {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.15s linear 0.2s, visibility 0s linear 0.2s;
  }
`;

const Inner = styled.div`
  background: white;
  width: 70px;
  height: 70px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -35px;
  margin-top: -35px;
  
  ${breakpoint.tabletUp} {
    width: 100px;
    height: 100px;
    margin-left: -50px;
    margin-top: -50px;
  }
`;

const PagePreloader = props => {
  const { active } = props;
  return (
    <PagePreloaderStyled className={active ? 'active' : ''}>
      {active && <Inner>
        <Loader bg="white"/>
      </Inner>}
    </PagePreloaderStyled>
  )
};

export default PagePreloader;

PagePreloader.propTypes = {
  active: PropTypes.bool,
};
