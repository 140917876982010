import styled from 'styled-components';
import theme from '../theme';

const Button = styled.button`
  background: transparent;
  color: ${theme.color.common};
  border: 0;
  font-size: 17px;
  outline: none;
  cursor: pointer;
  transition: all 0.075s linear;
  user-select: none;
  text-transform: uppercase;
  font-weight: bold;
  display: inline-flex;
  padding: 0.1em 0.1em 0.12em;
  font-family: ${theme.fonts.common};
  
  &:hover {
    color: ${theme.color.common_h};
  }
  
  :active {
    color: white;
  }
`;

export default Button;
