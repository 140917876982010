import React from 'react';
import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';
import theme, { breakpoint } from "./theme";
import setMeta from "../utils/setMeta";

const Styles = createGlobalStyle`
  .text {
    h1, h2, h3 {
      line-height: 1.2;
      margin: 1em 0 0.68px;
      font-weight: 500;

      &:first-child {
        margin-top: 0;
      }
    }
    
    h1 { font-size: 2em; }
    h2 { font-size: 1.5em; }
    h3 { font-size: 1.2em; }
    p { margin: 1em 0; }
    a { color: ${theme.color.bright}; }
    b, strong { font-weight: bold; }
    em { font-style: italic; }
  }
`;

const Article = styled.div`
  margin: 10px;
  line-height: 1.5;
  font-size: 15px;
  
  ${breakpoint.desktopUp} {
    margin: 20px;
  }
`;

export default class PageArticle extends React.Component {
  componentDidMount() {
    setMeta(this.props.pageTitle, this.props.pageDescription);
  }

  componentDidUpdate () {
    setMeta(this.props.pageTitle, this.props.pageDescription);
  }

  render() {
    const { pageText } = this.props.pageData;
    return (
      <React.Fragment>
        <Styles />
        <Article className="text" dangerouslySetInnerHTML={{__html: pageText}} />
      </React.Fragment>
    )
  }
}

PageArticle.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  pageDescription: PropTypes.string,
  pageData: PropTypes.shape({
    pageText: PropTypes.string,
  }).isRequired,
};
