import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import theme, { breakpoint } from './theme';
import CarouselThumb from './CarouselThumb';
import renderMessage from '../utils/renderMessage';
import Scroller from "../libs/Scroller";
import dataPathWithParams from '../utils/dataPathWithParams';
import IconChevronLeft from "./Icons/IconChevronLeft";
import IconChevronRight from "./Icons/IconChevronRight";
import IconChevronRoundLeft from "./Icons/IconChevronRoundLeft";
import IconChevronRoundRight from "./Icons/IconChevronRoundRight";

const ScrollerStyled = styled.div.attrs({  })`
  position: relative;
`;

const Container = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
`;

const Scrolled = styled.div`
  display: inline-flex;
  position: relative;
  height: 100%;
  transition: .5s;
  font-size: 0;
  margin: ${props => props.isBig? '0 -5px' : false}
`;

const Buttons = css`
  font-size: ${props => props.isBig ? '60px' : '26px'};
  width: ${props => props.isBig ? '60px' : '40px'};
  height: ${props => props.isBig ? '60px' : '40px'};
  margin-top: ${props => props.isBig ? '-30px' : '-39px'};
  transition: ${props => props.isBig ? 'fill' : 'background'} 0.1s linear;
  filter: drop-shadow(${props => props.isBig ? '0 3px 6px rgba(0,0,0,0.16)' : '0 3px 6px rgba(0,0,0,0.1)'}); 
  position: absolute;
  top: 50%;
  border: 0;
  color: white;
  cursor: pointer;
  outline: none;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: transparent;
  display: none;
  fill: white;
  
  ${breakpoint.tabletOnly} {
    width: 40px;
    height: 40px;
    font-size: 40px;
    margin-top: -20px;
    display: ${props => props.isBig ? 'inline-flex' : 'none'};
  }
  
  ${breakpoint.desktopUp} {
    display: inline-flex;
  }
  
  &:hover {
    background: ${props => props.isBig ? 'transparent' : theme.color.bright};
    fill: ${props => props.isBig ? theme.color.bright : false};
  }
  
  span {
    display: inline-flex;
  }
`;

const Prev = styled.button`
  ${Buttons};
  left: ${props => props.isBig ? '20px' : '24px'};
  padding-left: 3px;
`;

const Next = styled.button`
  ${Buttons};
  right: ${props => props.isBig ? '20px' : '24px'};
  padding-right: 3px;
`;

const Slide = styled.div`
  outline: none;
  text-align: left;
  padding: ${props => props.isBig ? '0 5px' : '5px'};
  width: ${props => props.isBig ? '344px' : '170px'};
  flex-basis: ${props => props.isBig ? '344px' : '170px'};
  flex-shrink: 0;
  min-width: 0;
  
  ${breakpoint.tabletOnly} {
    width: ${props => props.isBig ? '660px' : '230px'};
    flex-basis: ${props => props.isBig ? '660px' : '230px'};
  }
  
  ${breakpoint.desktopUp} {
    padding: ${props => props.isBig ? '0 5px' : '5px 8px'};
    width: ${props => props.isBig ? '950px' : '306px'};
    flex-basis: ${props => props.isBig ? '950px' : '306px'};
  }
`;

export default class Carousel extends React.Component {
  constructor(props) {
    super(props);
    this.setCarouselRef = this.setCarouselRef.bind(this);
  }

  setCarouselRef(node) {
    this.carouselRef = node;
  }

  componentDidMount() {
    if (this.carouselRef) {
      this.scroller = new Scroller(this.carouselRef, {
        container: '.container',
        scrolled: '.scrolled',
        item: '.item',
        prevButton: '.scroller__prev',
        nextButton: '.scroller__next',
      });

      const { id, dataPath, addSlides, isBig } = this.props;
      if(dataPath) {
        this.scroller.onLoad((count, visibleItems) => {
          if(count < visibleItems) {
            addSlides(id, dataPathWithParams(dataPath, count, visibleItems - count + 2, this.props), isBig);
          }
        });

        this.scroller.onSlideEnd(3, (count) => {
          const { isEnd, isFetching } = this.props;
          if (isEnd || isFetching) return;
          addSlides(id, dataPathWithParams(dataPath, count, 10, this.props), isBig);
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.scroller.resizeCarousel();
  }

  renderSlides() {
    const { isBig } = this.props;

    return this.props.items.map((item) => {
      return (
        <Slide key={item.id} className="item" isBig={isBig}>
          <CarouselThumb {...item} isBig={isBig}/>
        </Slide>
      );
    });
  }

  render() {
    renderMessage('Carousel');

    const { isBig } = this.props;
    return (
      <ScrollerStyled ref={this.setCarouselRef}>
        <Container className="container">
          <Scrolled className="scrolled" isBig={isBig}>
            {this.renderSlides()}
          </Scrolled>
        </Container>
        <Prev className="scroller__prev" isBig={isBig}>
          {isBig && <IconChevronRoundLeft size={60}/>}
          {!isBig && <IconChevronLeft size={20}/>}
        </Prev>
        <Next className="scroller__next" isBig={isBig}>
          {isBig && <IconChevronRoundRight size={60}/>}
          {!isBig && <IconChevronRight size={20}/>}
        </Next>
      </ScrollerStyled>
    );
  }
}

Carousel.propTypes = {
  addSlides: PropTypes.func.isRequired,
  dataPath: PropTypes.string.isRequired,
  isBig: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.object.isRequired
  ).isRequired,
};
