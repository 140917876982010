import React from 'react';
import PropTypes from 'prop-types';
import deepEqual from '../../utils/deepEqual';

const ThumbSources = ({ images }) => {
  const renderSets = (sets) => {
    return Object.keys(sets).map(set => {
      return (
        `${sets[set]} ${set}`
      )
    });
  };

  return (
    Object.keys(images).map((media, key) => {
      return (
        <source key={key} media={media} srcSet={renderSets(images[media])} />
      );
    })
  )
};

function areEqual(prevProps, nextProps) {
  if (deepEqual(prevProps.images, nextProps.images)) return true;
}

export default React.memo(ThumbSources, areEqual);

ThumbSources.propTypes = {
  images: PropTypes.object.isRequired,
};
