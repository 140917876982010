import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
// import Button from './primitives/Button';
import theme, { breakpoint } from './theme';
import renderMessage from '../utils/renderMessage';
import LogoHeader from './LogoHeader/LogoHeader';
import MenuButton from './MenuButton';
// import IconSearch from "./Icons/IconSearch";
import CookieBanner from "./CookieBanner";

const HeaderTag = styled.header`
  background-color: ${theme.color.white};
  box-shadow: 0 3px 6px rgba(0,0,0,0.16);
  position: relative;
`;

const HeaderInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
  max-width: ${theme.size.pageMaxWidth};
  margin: 0 auto;
  height: 48px;
  
  ${breakpoint.tabletPortrait}, ${breakpoint.desktopOnly.slice(6)} {
    height: 54px;
  }
  
  ${breakpoint.desktopBigUp} {
    height: 82px;
    padding: 8px 33px;
  }
`;

const HeaderColStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

const HeaderCol = styled.div`
  ${HeaderColStyles};
`;

const HeaderColLeft = styled(HeaderCol)``;

// const CentredIcon = styled(Icon)`
//   display: inline-flex;
// `;
//
// const SearchButton = styled(Button)`
//   padding: 7px;
// `;
//
// const LoginButton = styled(Button)`
//   margin-right: 8px;
//   margin-left: 13px;
//
//   ${breakpoint.tabletOnly} {
//     font-size: 20px;
//     margin-left: 9px;
//   }
//
//   ${breakpoint.desktopUp} {
//     font-size: 26px;
//     margin-right: 4px;
//     margin-left: 15px;
//   }
// `;

// const IconSearchStyled = styled(IconSearch)`
//   width: 19px;
//   fill: ${theme.color.common};
//
//   ${breakpoint.desktopUp} {
//     width: 26px;
//   }
// `;

export default class Header extends React.Component {
  // onClickEnter = () => {
  //   this.props.handleLogin();
  // };

  render() {
    renderMessage('Header');
    const { username, isFetching, cookiePolicy } = this.props;
    return (
      <HeaderTag>
        <HeaderInner>
          <HeaderColLeft>
            <MenuButton/>
            <LogoHeader />
          </HeaderColLeft>

          {/*<HeaderCol>*/}
          {/*  <SearchButton>*/}
          {/*    <IconSearchStyled/>*/}
          {/*  </SearchButton>*/}

          {/*  {isFetching && <span>Loading...</span>}*/}
          {/*  {!isFetching && (username.length ?*/}
          {/*    <P>Hello, {username}!</P>*/}
          {/*    :*/}
          {/*    <P><LoginButton onClick={this.onClickEnter}>Login</LoginButton></P>)}*/}
          {/*</HeaderCol>*/}

        </HeaderInner>
        {cookiePolicy.show && <CookieBanner { ...cookiePolicy }/>}
      </HeaderTag>
    );
  }
}

Header.propTypes = {
  username: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  handleLogin: PropTypes.func.isRequired,
  cookiePolicy: PropTypes.object.isRequired,
};
