const url = require('url');
export default function getImages(
  src,
  sizes,
  scales = [1, 2],
  pathPattern = /^\/[a-z]\//,
  namePattern = /[^\/]+$/g
) {
  const { path } = new url.parse(src);
  const imagePath = path.match(pathPattern)[0];
  const imageName = path.match(namePattern)[0];
  let res = {};

  for (let resName in sizes) {
    res[resName] = {};
    const size = sizes[resName];
    const sizeAr = size.split('x');
    const width = ~~sizeAr[0];
    const height = ~~sizeAr[1];

    scales.forEach(scale => {
      res[resName][`${scale}x`] = imagePath + width*scale + `x` + height*scale + `/` + imageName;
    })
  }

  return res;
}
