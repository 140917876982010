import React from 'react';
import renderMessage from '../utils/renderMessage';
import { connect } from 'react-redux';
import { handleRoute, handlePreLand } from '../actions/VideoPageActions';
import PagePreloader from '../components/PagePreloader';
import PageVideo from '../components/PageVideo';
import PagePreland from '../components/PagePreland';

class PageVideoContainer extends React.Component {
  state = {
    firstShow: true,
    prevPagePreLand: false,
  };

  get isPreLand() {
    return !!this.props.match.params.utm_source;
  }

  get correctPath() {
    const { videoPage, location } = this.props;
    const pathName = location.pathname.indexOf('/utm-') > -1 ?
      location.pathname.slice(0, location.pathname.indexOf('/utm-') + 1) : location.pathname;
    return videoPage.currentPath === pathName;
  }

  get showComponent() {
    const { data, isFetching } = this.props.videoPage;
    return !isFetching && data && (this.correctPath || !this.state.firstShow);
  }

  componentDidMount() {
    const { isFetching } = this.props.videoPage;

    if (!isFetching && !this.correctPath) {
      this.props.handleRoute(this.props.location.pathname);
    }

    this.setState({
      firstShow: false,
      prevPagePreLand: this.isPreLand,
    });

    if (this.isPreLand)
      this.props.handlePreLand(true);
  }

  componentDidUpdate(prevProps) {
    const utmPos = prevProps.location.pathname.indexOf('/utm-');
    const prevPathName = utmPos > -1 ?
      prevProps.location.pathname.slice(0, utmPos + 1) : prevProps.location.pathname;

    if (prevProps.location.pathname !== this.props.location.pathname) {

      this.setState({
        firstShow: false,
        prevPagePreLand: this.isPreLand,
      });

      if (!this.state.prevPagePreLand || prevPathName !== this.props.location.pathname)
        this.props.handleRoute(this.props.location.pathname, 'update');
    }
  }

  render() {
    renderMessage('PageVideoContainer');
    const { adNamed, location, history, videoPage, dataPath, siteFeatures } = this.props;
    const { data, pageTitle, pageDescription, isFetching, isUpdate } = videoPage;
    const preloaderActive = (isUpdate || isFetching) && !this.state.prevPagePreLand;

    return (
      <React.Fragment>
        {this.showComponent && !this.isPreLand && <PageVideo
          history={history}
          dataPath={dataPath}
          pathname={location.pathname}
          data={data}
          adNamed={adNamed}
          pageTitle={pageTitle}
          pageDescription={pageDescription}
          siteFeatures={siteFeatures}
        />}
        {this.showComponent && this.isPreLand && <PagePreland
          pathname={location.pathname}
          data={data}
          pageTitle={pageTitle}
          pageDescription={pageDescription}
        />}
        <PagePreloader active={preloaderActive}/>
      </React.Fragment>
    );
  }
}

const mapStateToProps = store => {
  return {
    videoPage: store.videoPage,
    adNamed: store.ads.adNamed,
    dataPath: store.videoPage.dataPath,
    siteFeatures: store.sessionParams.siteFeatures,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleRoute: (pathname, isUpdate) => dispatch(handleRoute(pathname, isUpdate)),
    handlePreLand: isPreLand => dispatch(handlePreLand(isPreLand)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageVideoContainer);
