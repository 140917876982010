import {
  VIDEO_PAGE_REQUEST,
  VIDEO_PAGE_UPDATE,
  VIDEO_PAGE_SUCCESS,
  VIDEO_PAGE_FAIL,
  PLAYLIST_UPDATE,
  PLAYLIST_SUCCESS,
  PLAYLIST_FAIL,
  PRELAND_SHOW,
  PRELAND_END,
} from '../constants';

const initialState = {};//(typeof window !== 'undefined') ? window.initialStates.videoPage : {}; //global.initialStates.videoPage;

export function videoPageReducer(state = initialState, action) {
  switch (action.type) {
    case VIDEO_PAGE_REQUEST:
      return { ...state, isFetching: true, isUpdate: false, error: '', isWasRender: true };
    case VIDEO_PAGE_UPDATE:
      return { ...state, isFetching: false, isUpdate: true, error: '' };
    case VIDEO_PAGE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isUpdate: false,
        data: action.payload.data,
        playlist: action.payload.playlist,
        dataPath: action.payload.dataPath,
        pageTitle: action.payload.pageTitle,
        pageDescription: action.payload.pageDescription,
        nextVideoUrl: action.payload.playlist.items[0].url,
        currentPath: action.payload.currentPath,
      };
    case VIDEO_PAGE_FAIL:
      return { ...state, isFetching: false, isUpdate: false, error: action.payload.message };


    case PLAYLIST_UPDATE:
      return {
        ...state,
        playlist: {
          items: state.playlist.items,
          isFetching: true,
          error: ''
        }
      };
    case PLAYLIST_SUCCESS:
      return {
        ...state,
        playlist: {
          items: [...state.playlist.items, ...action.payload.items],
          isFetching: false,
          isEnd: action.payload.eol,
          error: ''
        }
      };
    case PLAYLIST_FAIL:
      return {
        ...state,
        playlist: {
          items: state.playlist.items,
          isFetching: false,
          isEnd: false,
          error: action.payload.message
        }
      };

    case PRELAND_SHOW:
      return { ...state, isPreLandShown: true };

    case PRELAND_END:
      return { ...state, isPreLandShown: false }

    default:
      return state;
  }
}
