import { createStore, compose, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import rootReducer from '../reducers';
// import logger from 'redux-logger';
import thunk from 'redux-thunk';
import analyticsMiddleware from './analyticsMiddleware';


export default function configureStore(preloadedState, history) {
 return createStore(
    rootReducer(history), // root reducer with router state
    preloadedState,
    compose(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        analyticsMiddleware,
        thunk,
        // logger,
      ),
    ),
  );
}
