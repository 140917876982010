import React from 'react';
import { connect } from 'react-redux';
import Menu from '../components/Menu';
import { menuHide } from '../actions/MenuActions';

class MenuContainer extends React.Component {
  render() {
    const { active } = this.props.menu;
    const { menuHide} = this.props;

    return (
      <Menu
        active={active}
        menuHide={menuHide}
      />
    );
  }
}

const mapStateToProps = store => {
  return {
    menu: store.menu,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    menuHide: () => dispatch(menuHide()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MenuContainer);
