import React from 'react';
import Player from "./Player";
import { thumbsAnalytics } from "../Playlist";
import sendAnalyticsEvent from "../../utils/sendAnalyticsEvent";
import getImages from "../../utils/getImages";
import {bp} from "../theme";
import styled from "styled-components";
import Thumb from "../primitives/Thumb";
import PropTypes from "prop-types";
import getCookie from '../../utils/getCookie';
import delayedEvents from '../../utils/delayedEvents';

import { disabledAutoplay } from '../../constants/splitTest';

const Poster = styled(Thumb)`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  padding: 0!important;
`;

class ReactPlayer extends React.Component {
  state = {
    firstPlay: true,
    startTime: Date.now(),
  };

  firstPlayHandler = () => {
    this.setState({firstPlay: false});
  };

  log(string) {
    // console.log('%c%s', 'color: lime;', `${string}   \t${Date.now() - this.state.startTime} ms`);
  }

  onReady = () => {
    this.log('onReady');
  };

  onPlayPromise = () => {
    this.log('onPlayPromise');
  };

  onPlay = () => {
    this.log('onPlay');
    const { id } = this.props;
    thumbsAnalytics.start(id, 'show video', `video_${id}`);
  };

  onStop = () => {
    this.log('onStop');
    const { id } = this.props;
    thumbsAnalytics.stop(id, `video_${id}`);
  };

  onEnd = () => {
    const { id, nextVideoUrl } = this.props;
    thumbsAnalytics.stop(id, `video_${id}`);
    this.props.history.push(nextVideoUrl);
    this.log('onEnd');
  };

  onAdRequest = () => {
    // console.log('%c%s', 'color: gray;', 'onAdRequest after click', (performance.now() - window.startClick));
    this.log('onAdRequest');
    sendAnalyticsEvent('PLAYER_AD_REQUEST');

    if (getCookie('shownInterstitial')) {
      sendAnalyticsEvent('INVIDEO_LOADED_VIP');
    } else {
      delayedEvents().add({name: 'INVIDEO_LOADED_VIP'});
    }
  };

  onAdImpression = (creativetype) => {
    sendAnalyticsEvent('PLAYER_AD_IMPRESSION', {
      creativetype: creativetype
    });
  };

  onAdClick = (creativetype) => {
    this.log('onAdClick');
    sendAnalyticsEvent('PLAYER_AD_CLICK', {
      creativetype: creativetype
    });
  };

  onAdComplete = (creativetype) => {
    this.log('onAdComplete');
    sendAnalyticsEvent('PLAYER_AD_COMPLETE', {
      creativetype: creativetype
    });
  };

  onAdError = (message) => {
    sendAnalyticsEvent('PLAYER_AD_ERROR', {
      message: message
    });
  };

  onAutoPlayStart = () => {
    const { isPreLandShown, handlePreLand } = this.props;

    if (isPreLandShown) {
      handlePreLand(false);
      sendAnalyticsEvent('PLAYER_AUTOPLAY_START');
    }
  }

  onAutoPlayError = (message) => {
    const { isPreLandShown, handlePreLand } = this.props;

    if (isPreLandShown) {
      handlePreLand(false);
      sendAnalyticsEvent('PLAYER_AUTOPLAY_ERROR', {
        message: message
      });
    }
  }

  get images() {
    return getImages(this.props.posterUrl, {
      [bp.screen375]: '375x211',
      [bp.screen414]: '414x233',
      [bp.screen640]: '640x360',
      [bp.bigMobile]: '768x432',
      [bp.tablet]: '1024x576',
      [bp.desktop]: '1024x576',
    });
  }

  render() {
    const { id, siteFeatures } = this.props;
    console.log(this.props);
    const { firstPlay } = this.state;
    return (
      <Player
        {...this.props}
        firstPlayHandler={this.firstPlayHandler}

        onReady={this.onReady}
        onPlayPromise={this.onPlayPromise}
        onPlay={this.onPlay}
        onResume={this.onPlay}
        onAutoStart={this.onPlay}
        onPause={this.onStop}
        onEnd={this.onEnd}

        onAdRequest={this.onAdRequest}
        onAdImpression={this.onAdImpression}
        onAdClick={this.onAdClick}
        onAdComplete={this.onAdComplete}
        onAdError={this.onAdError}

        onAutoPlayStart={this.onAutoPlayStart}
        onAutoPlayError={this.onAutoPlayError}

        autoPlay={!siteFeatures.includes(disabledAutoplay)}
      >
        <Poster
          images={this.images}
          proportion="16:9"
          key={id}
          objectFit="contain"
          disableLoader={!firstPlay}
          iosBorderHack={!firstPlay}
        />
      </Player>
    )
  }
}

export default ReactPlayer

ReactPlayer.propTypes = {
  id: PropTypes.number.isRequired,
  length: PropTypes.string.isRequired,
  posterUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  videoUrl: PropTypes.string.isRequired,
  widthVideoUrl: PropTypes.array,
  vasts: PropTypes.array.isRequired,
  nextVideoUrl: PropTypes.string.isRequired,
  isPreLandShown: PropTypes.bool.isRequired,
  handlePreLand: PropTypes.func.isRequired,
};
