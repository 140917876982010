import React from 'react';
import Svg from "./Svg";

const IconPlaymarket = props => {
  return (
    <Svg
      title="Playmarket Icon"
      size={props.size || 24}
      className={props.className}
      width={24}
      height={24}>
      <path d="M20.6374 10.7994C21.5653 11.33 21.5653 12.6702 20.6374 13.2007L15.8187 15.9548L11.8942 12.0093L15.8228 8.04759L20.6374 10.7994Z" fill="#FFCE00"/>
      <path d="M15.8228 8.04759L11.8942 12.0093L0.37866 0.431759C0.791876 -0.00073369 1.47373 -0.153395 2.06348 0.183648L15.8228 8.04759Z" fill="#00E464"/>
      <path d="M11.8942 12.0093L15.8187 15.9548L2.06362 23.8163C1.48631 24.1463 0.820649 24.0071 0.404975 23.5949L11.8942 12.0093Z" fill="#E94335"/>
      <path d="M0 1.38423C0 1.00359 0.147184 0.673931 0.37866 0.431759L11.8942 12.0093L0.404975 23.5949C0.158462 23.3508 0 23.0107 0 22.6158V1.38423Z" fill="#01D0FF"/>
    </Svg>
  )
};

export default IconPlaymarket;
