import React from 'react';
import { connect } from 'react-redux';
import PagePreloader from '../components/PagePreloader';
import PageArticle from '../components/PageArticle';
import { handleArticle } from '../actions/ArticlePageActions';

class PageArticleContainer extends React.Component {
  componentDidMount() {
    const { pageData, isWasRender } = this.props.articlePage;
    if (isWasRender || !pageData.pageText) {
      this.props.handleArticle(this.props.location.pathname);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.props.handleArticle(this.props.location.pathname);
    }
  }

  render() {
    const { isFetching, pageData } = this.props.articlePage;
    return (
      <React.Fragment>
        {!isFetching && pageData && <PageArticle {...this.props.articlePage}/> }
        <PagePreloader active={isFetching}/>
      </React.Fragment>
    )
  }
}

const mapStateToProps = store => {
  return {
    articlePage: store.articlePage,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleArticle: (pathname) => dispatch(handleArticle(pathname)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageArticleContainer);
