import {ARTICLE_REQUEST, ARTICLE_SUCCESS, ARTICLE_FAIL} from "../constants";

export function articlePageReducer(state = {}, action) {
  switch (action.type) {
    case ARTICLE_REQUEST:
      return {...state, isFetching: true, error: '', isWasRender: true };

    case ARTICLE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        pageData: action.payload.pageData,
        pageDescription: action.payload.data,
        pageTitle: action.payload.pageTitle,
      };

    case ARTICLE_FAIL:
      return {...state, isFetching: false, error: action.payload.message};

    default:
      return state;
  }
}
