/**
 * These are custom helper methods that are not native
 * to the HTMLMediaElement API. Pass in the native
 * Video element, state and optional desired value to
 * set. To be primarily used in `mapVideoElToProps`.
 */
import StorageApi from './StorageApi';
import MHelper from "../../../utils/MHelper";

export default class Api {
  constructor(options) {
    this.setState = options.setState;
    this.videoEl = options.videoEl;
    this.sourceEl = options.sourceEl;
    this.adEl = options.adEl;
    this.setState = options.setState;
    this.getState = options.getState;
    this.firstPlayHandler = options.firstPlayHandler;
    this.onAutoPlayStart = options.onAutoPlayStart;
    this.onAutoPlayError = options.onAutoPlayError;
  }

  load = () => {
    this.setState({ videoLoaded: true });
    this.sourceEl.src = this.selectedSource;
    this.videoEl.load();
    this.errorDisableTimer = setTimeout(() => {
      this.setState({ ErrorDisableTimeout: false });
    }, 1000);
  };

  play = (startAd) => {
    const playPromise = this.videoEl.play();

    if (playPromise !== undefined) {
      playPromise
        .then(_ => {
          this.onAutoPlayStart();
          if (startAd)
            startAd();
        })
        .catch(({ message }) => {
          this.onAutoPlayError(message);
          console.log(message);
        });
    }
  };

  pause = () => {
    if (!this.getState().paused) {
      this.videoEl.pause();
    }
  };

  togglePause = () => {
    if (this.getState().paused) {
      this.play();
    } else {
      this.videoEl.pause();
    }
  };

  loadAndPlay = (startAd) => {
    this.load();
    this.play(startAd);
  };

  static setCurrentTime = (videoEl, value) => {
    if (value)
      videoEl.currentTime = value;
  };

  static setVolume = (videoEl, value) => {
    if (value > 0) {
      videoEl.muted = false;
      StorageApi.mute = false;
    } else {
      videoEl.muted = true;
      StorageApi.mute = true;
    }

    videoEl.volume = value;
    StorageApi.volume = value;
  };

  static toggleMute = (videoEl, { volume, muted }) => {
    if (muted || volume <= 0) {
      if (volume <= 0) {
        videoEl.volume = 1;
      }
      videoEl.muted = false;
      StorageApi.mute = false;
    } else {
      videoEl.muted = true;
      StorageApi.mute = true;
    }
  };

  static getPercentageBuffered = (buffered, duration) => {
    return buffered && buffered.length && buffered.end(buffered.length - 1) / duration * 100 || 0;
  };

  static getPercentagePlayed = (currentTime, duration) => {
    return currentTime / duration * 100;
  };

  static getDimensions = (videoEl, minHeight) => {
    const height = videoEl.offsetWidth * 0.5625 >= minHeight ?
      Math.floor(videoEl.offsetWidth * 0.5625) : minHeight;

    return {
      height: height,
      width: videoEl.offsetWidth,
    };
  };

  get selectedSource() {
    const urls = this.getState().widthVideoUrl;

    if (!urls) {
      return this.getState().videoUrl;
    } else {
      const coefficient = MHelper.pixelRatio > 2 ? Math.round((MHelper.pixelRatio - 2) / 6.66 * 100) / 100 + 1 : 1;
      const width = Math.round(MHelper.maxScreenSide * coefficient);
      const items = urls.reduce((result, item) => {
        const key = Object.keys(item)[0];
        result[key] = item[key];
        return result;
      }, {});

      const lessThanWidth = Math.max(...Object.keys(items).filter(num => num <= width));
      const selectedWidth = isFinite(lessThanWidth) ? lessThanWidth : Math.min(...Object.keys(items));

      return items[selectedWidth];
    }
  }
}
