import React from 'react';
import Svg from "./Svg";

const IconChevronRight = props => {
  return (
    <Svg
      title="ChevronRight Icon"
      size={props.size || 32}
      className={props.className}
      width={32}
      height={32}>
      <polygon points="14.9,0 3,0 17.1,16 3,32 14.9,32 29,16 "/>
    </Svg>
  )
};

export default IconChevronRight;
