import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Thumb from './primitives/Thumb';
import theme, { breakpoint, bp } from './theme';
import styled from 'styled-components';
import getImages from "../utils/getImages";
import { thumbsAnalytics } from "./Playlist";

const CarouselThumbStyled = styled(Link)`
  display: block;
  text-decoration: none;
  color: ${theme.color.text};
  font-size: 14px;
  overflow: hidden;
  box-shadow: ${props => props.isBig ? '0 0 10px red' : 'none'};
  
  &.small {
    ${breakpoint.tabletUp} {
      font-size: 16px;
    }
    
    ${breakpoint.desktopUp} {
      border-radius: 8px;
      background: white;
      box-shadow: ${theme.shadow.common};
    }
  }
`;

const Title = styled.div`
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  min-width: 0;
  text-overflow: ellipsis;
  padding: 11px 0 0;
  
  ${breakpoint.desktopUp} {
    padding: 19px 20px 16px;
  }
`;

class CarouselThumb extends React.Component {
  componentDidMount() {
    const { id, isBig } = this.props;

    this.observer = new IntersectionObserver(entries => {
      const { isIntersecting } = entries[0];
      const action = isBig ? 'show carousel-big-thumb' : 'show carousel-thumb';
      const slug = isBig ? `big_${id}` : `small_${id}`;

      if (isIntersecting) {
        thumbsAnalytics.start(id, action, slug);
      } else {
        thumbsAnalytics.stop(id, slug);
      }
    }, {threshold: 0.5});

    this.observer.observe(this.element);
  }

  handleClick = () => {
    const { id, isBig } = this.props;
    const action = isBig ? 'click carousel-big-thumb' : 'click carousel-thumb';
    thumbsAnalytics.send(id, action);
  };

  get images() {
    const { image, isBig } = this.props;

    if(!isBig) {
      return getImages(image, {
        [bp.mobile]: '160x90',
        [bp.tablet]: '220x124',
        [bp.desktop]: '290x164',
      });
    } else {
      return getImages(image, {
        [bp.mobile]: '334x167',
        [bp.tablet]: '650x325',
        [bp.desktop]: '940x470',
      });
    }
  }

  render() {
    const { url, length, title, isBig } = this.props;

    return (
      <CarouselThumbStyled
        onClick={this.handleClick}
        innerRef={el => this.element = el}
        to={url}
        className={isBig ? false : 'small'} >
        <Thumb
          images={this.images}
          alt=""
          length={length}
          proportion={isBig ? "16:8" : "16:9"}
          Radius={isBig ? {} : {m: '4px', t: '5px', d: '8px 8px 0 0'}}
          disableLazy={true}
        />
        {!isBig && <Title title={title}>{ title }</Title>}
      </CarouselThumbStyled>
    );
  }
}

CarouselThumb.propTypes = {
  id: PropTypes.oneOfType([ PropTypes.number, PropTypes.string, ]).isRequired,
  image: PropTypes.string.isRequired,
  isBig: PropTypes.bool,
  length: PropTypes.string,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default CarouselThumb;
