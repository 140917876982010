import React from 'react';
import styled, { css } from "styled-components";
import MHelper from "../../../utils/MHelper";

const Component = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 15px;
  height: 17px;
  background: transparent none;
  z-index: 1;
  outline: none;
`;

const Track = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  touch-action: none;
  height: 5px;
  width: 100%;
  background: rgba(255,255,255,0.3);
`;

const CommonStyles = css`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 2px;
`;

const Buffer = styled.div`
  ${CommonStyles};
  background: rgba(255,255,255,0.3);
`;

const Fill = styled.div`
  ${CommonStyles};
  background-color: #f2f2f2;
  
  &::before {
    content: '';
    position: absolute;
    right: -4px;
    top: -2px;
    height: 9px;
    width: 9px;
    border-radius: 50%;
    background: white;
    box-shadow: 0 0 7px rgba(0,0,0,0.5);
  }
`;

const InputStyles = css`
  ${CommonStyles};
  width: 100%;
  opacity: 0;
  cursor: pointer;
  height: 300%;
  top: -100%;
`;

const InputMobile = styled.div`
  ${InputStyles};
`;

const InputDesktop = styled.input`
  ${InputStyles};
`;

export default ({ariaLabel, onChange, onSeekTouchStop, onSeekTouchMove, seekTouched, percentagePlayed, percentageBuffered, className }) => {
  const percentageFill = seekTouched ? seekTouched : percentagePlayed;

  return (
    <Component className={className}>
      <Track>
        <Buffer
          style={{
            width: `${percentageBuffered || 0}%`
          }}/>
        <Fill
          style={{
            width: `${percentageFill || 0}%`
          }}/>
        {MHelper.hasTouchScreen ?
          <InputMobile
            onTouchMove={onSeekTouchMove}
            onTouchEnd={onSeekTouchStop}
            aria-label={ariaLabel}/>
          :
          <InputDesktop
            min="0"
            step={1}
            max="100"
            type="range"
            orient="horizontal"
            onChange={onChange}
            aria-label={ariaLabel}
            value={percentagePlayed || 0}/>
        }
      </Track>
    </Component>
  );
};
