import { SLIDES_REQUEST, SLIDES_SUCCESS, SLIDES_FAIL } from "../constants";

const initialState = {}; //(typeof window !== 'undefined') ? window.initialStates.homePage : {}; //global.initialStates.homePage;


export function homePageReducer(state = initialState, action) {
  if ([SLIDES_REQUEST, SLIDES_SUCCESS, SLIDES_FAIL].includes(action.type)) {
    let pl = action.payload;
    let carousels = [...state.carousels];
    let carouselItem = !pl.isBig ? carousels[pl.id] : state.carouselBig;

    switch (action.type) {
      case SLIDES_REQUEST:
        carouselItem.isFetching = true;

        return {
          ...state,
          error: '',
          [!pl.isBig ? 'carousels' : 'carouselBig']: !pl.isBig ? carousels : carouselItem,
        };

      case SLIDES_SUCCESS:
        carouselItem.items = !pl.isBig ? [...state.carousels[pl.id].items, ...pl.items] : [...state.carouselBig.items, ...pl.items];
        carouselItem.isEnd = pl.eol;
        carouselItem.isFetching = false;

        return {
          ...state,
          error: '',
          [!pl.isBig ? 'carousels' : 'carouselBig']: !pl.isBig ? carousels : carouselItem,
        };

      case SLIDES_FAIL:
        return {...state, error: action.payload.message};

    }
  } else {
    return state;
  }
}
