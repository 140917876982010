import React from 'react';
import FullscreenIcon from '../icons/Fullscreen';
import styled from "styled-components";

const Button = styled.button`
  width: 40px;
  height: 40px;
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  cursor: pointer;
  outline: 0;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
`;

const FullscreenIconStyled = styled(FullscreenIcon)`
  padding: 7px;
  height: 100%;
  width: 100%;
  fill: white;
`;

export default ({onClick, className, ariaLabel}) => {
  return (
    <Button
      className={className}
      type="button"
      onClick={onClick}
      aria-label={ariaLabel}>
      <FullscreenIconStyled/>
    </Button>
  );
};
