import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import Checkbox from "./primitives/Checkbox";
import theme from "./theme";
import Loader from "./primitives/Loader";
import setMeta from "../utils/setMeta";
import getGoogleIdentifiers from '../utils/getGoogleIdentifiers';
import getCookie from '../utils/getCookie';

const StyledLoader = styled(Loader)`
  font-size: 3.9px;
  position: relative;
  color: white;
  margin: -4px -4px -3px 5px;
  display: inline-flex;
  vertical-align: middle;
`

const PageContactStyled = styled.div`
  max-width: 680px;
  margin: 0 auto;
  padding: 16px 10px;
  color: ${theme.color.text};
`;

const Title = styled.h1`
  font-size: 2.5em;
  font-weight: 500;
  margin: 15px 0 20px;
`;

const Description = styled.div`
  font-size: 18px;
`;

const FormElement = styled.div`
  margin: 15px 0;
`;

const InputStyled = css`
  width: 100%;
  border-radius: 4px;
  border: 1px solid #efefef;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  background: white;
  margin: 0;
  display: block;
  padding: 8px 10px;
  font: inherit;
  transition: box-shadow 0.1s linear;
  outline: none;
  -webkit-appearance: none;
  
  :focus {
    box-shadow: 0 3px 8px rgba(0,0,0,0.25);
    border-color: #e0e0e0;
  }
`;

const Input = styled.input`
  ${InputStyled};
`;

const TextArea = styled.textarea`
  ${InputStyled};
  height: 150px;
  min-height: 100px;
  max-width: 100%;
  min-width: 100%;
`;

const CheckboxStyled = styled(Checkbox)`
  padding: 10px;
`;

const A = styled(Link)`
  text-decoration: none;
  color: ${theme.color.bright};

  :hover {
    ${theme.color.bright_h};
  }
`;

const ButtonStyles = css`
  background: ${theme.color.bright};
  border: 0;
  color: white;
  border-radius: 4px;
  padding: 8px 15px;
  font-size: 16px;
  font-weight: 600;
  opacity: 0.8;
  cursor: pointer;
  transition: opacity 0.1s linear;
  outline: none;
  text-decoration: none;
  
  :hover {
    opacity: 1;
  }
`;

const Submit = styled.button`
  position: relative;
  ${ButtonStyles};
`;

const Done = styled.div`
  line-height: 1.35em;
  font-size: 32px;
  font-weight: 500;
`;

const BackButton = styled(Link)`
  ${ButtonStyles};
  display: inline-block;
  margin-top: 20px;
`;

const PageContact = (props) => {
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    email: '',
    subject: '',
    message: '',
    terms_accepted: false,
  });

  useEffect(() => {
    setMeta(props.contactPage.pageTitle, props.contactPage.pageDescription);
  }, []);

  const handleChange = ({ target }) => {
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setFormValues({...formValues, [name]: value});
  };

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);

    const request = new Request(props.contactPage.userMessagePostUrl, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({...formValues, ...{UUID: getCookie('userIdentifier')}, ...getGoogleIdentifiers()}),
    });

    fetch(request)
      .then((response) => {
        setLoading(false);
        if (response.status === 200) {
          setDone(true);
        } else {
          throw new Error('Something went wrong on api server!');
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <PageContactStyled>
      {!done && <>
        <Title>Contact us</Title>
        <Description>Need help with something? Send us your question, feedback, or comment.</Description>
        <form onSubmit={handleSubmit}>
          <FormElement>
            <Input onChange={handleChange} value={formValues.email} type="email" placeholder="Email" name="email" required/>
          </FormElement>
          <FormElement>
            <Input onChange={handleChange} value={formValues.subject} type="text" placeholder="Subject" name="subject" required/>
          </FormElement>
          <FormElement>
            <TextArea onChange={handleChange} value={formValues.message} placeholder="Message" name="message" required />
          </FormElement>
          <FormElement>
            <CheckboxStyled onChange={handleChange} checked={formValues.terms_accepted} name="terms_accepted" isRequired={true}>
              I agree to the <A to="/en/about/privacy">
              Privacy policy</A> and <A to="/en/about/terms">
              Terms and Conditions</A>
            </CheckboxStyled>
          </FormElement>
          <FormElement>
            <Submit>Send message {loading && <StyledLoader bg={theme.color.bright}/>}</Submit>
          </FormElement>
        </form>
      </>}

      {done && <>
        <Done>Your message was sent. Usually it takes us about 24 hours to react.</Done>
        <BackButton to='/'>Васк to Home</BackButton>
      </>}
    </PageContactStyled>
  )
}

const mapStateToProps = store => {
  return {
    contactPage: store.contactPage,
  };
};

export default connect(
  mapStateToProps,
)(PageContact);
