const theme = {
  color: {
    bg: '#F6F6F6',
    white: '#ffffff',
    common: '#283593',
    common_h: '#364093',
    bright: '#F73B5A',
    bright_h: '#F74A67',
    text: '#2A2D42',
    textGray: '#9B9FAD',
    border: '#F5F5F5',
    gray: '#ECEDED',
    playerButtonHover: 'rgba(0,0,0,0.3)',
  },
  size: {
    pageMaxWidth: '1920px',
  },
  fonts: {
    common: 'Roboto, sans-serif'
  },
  shadow: {
    common: '0 2px 8px rgba(0,0,0,0.05)'
  }
};

const p = {
  mobile: 0,
  mobileBig: 640,
  tablet: 768,
  desktop: 1024,
  desktopBig: 1366,

  screen375: 376,
  screen414: 415,
};

export const breakpoint = {
  all: `@media (min-width: ${p.mobile}px)`,

  mobileOnly: `@media (min-width: ${p.mobile}px) and (max-width: ${p.tablet - 1}px)`,
  mobileSmallOnly: `@media (min-width: ${p.mobile}px) and (max-width: ${p.mobileBig - 1}px)`,
  mobileBigOnly: `@media (min-width: ${p.mobileBig}px) and (max-width: ${p.tablet - 1}px)`,
  mobileBigUp: `@media (min-width: ${p.mobileBig}px)`,

  tabletDown: `@media (max-width: ${p.desktop - 1}px)`,
  tabletOnly: `@media (min-width: ${p.tablet}px) and (max-width: ${p.desktop - 1}px)`,
  tabletUp: `@media (min-width: ${p.tablet}px)`,

  desktopDown: `@media (max-width: ${p.desktopBig - 1}px)`,
  desktopOnly: `@media (min-width: ${p.desktop}px) and (max-width: ${p.desktopBig - 1}px)`,
  desktopUp: `@media (min-width: ${p.desktop}px)`,

  desktopBigUp: `@media (min-width: ${p.desktopBig}px)`,

  portrait: `@media (orientation: portrait)`,
  landscape: `@media (orientation: landscape)`,

  mobileSmallLandscape: `@media (min-width: ${p.mobileBig}px) and (max-width: ${p.tablet - 1}px) and (orientation: landscape)`,
  mobileBigLandscape: `@media (min-width: ${p.tablet}px) and (max-width: ${p.desktop - 1}px) and (orientation: landscape)`,
  mobileLandscape: `@media (min-width: ${p.mobileBig}px) and (max-width: ${p.desktop - 1}px) and (orientation: landscape)`,

  tabletPortrait: `@media (min-width: ${p.tablet}px) and (max-width: ${p.desktop - 1}px) and (orientation: portrait)`,
  tabletLandscape: `@media (min-width: ${p.tablet}px) and (max-width: ${p.desktop - 1}px) and (orientation: landscape)`,

  screen375: `@media (min-width: ${p.mobile}px) and (max-width: ${p.screen375 - 1}px)`,
  screen414: `@media (min-width: ${p.screen375}px) and (max-width: ${p.screen414 - 1}px)`,
  screen640: `@media (min-width: ${p.screen414}px) and (max-width: ${p.mobileBig - 1}px)`,
};

export const bp = {
  all: breakpoint.all.slice(7),
  mobile: breakpoint.mobileOnly.slice(7),
  smallMobile: breakpoint.mobileSmallOnly.slice(7),
  bigMobile: breakpoint.mobileBigOnly.slice(7),
  mobileLandscape: breakpoint.mobileLandscape.slice(7),
  tablet: breakpoint.tabletOnly.slice(7),
  tabletPortrait: breakpoint.tabletPortrait.slice(7),
  desktop: breakpoint.desktopUp.slice(7),
  desktopBig: breakpoint.desktopBigUp.slice(7),

  screen375: breakpoint.screen375.slice(7),
  screen414: breakpoint.screen414.slice(7),
  screen640: breakpoint.screen640.slice(7),
};

export default theme;
