export const EVENTS = {
  'onAbort': 'abort',
  'onCanPlay': 'canplay',
  'onCanPlayThrough': 'canplaythrough',
  'onDurationChange': 'durationchange',
  'onEmptied': 'emptied',
  'onEncrypted': 'encrypted',
  'onEnd': 'ended',
  'onError': 'error',
  'onLoadedData': 'loadeddata',
  'onLoadedMetadata': 'loadedmetadata',
  'onLoadStart': 'loadstart',
  'onPause': 'pause',
  'onPlay': 'play',
  'onPlaying': 'playing',
  'onProgress': 'progress',
  'onRateChange': 'ratechange',
  'onSeeked': 'seeked',
  'onSeeking': 'seeking',
  'onStalled': 'stalled',
  'onSuspend': 'suspend',
  'onTimeUpdate': 'timeupdate',
  'onVolumeChange': 'volumechange',
  'onWaiting': 'waiting',
};

export const METHODS = [
  'addTextTrack',
  'canPlayType',
  'load',
  'play',
  'pause',
];

export const PROPERTIES = [
  'audioTracks',
  'autoPlay',
  'buffered',
  'controller',
  'controls',
  'currentSrc',
  'currentTime',
  'defaultMuted',
  'defaultPlaybackRate',
  'duration',
  'ended',
  'error',
  'loop',
  'mediaGroup',
  'muted',
  'networkState',
  'paused',
  'playbackRate',
  'played',
  'preload',
  'readyState',
  'seekable',
  'seeking',
  'src',
  'startDate',
  'textTracks',
  'videoTracks',
  'volume',
];
