import React from 'react';
import PropTypes from 'prop-types';
import theme, { breakpoint } from './theme';
import styled from 'styled-components';
import getClosest from '../utils/getClosest';
import { Link } from 'react-router-dom';
import IconPlaymarket from './Icons/IconPlaymarket';
import IconChevron from './Icons/IconChevron';
import IconLogoFooter from './Icons/IconLogoFooter';
// import Lang from "./Lang";

const MenuStyled = styled.div`
  will-change: transform;
  background: linear-gradient(122deg, #E43451 0%, #F73B5A 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 260px;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  
  transition: 
      transform 0.2s ease, 
      box-shadow 0.2s ease,
      opacity 0.15s linear,
      visibility 0s linear ${props => props.active ? '0s' : '0.2s'};
      
  transform: ${props => props.active ? 'translate(0, 0)' : 'translate(-100%, 0)'};
  box-shadow: ${props => props.active ? '87px 8px 67px rgba(0,0,0,0.3)' : 'none'};
  opacity: ${props => props.active ? '1' : '0'};
  visibility: ${props => props.active ? 'visible' : 'hidden'};
  
  ${breakpoint.tabletOnly} {
    padding-top: 54px;
  }
  
  ${breakpoint.tabletUp} {
    width: 300px;
  }
  
  ${breakpoint.desktopUp} {
    padding-top: 82px;
    width: 330px;
  }
  
  @media (min-width: 1920px) {
    left: 50%;
    margin-left: -960px;

    &::before {
      content: '';
      position: absolute;
      width: 1000px;
      height: 100%;
      left: -1000px;
      top: 0;
      background: white;
    }
    
    &::after {
      content: '';
      background: ${theme.color.border};
      width: 1px;
      height: 100%;
      left: 1px;
      top: 0;
      position: absolute;
    }
  }
`;

const Top = styled.div`
  text-align: center;
  padding: 0 30px;
`;

const AppButton = styled.a`
  color: ${theme.color.text};
  text-decoration: none;
  border-radius: 5px;
  height: 50px;
  display: inline-flex;
  background: white;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  padding: 0 23px;
`;

const AppButtonText = styled.div`
  font-weight: 700;
  margin-left: 13px;
`;

const AppButtonTinn = styled.div`
  font-weight: 400;
`;

const Text = styled.div`
  font-size: 12px;
  color: white;
  margin: 30px 0 7px;
  line-height: 1.18;
  text-align: left;
  will-change: transform;
  position: relative;

  b {
    font-weight: 600;
  }
`;

const TextIn = styled.div`
  will-change: transform;
  max-height: ${props => props.textOpened ? '1000px' : '71px'};
  text-overflow: ${props => props.textOpened ? 'clip' : 'ellipsis'};
  overflow: hidden;
  display: ${props => props.textOpened ? 'block!important' : '-webkit-box!important'};
  -webkit-line-clamp: ${props => props.textOpened ? 'none' : '5'};
  -webkit-box-orient: vertical;
  white-space: normal;
  transition: max-height 1s cubic-bezier(0, 1, 0.5, 1);
`;

const TextMore = styled.button`
  will-change: transform;
  background: #f03856;
  box-shadow: 0 0 3px 2px #f03856;
  border: 0;
  cursor: pointer;
  position: absolute;
  right: ${props => props.textOpened ? '-3px' : '0'};
  bottom: ${props => props.textOpened ? '-5px' : '-2px'};
  transform: ${props => props.textOpened ? 'rotate(180deg)' : 'rotate(0)'};
  transition: transform 0.2s ease-in-out;
  user-select: none;
  outline: none;
  height: 20px;
  width: 20px;
  padding: 0;
  border-radius: 10px;
`;

const Links = styled.div`
  padding: 10px 0 12px;
  text-align: left;
`;

const MItem = styled.div`
  margin: 16px 0;
  white-space: nowrap;
  color: white;
  font-weight: 700;
  font-size: 16px;

  &::before {
    content: '•';
    padding-right: 4px;
  }
`;

const MLink = styled(Link)`
  color: white;
  text-decoration: none;
  display: inline-flex;
  position: relative;
  padding-bottom: 2px;

  &::before {
    content: '';
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: white;;
    transform: scale(0, 1);
    transition: transform 0.2s ease;
  }

  &:hover::before {
    transform: scale(1, 1);
  }
`;

const Footer = styled.div`
  width: 100%;
  left: 0;
  bottom: 0;
  margin-top: 10px;
`;

const Logo = styled.div`
  text-align: center;
  margin: 0 0 30px;
`;

const Copyright = styled.div`
  background: #E43451;
  opacity: 0.8;
  color: white;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 25px 15px 27px;
`;

class Menu extends React.Component {
  state = { textOpened: false };
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    const { active, menuHide } = this.props;
    if (this.menuRef && !this.menuRef.contains(event.target) && active) {
      if (getClosest(event.target, '.menu-button')) return false;
      setTimeout(() => {
        menuHide();
      }, 100);
    }
  }

  handleClickMore = () => {
    this.setState({textOpened: !this.state.textOpened});
  }

  handleClickFooter = () => this.props.menuHide();

  render() {
    const { active } = this.props;
    const { textOpened } = this.state;
    return (
      <MenuStyled active={active} ref={el => (this.menuRef = el)}>
        <Top>
          <AppButton target="_blank" href='https://play.google.com/store/apps/details?id=tv.mama.app'>
            <IconPlaymarket/>
            <AppButtonText>Download for Free<AppButtonTinn>Video App for Kids</AppButtonTinn></AppButtonText>
          </AppButton>
          <Text>
            <TextIn textOpened={textOpened}>
              <b>Mama.TV</b> is the ultimate family-friendly place for safe and fun cartoons that kids will love.
              Our collection includes colorful shows, outdoor adventures, catchy rhymes, adorable toys, animals, cars, and more.
              With nursery rhymes, kids songs, and educational videos, children can learn colors, shapes, phonics, and numbers
              in an entertaining way. Our funny cartoons, pretend play, and family activity show videos are sure to keep kids
              entertained. Rest assured, all our videos are safe for kids. Let your kids have a blast and learn with <b>MamaTV!</b>
              <br /><br />
              You have the opportunity to enjoy cartoons and children's videos without any cost, whether you are at home, work,
              on vacation, or during a holiday. You can access them in various settings such as a car, train, bus, boat, airplane,
              or any other location. Regardless of your whereabouts, be it a cafe or restaurant, and irrespective of your
              activities like shopping, relaxing or traveling, you can always rely on mama.tv to entertain your children.
              <b>MamaTV</b> is committed to creating entertaining and educational videos for children, toddlers, and babies.
              The content is vibrant, enjoyable, safe, and captivating.
              Additionally, you can download our free app to watch educational videos and songs for kids on any device.
              <br /><br />
              Simply launch the <b>MamaTV</b> app on your mobile device, tablet, or computer and select the desired video to watch.
            </TextIn>
            <TextMore onClick={this.handleClickMore} textOpened={textOpened}><IconChevron /></TextMore>
          </Text>
          <Links>
            <MItem><MLink onClick={this.handleClickFooter} to="/en/about/affiliate">Affiliate</MLink></MItem>
            <MItem><MLink onClick={this.handleClickFooter} to="/en/about/terms">Terms of service</MLink></MItem>
            <MItem><MLink onClick={this.handleClickFooter} to="/en/about/cookies">Cookie policy</MLink></MItem>
            <MItem><MLink onClick={this.handleClickFooter} to="/en/about/privacy">Privacy policy</MLink></MItem>
          </Links>
        </Top>
        <Footer>
          <Logo><IconLogoFooter /></Logo>
          <Copyright>Copying of any materials, content or design of the mama.tv website is prohibited.<br /><br />© 2019 - 2023 mama.tv</Copyright>
        </Footer>
      </MenuStyled>
    )
  }
}

export default Menu;

Menu.propTypes = {
  active: PropTypes.bool.isRequired,
  menuHide: PropTypes.func.isRequired,
};
