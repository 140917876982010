import axios from 'axios';
import {
  VIDEO_PAGE_REQUEST,
  VIDEO_PAGE_UPDATE,
  VIDEO_PAGE_SUCCESS,
  VIDEO_PAGE_FAIL,
  PLAYLIST_UPDATE,
  PLAYLIST_SUCCESS,
  PLAYLIST_FAIL,
  PRELAND_SHOW,
  PRELAND_END,
} from '../constants';

export function handleRoute(pathname, isUpdate = false) {
  return (dispatch) => {
    dispatch({
      type: isUpdate ? VIDEO_PAGE_UPDATE : VIDEO_PAGE_REQUEST,
    });

    axios.get(`${pathname.slice(0, -1)}.json`)
      .then(res => {
        window.scrollTo(0, 0);
        dispatch({
          type: VIDEO_PAGE_SUCCESS,
          payload: {...res.data, currentPath: pathname},
        });
      }, error => {
        dispatch({
          type: VIDEO_PAGE_FAIL,
          payload: error,
        });
      });
  };
}

export function handlePlaylist(dataPath, ids) {
  return (dispatch) => {
    let pathname = window.location.pathname;

    dispatch({
      type: PLAYLIST_UPDATE,
    });

    let delay = 0;
    let start = performance.now();

    axios.get(dataPath)
      .then(res => {
        if (window.location.pathname !== pathname) return;

        let passed = performance.now() - start;
        let timeout = (passed >= delay) ? 0 : delay - passed;
        setTimeout(() => {
          console.log('PLAYLIST_SUCCESS', res.data);
          res.data.items = res.data.items.filter(item => !ids.includes(item.id));
          dispatch({
            type: PLAYLIST_SUCCESS,
            payload: res.data,
          });
        }, timeout);

      }, error => {
        dispatch({
          type: PLAYLIST_FAIL,
          payload: error,
        });
      });
  };
}

export function handlePreLand(isPreLand) {
  return dispatch => {
    dispatch({
      type: isPreLand ? PRELAND_SHOW : PRELAND_END,
    });
  };
}
