import React from 'react';
import Playlist from '../components/Playlist';
import PlaylistMScroll from '../components/PlaylistMScroll';
import { connect } from 'react-redux';
import { handlePlaylist } from '../actions/VideoPageActions';
import { modifiedSroll } from '../constants/splitTest';

const PlaylistContainer = props => {
  const { playlist, dataPath, handlePlaylist, ads, siteFeatures, channel } = props;
  const isModifiedSroll = siteFeatures.includes(modifiedSroll);

  return(
    <>{(!isModifiedSroll || channel) && <Playlist
        playlist={playlist}
        dataPath={dataPath}
        className={props.className}
        handlePlaylist={handlePlaylist}
        ads={ads}
        siteFeatures={siteFeatures}
        channel={channel}
      />}
      {(isModifiedSroll && !channel) && <PlaylistMScroll
        playlist={playlist}
        dataPath={dataPath}
        className={props.className}
        handlePlaylist={handlePlaylist}
        ads={ads}
        siteFeatures={siteFeatures}
      />}
    </>
  )
};

const mapStateToProps = store => {
  return {
    playlist: store.videoPage.playlist,
    dataPath: store.videoPage.dataPath,
    ads: store.ads.adNamed,
    siteFeatures: store.sessionParams.siteFeatures,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handlePlaylist: (dataPath, ids) => dispatch(handlePlaylist(dataPath, ids)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlaylistContainer);
