import MHelper from './MHelper';
export default class Analytics {
  constructor(options) {
    if (!MHelper.ifSSR) {
      Object.assign(this, options);

      if (window.localStorage && document.referrer && document.referrer.includes(window.location.host)) {
        this.pagesViewed = +localStorage.getItem('szPagesViewed');
        this.startTimer = +localStorage.getItem('szStartTimer');
        this.sentEvents = this.getMinutesElapsed(this.startTimer);
      }
      else {
        this.pagesViewed = 0;
        this.sentEvents = 0;
        this.startTimer = Date.now();
        if (window.localStorage) localStorage.setItem('szStartTimer', this.startTimer);
      }

      gtag('config', this.gaId4, { 'send_page_view': false });

      setInterval(this.timer, 5000);

      return this.init;
    } else {
      return store => next => action => {
        return next(action);
      }
    }
  }

  get init() {
    for(let item in this.events) {
      let event = this.events[item];
      if (event.method)
        this[event.method]();
    }

    document.addEventListener('analytics', e => {
      this.test(e.detail.type, e.detail);
    });

    document.dispatchEvent(new Event('analyticsInit'));

    return store => next => action => {
      if ( action.type === '@@router/LOCATION_CHANGE') {
        this.pagesViewed++;
        if (window.localStorage) localStorage.setItem('szPagesViewed', this.pagesViewed);
        const nextPage = `${action.payload.location.pathname}${action.payload.location.search}`;
        const state = store.getState();
        const pathName = action.payload.location.pathname;
        const splitGroupKey = state.sessionParams.splitGroupKey;
        const activeUtm = state.sessionParams.activeUtm;
        this.eventsMap = state.ads.eventsMap

        if ((pathName.indexOf('/utm-') > -1) || (pathName.indexOf('/url/') > -1)) {
          if(splitGroupKey) gtag('set', { 'dimension1': splitGroupKey });
          if(activeUtm) gtag('set', { 'dimension2': activeUtm });
        }

        this.trackPage(nextPage);
        if (this.pagesViewed === 2) this.trackEvent("Pages viewed 2", window.location.pathname, 0, this.category);
      }

      this.test(action.type);
      return next(action);
    }
  }

  test = (type, detail) => {
    if (type in this.events) {
      let event = this.events[type];

      if (event.action)
        event.action.forEach((action, key) => {
          let label = ( event.label && detail) ? detail[event.label[key]] : "Click";
          let value = event.value ? event.value[key] : 0;
          let category = event.category ? event.category : this.category;
          this.trackEvent(action, label, value, category);
        });

      if (event.conversion)
        event.conversion.forEach((conversionItem) => {
          this.trackConversion(conversionItem)
        });

    }
  };

  timer = () => {
    const minutesElapsed = this.getMinutesElapsed(this.startTimer);
    if (minutesElapsed !== this.sentEvents && minutesElapsed > 0) {
      this.sentEvents = minutesElapsed;
    }
  }

  getMinutesElapsed(start) {
    return Math.floor((Date.now() - start) / 60000);
  }

  trackEvent = (action, label, value, category) => {
    gtag('event', action, {
      'event_label': label,
      'value': value,
      'event_category': category,
    })
  };

  trackConversion = (val) => {
    if (this.eventsMap && Object.keys(this.eventsMap).length && this.eventsMap[val]) {
      gtag('event', 'conversion', {
        'allow_custom_scripts': true,
        'send_to': this.eventsMap[val],
      })
    }
  };

  trackPage = page => {
    gtag('event', 'page_view', { page_location: page });
  };

  /** Tracks old Webkits with HLS problems */
  trackOldBrowsers = () => {
    const oldWebkit = 534.3;
    let webKitVersion = parseFloat(/\d{3}\.\d{2}(?!AppleWebKit\/)/.exec(navigator.userAgent));
    if (webKitVersion <= oldWebkit) {
      this.trackEvent("Old WebKit", webKitVersion.toString(), 1, this.category);
    }
  };
}
