const getGoogleIdentifiers = () => {
  let _ga, _gid;

  if (window.gtagLoaded) {
    function getCookie(name) {
      var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
      return v ? v[2] : null;
    }

    _ga = getCookie('_ga');
    _ga = (_ga && _ga.slice(6)) || _ga;

    _gid = getCookie('_gid');
    _gid = (_gid && _gid.slice(6)) || _gid;
  }

  return {ga: _ga, gid: _gid};
}

export default getGoogleIdentifiers;
