import React from 'react';
import renderMessage from '../utils/renderMessage';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import VideoThumbFPlayer from "./VideoThumbFPlayer";
import { breakpoint } from './theme';
import setMeta from '../utils/setMeta';

const PagePrelandLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 7px 5px;
  min-width: 0;

  ${breakpoint.mobileLandscape} {
    padding: 5px;
  }

  ${breakpoint.desktopUp} {
    padding: 20px;
  }
`;

const PrelandItem = styled.div`
  flex: 100% 0 0;
  padding: 5px 0;
  min-width: 0;

  &:nth-child(9), &:nth-child(10), &:nth-child(11), &:nth-child(12) {
    display: none;
  }

  ${breakpoint.tabletOnly} {
    flex-basis: 50%;
    padding: 5px;
  }

  ${breakpoint.mobileSmallLandscape} {
    flex-basis: 50%;
    padding: 5px;
  }

  ${breakpoint.desktopUp}, ${breakpoint.mobileLandscape.slice(6)}{
    padding: 5px;
  }

  ${breakpoint.tabletLandscape}, ${breakpoint.desktopUp.slice(6)} {
    flex-basis: 33.33%;

    &:nth-child(9) {
      display: block;
    }
  }

  ${breakpoint.desktopBigUp} {
    flex-basis: 25%;

    &:nth-child(10), &:nth-child(11), &:nth-child(12) {
      display: block;
    }
  }
`;

export default class PagePreland extends React.Component {
  componentDidMount() {
    setMeta(this.props.pageTitle, this.props.pageDescription);
    if (window.localStorage) localStorage.setItem('szManualAdFromThird', 1); // manualAdFromThird
  }

  componentDidUpdate () {
    setMeta(this.props.pageTitle, this.props.pageDescription);
  }

  playlistItems = () => {
    const { data, playlist } = this.props;
    return data ? [{
      id: data.id,
      title: data.title,
      image: data.posterUrl,
      url: data.videoPagePath,
    }, ...playlist.items] : playlist.items;
  }

  renderSlides() {
    return this.playlistItems().map((item, key) => {
      return (
        <PrelandItem key={key}>
          <VideoThumbFPlayer {...item} position={key} />
        </PrelandItem>
      )
    })
  }

  render() {
    renderMessage('PagePreland');

    return (
      <PagePrelandLayout>
        {this.renderSlides()}
      </PagePrelandLayout>
    );
  }
}

PagePreland.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    length: PropTypes.string,
    posterUrl: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    videoUrl: PropTypes.string.isRequired,
    vastUrl: PropTypes.array,
  }),
  pageDescription: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
};
