import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Thumb from './primitives/Thumb';
import theme, { breakpoint, bp } from './theme';
import styled from 'styled-components';
import sendAnalyticsEvent from '../utils/sendAnalyticsEvent';
import getImages from "../utils/getImages";
import { thumbsAnalytics } from "./Playlist";
import getCookie from '../utils/getCookie';
import delayedEvents from '../utils/delayedEvents';

const VideoThumbStyled = styled(Link)`
  background: white;
  border-radius: 4px;
  display: block;
  text-decoration: none;
  color: ${theme.color.text};
  font-size: 14px;
  overflow: hidden;
  box-shadow: ${theme.shadow.common};
  
  ${breakpoint.tabletUp} {
    font-size: 16px;
  }
  
  ${breakpoint.desktopUp} {
    border-radius: 8px;
  }
`;

const Title = styled.div`
  font-weight: bold;
  padding: 11px 10px 10px;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  min-width: 0;
  text-overflow: ellipsis;
  
  ${breakpoint.desktopUp} {
    padding: 13px 19px 12px;
  }
`;

export default class VideoThumb extends React.Component {
  componentDidMount() {
    const { id } = this.props;

    this.observer = new IntersectionObserver(entries => {
      const { isIntersecting } = entries[0];

      if (isIntersecting) {
        thumbsAnalytics.start(id, 'show video-thumb');
      } else {
        thumbsAnalytics.stop(id);
      }
    }, {threshold: 0.5});

    this.observer.observe(this.element);
  }

  componentWillUnmount() {
    this.observer.unobserve(this.element);
  }

  get images() {
    return getImages(this.props.image, {
      [bp.screen375]: '173x98',
      [bp.screen414]: '192x108',
      [bp.screen640]: '305x172',
      [bp.bigMobile]: '369x208',
      [bp.tablet]: '328x185',
      [bp.desktop]: '425x239',
    });
  }

  handleClick = () => {
    const { url, position, id } = this.props;

    thumbsAnalytics.send(id, 'click video-thumb');

    sendAnalyticsEvent('VIDEO_THUMB', {
      url: url,
      position: position.toString()
    });

    if (getCookie('shownInterstitial')) {
      sendAnalyticsEvent('VIDEO_THUMB_VIP', { url: url });
    } else {
      delayedEvents().add({name: 'VIDEO_THUMB_VIP', detail: { url: url }});
    }
  };

  render() {
    const { url, length, title } = this.props;

    return (
      <VideoThumbStyled to={url} onClick={this.handleClick} innerRef={el => this.element = el}>
        <Thumb images={this.images} alt="" length={length} proportion="16:9" Radius={{m: '4px 4px 0 0', t: '4px 4px 0 0', d: '8px 8px 0 0'}} />
        <Title title={title}>{ title }</Title>
      </VideoThumbStyled>
    );
  }
}

VideoThumb.propTypes = {
  id: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  length: PropTypes.string
};
