import React from 'react';
import Svg from "./Svg";

const IconMenu = props => {
  return (
    <Svg
      title="Close Icon"
      size={props.size || 15}
      className={props.className}
      width={15}
      height={15}>
      <path d="M8.9,7.562l5.947-5.947A.946.946,0,1,0,13.508.278L7.562,6.225,1.614.278A.946.946,0,0,0,.277,1.615L6.224,7.562.277,13.509a.945.945,0,1,0,1.337,1.337L7.562,8.9l5.947,5.947a.946.946,0,1,0,1.337-1.337Z"/>
    </Svg>
  )
};

export default IconMenu;
