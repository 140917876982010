import React from 'react';
import Svg from "./Svg";

const IconPlay = props => {
  return (
    <Svg
      title="Play Icon"
      size={props.size || 95}
      className={props.className}
      width={95}
      height={104}>
      <path d="M5.37237179,103.972186 C2.40663809,103.972186 0.00243589744,101.585052 0.00243589744,98.6403721 L0.00243589744,5.33181395 C0.00243589744,2.38713442 2.40663809,5.40928855e-16 5.37237179,0 L92.6359615,47.9863256 C92.6359615,47.9863256 96.6637179,51.9854884 92.6359615,55.9846512 C88.6082051,59.983814 5.37237179,103.972186 5.37237179,103.972186 Z"/>
    </Svg>
  )
};

export default IconPlay;
